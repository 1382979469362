import React from 'react';
import { render } from '@testing-library/react';
import { jiraIssue } from '../../../mocks/jira';
import { JiraIssueCard } from './JiraIssueCard';

describe('JiraIssueCard', () => {
  it('should render successfully', () => {
    const { baseElement } = render(
      <JiraIssueCard
        jiraIssue={jiraIssue}
        onRefresh={(params) => console.info('Storybook - JiraIssueCard - onRefresh()', params)}
        onRemove={(params) => console.info('Storybook - JiraIssueCard - onRemove()', params)}
      />
    );
    expect(baseElement).toBeTruthy();
  });
});
