import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { users } from '../../mocks/users';
import { User } from '../../types/user';
import { UsersApi } from './usersApi';

export const getOrganizationUsers = async (abortSignal?: AbortSignal): Promise<User[]> => {
  try {
    const result: User[] = await UsersApi.getOrganizationUsers({
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectUsers = async (
  organizationId: string,
  projectId: string
): Promise<{ total: number; users: User[] }> => {
  try {
    if (!organizationId) {
      const error = 'smaply-ui: missing organization id';
      console.error(error);
      throw new Error(error);
    }

    if (!projectId) {
      const error = 'smaply-ui: missing project id';
      console.error(error);
      throw new Error(error);
    }

    if (shouldMockSmaplyAPI) {
      return { total: users.length, users };
    }
    const result: { total: number; users: User[] } = await UsersApi.getProjectUsers(
      organizationId,
      projectId
    ).json();
    return result;
  } catch (error) {
    console.error(error);
    const serverErrors = await error.json();
    throw serverErrors;
  }
};
