import { OrganizationId } from '../../types/organization';
import { get, post, put, remove } from '../config';

export const TagsApi = {
  get: (organizationId: OrganizationId, params: any) =>
    get(`web/v1/organizations/${organizationId}/tags`, params),
  post: (organizationId: OrganizationId, params: any) =>
    post(`web/v1/organizations/${organizationId}/tags`, params),
  put: (organizationId: OrganizationId, id: string | number, params: any) =>
    put(`web/v1/organizations/${organizationId}/tags/${id}`, params),
  remove: (organizationId: OrganizationId, id: string | number) =>
    remove(`web/v1/organizations/${organizationId}/tags/${id}`),
};
