import { UserRoles } from '../../types/user';
import { UsersApi } from './usersApi';

export const getUserRoles = async (abortSignal?: AbortSignal): Promise<UserRoles[]> => {
  try {
    const result: UserRoles[] = await UsersApi.getUserRoles({
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
