import React from 'react';
import {
  ProjectTags as SUIProjectTags,
  TagsProps,
} from './src/components/molecules/Tags/ProjectTags/ProjectTags';
import { K2Theme } from './src/theme/material/k2';
import { ThemeProvider } from '@mui/material';

export function ProjectTags(props: TagsProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUIProjectTags {...props} />
    </ThemeProvider>
  );
}
