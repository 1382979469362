import React from 'react';
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

export const StyledMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

export type MenuItemProps = MuiMenuItemProps;

export const MenuItem = (props: any) => {
  return <StyledMenuItem {...props} />;
};
