import { Gon } from '../../types/gon';
import { OrganizationApi } from './organizationApi';

export const getGonData = async (abortSignal?: AbortSignal): Promise<Gon> => {
  try {
    const result: Gon = await OrganizationApi.getGonData({
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
