import React from 'react';
import { nanoid } from 'nanoid';
import List from '@mui/material/List';
import { JiraIssuesListItem } from './JiraIssuesListItem';
import { JiraIssue } from '../../../types/jira';

export type JiraIssuesListProps = {
  jiraIssues: JiraIssue[];
};

export const JiraIssuesList = ({ jiraIssues }: JiraIssuesListProps) => {
  return (
    <List sx={{ width: '100%', padding: '0px' }}>
      {jiraIssues.map((jiraIssue) => (
        <JiraIssuesListItem key={nanoid()} jiraIssue={jiraIssue} />
      ))}
    </List>
  );
};
