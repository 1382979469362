import { Options } from 'ky';
import { get, post } from '../config';

export const JourneyMapApi = {
  getTemplates: (organizationId: string, projectId: string, options?: Options) =>
    get(
      `web/v1/organizations/${organizationId}/projects/${projectId}/journey_map_templates`,
      options
    ),
  getExamples: (organizationId: string, projectId: string, options?: Options) =>
    get(
      `web/v1/organizations/${organizationId}/projects/${projectId}/journey_map_examples`,
      options
    ),
  postJourneyMapDuplicateExample: (
    organizationId: string,
    projectId: string,
    exampleId: string,
    options?: Options
  ) =>
    post(
      `web/v1/organizations/${organizationId}/projects/${projectId}/journey_map_examples/${exampleId}/duplicate`,
      { ...options, timeout: false }
    ),
  postJourneyMap: (organizationId: string, projectId: string, options?: Options) =>
    post(`web/v1/organizations/${organizationId}/projects/${projectId}/journey_maps`, options),
  postJourneyMapDuplicate: (
    organizationId: string,
    projectId: string,
    templateId: string,
    options?: Options
  ) =>
    post(
      `web/v1/organizations/${organizationId}/projects/${projectId}/journey_map_templates/${templateId}/duplicate`,
      options
    ),
};
