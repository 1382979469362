import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import values from 'lodash/values';
import { nanoid } from 'nanoid';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import {
  additionalFeatures,
  featureCategories,
  freePlanLimits,
  organizationLimits,
  userLimitByPlan,
} from '../../../../constants/additionalFeatures';
import { billingFrequencies } from '../../../../constants/billingFrequencies';
import { ErrorMessage } from '../../Alerts/GenericAlert';
import { colors } from '../../../../theme';
import { SelectPlanCard } from '../SelectPlanCard';
import { Tooltip } from '../../../atoms/Tooltip/Tooltip';
import { Icon } from '../../../atoms/Icon/Icon';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from '../../../atoms/Table/Table';
import { PlanBillingFrequencySwitch } from '../PlanBillingFrequencySwitch/PlanBillingFrequencySwitch';
import { usePrev } from '../../../../utils/hooks/usePrev';
import { Template } from '../../../templates/Template';
import { PlansTableSkeleton } from './PlansTableSkeleton';
import { PeriodUnit, Plan } from '../../../../types/pricing';
import { enterPrisePlanChargebeeIdYear } from '../../../../constants/enterprisePlanChargebeeId';
import { IconButton } from '../../../atoms/Icon/IconButton';
import { orderPlans } from '../../../../business-logic/plansFactory';

const features = Object.values(additionalFeatures);
const limits = Object.values(organizationLimits);
const categories = Object.keys(featureCategories);
const rowsByCategory = {
  general: [
    ...limits.filter((limit) => limit.category === 'general'),
    ...features.filter((feature) => feature.category === 'general'),
  ],
  lanes: features.filter((feature) => feature.category === 'lanes'),
  exports: features.filter((feature) => feature.category === 'exports'),
  templates: features.filter((feature) => feature.category === 'templates'),
  imports: features.filter((feature) => feature.category === 'imports'),
  branding: features.filter((feature) => feature.category === 'branding'),
  integrations: features.filter((feature) => feature.category === 'integrations'),
  support: features.filter((feature) => feature.category === 'support'),
} as any;

const isFeatureAvailableIcon = (flag: boolean) => {
  return flag ? (
    <Icon sx={{ fontSize: 14, color: colors.green[500] }} iconName="check" iconPrefix="fal" />
  ) : (
    <Icon sx={{ fontSize: 14, color: colors.red[500] }} iconName="times" iconPrefix="fal" />
  );
};

export type PlansTableProps = {
  handleSelectPlan: (plan: Partial<Plan>) => void;
  handleCancelSubscription: () => void;
};

export const PlansTable = ({ handleSelectPlan, handleCancelSubscription }: PlansTableProps) => {
  const {
    entities: { currentPlan, plans },
    hasErrors,
    isLoading,
  } = useEntities(entityNames.CURRENT_PLAN, entityNames.PLANS);

  const [period, setPeriod] = useState<PeriodUnit>(billingFrequencies.month);

  const prevPeriod = usePrev(period);

  if (currentPlan.can_change_plan === false) {
    return null;
  }

  if (hasErrors) {
    return <ErrorMessage />;
  }

  const onSwitchBillingFrequency = (event: any, checked: boolean) => {
    setPeriod(checked ? billingFrequencies.year : billingFrequencies.month);
  };

  const orderedPlans = orderPlans(values(plans));

  const visiblePlans = orderedPlans.filter(
    (plan) => plan.period_unit === period || plan.is_free_plan
  );

  const shouldAnimatePrice = prevPeriod !== period;

  const getLimitToShow = (plan: Plan, row: unknown) => {
    // @ts-ignore
    if (plan.is_free_plan && freePlanLimits[row.id]) {
      // @ts-ignore
      return freePlanLimits[row.id];
    }

    // @ts-ignore
    if (plan.meta_data.limits[row.id] >= 500) {
      // @ts-ignore
      if (row.name === 'Users') {
        // @ts-ignore
        // eslint-disable-next-line
        return `Up to ${plan.meta_data.limits[row.id]}`;
      } else {
        return 'Unlimited';
      }
    }

    // @ts-ignore
    if (!plan.meta_data.limits[row.id]) {
      // @ts-ignore
      console.error('Unknown plan limit:', row.id);
    }

    // @ts-ignore
    return plan.meta_data.limits[row.id];
  };

  return (
    <Template
      skeleton={<PlansTableSkeleton />}
      isLoading={isLoading}
      errorComponent={<ErrorMessage />}
    >
      <>
        <Typography sx={{ textTransform: 'uppercase' }} variant="boldM" color={colors.iron}>
          Pricing plans overview
        </Typography>
        <Box maxWidth={1000} id="plans-table" mt={9} display="flex" justifyContent="space-between">
          <TableContainer sx={{ maxHeight: 800, mt: 5 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      verticalAlign: 'bottom',
                      boxShadow: `5px 5px 6px ${colors.silver}`,
                      p: 0,
                    }}
                  >
                    <PlanBillingFrequencySwitch
                      period={period}
                      onSwitch={onSwitchBillingFrequency}
                    />
                  </TableCell>
                  {visiblePlans.map((plan) => (
                    <TableCell
                      key={nanoid()}
                      sx={{ verticalAlign: 'top', p: 0, boxShadow: `5px 5px 6px ${colors.silver}` }}
                    >
                      <SelectPlanCard
                        plan={plan}
                        shouldAnimatePrice={shouldAnimatePrice}
                        handleSelectPlan={handleSelectPlan}
                        handleCancelSubscription={handleCancelSubscription}
                        key={plan.chargebee_id}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category: string) => {
                  return rowsByCategory[category].map((row: any, index: number) => (
                    <React.Fragment key={nanoid()}>
                      {index === 0 ? (
                        <TableRow>
                          <TableCell sx={{ boxShadow: 'none' }}>
                            <Typography
                              sx={{
                                display: 'inline-flex',
                                textTransform: 'uppercase',
                                mt: 6,
                                mb: 2,
                              }}
                              variant="boldS"
                              color={colors.iron}
                            >
                              {/* @ts-ignore */}
                              {featureCategories[category].name}
                            </Typography>
                          </TableCell>
                          {visiblePlans.map((plan) => (
                            <TableCell
                              key={nanoid()}
                              sx={{
                                boxShadow: 'none',
                                // @ts-ignore
                                backgroundColor: plan.current_plan ? colors.blue[200] : 'initial',
                              }}
                            />
                          ))}
                        </TableRow>
                      ) : null}
                      <TableRow>
                        <TableCell>
                          <Typography variant="regularXS" color={colors.onyx}>
                            {row.name}
                            {row.tooltip ? (
                              <Tooltip placement="right" title={row.tooltip}>
                                <span>
                                  <IconButton sx={{ p: 0, pl: 1 }} disableRipple disableFocusRipple>
                                    <Icon
                                      sx={{ fontSize: 14, color: colors.silver }}
                                      iconName="question-circle"
                                      iconPrefix="fas"
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            ) : null}
                          </Typography>
                        </TableCell>
                        {visiblePlans.map(
                          (plan) =>
                            !plan.is_enterprise_plan && (
                              <TableCell
                                key={nanoid()}
                                sx={{
                                  textAlign: 'center',
                                  // @ts-ignore
                                  backgroundColor: plan.current_plan ? colors.blue[200] : 'initial',
                                }}
                              >
                                {/* @ts-ignore */}
                                {plan.meta_data.limits[row.id] ? (
                                  <Typography variant="regularXS" color={colors.iron}>
                                    {/* @ts-ignore */}
                                    {getLimitToShow(plan, row)}
                                  </Typography>
                                ) : (
                                  <Typography variant="regularXS" color={colors.iron}>
                                    {isFeatureAvailableIcon(
                                      plan.meta_data.features.includes(row.id)
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )
                        )}
                        <TableCell sx={{ textAlign: 'center' }}>
                          {/* @ts-ignore */}
                          {visiblePlans[0] && visiblePlans[0].meta_data.limits[row.id] ? (
                            <Typography variant="regularXS" color={colors.iron}>
                              {row.name === 'Users'
                                ? userLimitByPlan[enterPrisePlanChargebeeIdYear]
                                : 'Unlimited'}
                            </Typography>
                          ) : (
                            <Typography variant="regularXS" color={colors.iron}>
                              {isFeatureAvailableIcon(true)}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ));
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Template>
  );
};
