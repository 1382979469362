import { HTTPError } from 'ky';
import { DENORMALIZED_DATA_KEY } from './helpers';
import { tagAssociationEntities } from '../constants/tagAssociationEntities';
import { AllowedStatuses, EntityNames } from '../../types/state';
import { AdditionalFeatureType } from '../../types/subscription';
import {
  BillingInformation,
  ChargebeePlanId,
  CurrentPlan,
  Plan,
  PlanValidation,
  Subscription,
} from '../../types/pricing';
import { JiraIntegration, JiraIssue } from '../../types/jira';
import { JourneyMap, JourneyMapExample, JourneyMapTemplate } from '../../types/journeyMap';
import { Tag, TagAssociation } from '../../types/tags';
import { JourneyMapUserAssociationType, User } from '../../types/user';
import { Organization } from '../../types/organization';
import { Project } from '../../types/project';
import { WallsyncIntegration, WallsyncMetaData } from '../../types/wallsync';

// statuses
export type StatusesType = Record<EntityNames, AllowedStatuses>;

export type ErrorMessages = string[];
export type Errors = {
  messages: ErrorMessages[];
};

// errors
export type ErrorType = HTTPError & {
  id: string;
  entityName: string;
  entityId?: string | number;
  status?: number;
  errors?: Errors;
};
type ErrorsType = Record<EntityNames, ErrorType[]>;

// results
export type ResultType = {
  additionalFeatures: string[];
  jiraIntegration: string[];
  journeyMapTagAssociations: string[];
  journeyMapUserAssociations: string[];
  plans: string[];
  projectTagAssociations: string[];
  tags: string[];
  users: string[];
};

// entities
export type EntitiesState = {
  additionalFeatures: AdditionalFeatureType[];
  billingInformation: BillingInformation;
  currentPlan: CurrentPlan;
  errors: ErrorsType;
  jiraIntegration: JiraIntegration;
  jiraIssues: JiraIssue[];
  jiraSelectedIssueKeys: string[];
  journeyMap: JourneyMap;
  journeyMapExamples: JourneyMapExample[];
  journeyMaps: JourneyMap[];
  journeyMapTagAssociations: Record<string, TagAssociation>;
  journeyMapUserAssociations: Record<string, JourneyMapUserAssociationType>;
  organization: Organization;
  plans: Record<string, Plan>;
  planValidation: PlanValidation;
  projectTagAssociations: Record<string, TagAssociation>;
  result: ResultType;
  selectedProject: Project;
  statuses: StatusesType;
  subscription: Subscription;
  tags: Record<string, Tag>;
  users: Record<string, User>;
  wallsync: WallsyncMetaData;
};

export type EntityTypes = EntitiesState[keyof EntitiesState];

export type Entities =
  | AdditionalFeatureType
  | BillingInformation
  | CurrentPlan
  | JiraIntegration
  | JiraIssue[]
  | JourneyMap
  | JourneyMapUserAssociationType
  | Organization
  | Plan
  | PlanValidation
  | Project
  | Subscription
  | Tag
  | TagAssociation
  | User
  | WallsyncMetaData;

// methods
export type StoreMethodsType = {
  // entity
  removeEntity: (entityName: string, entityId: string) => void;

  // normalizer methods
  setDenormalizedData: (data: Entities | Entities[], entityName: EntityNames) => void;

  // error state methods
  addError: (error: HTTPError, entityName: EntityNames, entityId?: string | number) => void;
  removeError: (entityName: EntityNames, id: string) => void;
  removeAllErrors: (entityName: EntityNames) => void;

  // statuses state methods
  setEntityStatus: (
    entityName: EntityNames,
    status: AllowedStatuses,
    entityId?: string | number
  ) => void;

  // additional features
  getAdditionalFeatures: (abortSignal?: AbortSignal) => Promise<void>;

  deleteJiraIntegration: (abortSignal: AbortSignal) => Promise<void>;
  getJiraIntegration: (abortSignal: AbortSignal) => Promise<void>;
  putJiraIntegration: (jiraUrl: string, abortSignal: AbortSignal) => Promise<void>;
  searchJiraIssues: (query: string, abortSignal: AbortSignal) => Promise<void>;
  setJiraIntegration: (jiraIntegration: JiraIntegration) => void;
  toggleSelectedJiraIssue: (jira_issue_key: string) => void;
  clearSelectedJiraIssues: () => void;

  // organizations
  // getOrganizations: (abortSignal?: AbortSignal) => Promise<void>;
  setSelectedOrganization: (organization: Organization) => void;

  // project
  setSelectedProject: (project: Project) => void;

  // plans
  getPlans: (abortSignal?: AbortSignal) => Promise<void>;
  getPlanValidation: (planId: ChargebeePlanId, abortSignal?: AbortSignal) => Promise<void>;
  getBillingInformation: (abortSignal?: AbortSignal) => Promise<void>;
  getCurrentPlan: (abortSignal?: AbortSignal) => Promise<void>;
  getSubscription: (abortSignal?: AbortSignal) => Promise<void>;

  // project tag associations
  getProjectTagAssociations: (projectId: string, abortSignal: AbortSignal) => Promise<void>;
  createProjectTagAssociation: (projectId: string, tagId: number) => Promise<void>;
  deleteProjectTagAssociation: (projectId: string, tagAssociationId: string) => Promise<void>;

  // journey map tag associations
  getJourneyMapTagAssociations: (
    projectId: string,
    journeyMapId: string,
    abortSignal?: AbortSignal
  ) => Promise<void>;

  createJourneyMapTagAssociation: (
    projectId: string,
    journeyMapId: string,
    tagId: string
  ) => Promise<void>;

  deleteJourneyMapTagAssociation: (
    projectId: string,
    journeyMapId: string,
    tagAssociationId: string
  ) => Promise<void>;

  // tags
  getTags: (abortSignal?: AbortSignal) => Promise<void>;
  createTag: (
    tagName: string,
    associationEntity: keyof typeof tagAssociationEntities,
    projectId?: string,
    journeyMapId?: string
  ) => Promise<void>;
  updateTag: (tag: Partial<Tag>) => Promise<void>;
  deleteTag: (tagId: string | number) => Promise<void>;

  // journey maps
  setJourneyMap: (journeyMap: JourneyMap) => void;
  setJourneyMaps: (journeyMaps: JourneyMap[]) => void;
  setJourneyMapExamples: (journeyMapExamples: JourneyMapExample[]) => void;
  updateJourneyMap: (
    projectId: string,
    journeyMapId: string,
    journeyMap: Partial<JourneyMap>
  ) => Promise<JourneyMap>;
  // wallsync
  getWallsync: () => Promise<void>;
  getWallsyncIntegrations: () => WallsyncIntegration[];

  // journey map
  getJourneyMapTemplates: () => JourneyMapTemplate[];
  getJourneyMapExamples: () => JourneyMapExample[];
  createJourneyMapFromExample: (exampleId: number) => Promise<void>;
  createJourneyMapFromTemplate: (templateId: number) => Promise<void>;
  createJourneyMap: () => Promise<void>;

  // users
  getProjectUsers: (projectId: string) => Promise<void>;

  // journey map user associations
  getJourneyMapUserAssociations: (projectId: string, journeyMapId: string) => Promise<void>;

  createJourneyMapUserAssociation: (
    projectId: string,
    journeyMapId: string,
    userId: string
  ) => Promise<void>;

  deleteJourneyMapUserAssociation: (
    projectId: string,
    journeyMapId: string,
    id: string
  ) => Promise<void>;
};

export type StoreMethodsNames = keyof StoreMethodsType;

// denormalized data
export interface DenormalizedDataInterface {
  data: Entities | Entities[];
  entityName: EntityNames;
}

// app state
export interface AppStateInterface extends StoreMethodsType {
  entities: EntitiesState;
  [DENORMALIZED_DATA_KEY]?: DenormalizedDataInterface;
}
