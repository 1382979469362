import { colors } from '../theme/colors/colors';
import { User } from '../types/user';

export const users = [
  { id: 1, full_name: 'Pedro', color: colors.red[500] },
  { id: 2, full_name: 'Jesse', color: colors.orange[500] },
  { id: 3, full_name: 'Marius', color: colors.blue[500] },
  { id: 4, full_name: 'Tobi', color: colors.green[500] },
  { id: 5, full_name: 'Pavan', color: colors.purple[500] },
  { id: 6, full_name: 'Evgenia', color: colors.yellow[500] },
  { id: 7, full_name: 'Klaus', color: colors.onyx },
] as User[];
