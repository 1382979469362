import { GetState, SetState } from 'zustand/vanilla';
import { AppStateInterface } from '../../config/storeTypes';
import { entityNames } from '../../constants/entityNames';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { HTTPError } from 'ky';
import { TagAssociation } from '../../../types/tags';
import {
  deleteJourneyMapTagAssociation,
  getJourneyMapTagAssociations,
  postJourneyMapTagAssociation,
} from '../../../api/journey-map-tags-association/index';

export const journeyMapTagAssociationsSlice = (
  set: SetState<AppStateInterface>,
  get: GetState<AppStateInterface>
) => ({
  getJourneyMapTagAssociations: async (
    projectId: string,
    journeyMapId: string,
    abortSignal?: AbortSignal
  ) => {
    const {
      setEntityStatus,
      addError,
      setDenormalizedData,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.LOADING);
      const journeyMapTagAssociations: TagAssociation[] = await getJourneyMapTagAssociations(
        organization.slug,
        projectId,
        journeyMapId,
        { signal: abortSignal }
      ).json();
      setDenormalizedData(journeyMapTagAssociations, entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS);
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.IDLE);
    } catch (error) {
      const serverError: HTTPError = await error.response.json();
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.ERROR);
      addError(serverError, entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS);
    }
  },
  createJourneyMapTagAssociation: async (
    projectId: string,
    journeyMapId: string,
    tagId: string
  ) => {
    const {
      setEntityStatus,
      addError,
      setDenormalizedData,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.PERSISTING);
      const tagAssociation: TagAssociation = await postJourneyMapTagAssociation(
        organization.slug,
        projectId,
        journeyMapId,
        { json: { tag_id: tagId } }
      ).json();
      setDenormalizedData(tagAssociation, entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS);
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.PERSISTED);
    } catch (error) {
      addError(error, entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS);
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.ERROR);
    }
  },
  deleteJourneyMapTagAssociation: async (
    projectId: string,
    journeyMapId: string,
    tagAssociationId: string
  ) => {
    const {
      setEntityStatus,
      addError,
      removeEntity,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.LOADING);
      setEntityStatus(
        entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS,
        allowedStatuses.LOADING,
        tagAssociationId
      );
      await deleteJourneyMapTagAssociation(
        organization.slug,
        projectId,
        journeyMapId,
        tagAssociationId
      );
      removeEntity(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, tagAssociationId);
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.IDLE);
      setEntityStatus(
        entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS,
        allowedStatuses.IDLE,
        tagAssociationId
      );
    } catch (error) {
      addError(error, entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS);
      setEntityStatus(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS, allowedStatuses.ERROR);
      setEntityStatus(
        entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS,
        allowedStatuses.ERROR,
        tagAssociationId
      );
    }
  },
});
