// https://www.notion.so/morethanmetrics/Plan-Feature-blocking-d7c39d90c3544dfdbf74490c44303c9c
// https://docs.google.com/spreadsheets/d/14rbCYr6g5YfbaMyPF8s_ERLTXHjqkl1EkRrev9KmYbY/edit#gid=1252268541

import { default as ImagePathOnMap } from '../images/path-on-map.svg';
import { enterPrisePlanChargebeeIdYear } from './enterprisePlanChargebeeId';

export const additionalFeatures = {
  project_archive: {
    id: 'project_archive',
    name: 'Project Archive',
    tooltip: 'Archive your projects for a later use.',
    category: 'general',
  },
  journey_map_tags: {
    id: 'journey_map_tags',
    name: 'Journey Map Tags',
    tooltip: 'Add tags to your journey maps',
    category: 'general',
  },
  journey_map_people: {
    id: 'journey_map_people',
    name: 'Journey Map People',
    tooltip: 'Add people to your journey maps',
    category: 'general',
  },
  journey_map_internal_notes: {
    id: 'journey_map_internal_notes',
    name: 'Journey Map Internal Notes',
    tooltip: 'Add internal notes to your journey maps',
    category: 'general',
  },
  journey_canvas: {
    id: 'journey_canvas',
    name: 'Journey Canvas (beta)',
    tooltip: 'Beta-test this upcoming Smaply feature.',
    category: 'hidden',
  },
  // lanes
  journey_map_stages: {
    id: 'journey_map_stages',
    name: 'Stages',
    tooltip: 'Stages help you to categorize the journey maps steps and to keep the overview.',
    category: 'lanes',
  },
  journey_map_steps: {
    id: 'journey_map_steps',
    name: 'Steps',
    tooltip: 'Steps are the single experiences a person/customer/stakeholder has with a service.',
    category: 'lanes',
  },
  journey_map_text: {
    id: 'journey_map_text',
    name: 'Text',
    tooltip: "Text lanes can be used to further describe a persona's experience.",
    category: 'lanes',
  },
  journey_map_storyboard: {
    id: 'journey_map_storyboard',
    name: 'Storyboard',
    tooltip:
      'Storyboards visually represent the sequence of steps through icons, photos or just quick scribbles.',
    category: 'lanes',
  },
  journey_map_emotional_journey: {
    id: 'journey_map_emotional_journey',
    name: 'Emotional journey lane',
    tooltip:
      "The emotional journey lane illustrates the customers' emotions or satisfaction with one specific experience.",
    category: 'lanes',
  },
  journey_map_multiple_journeys: {
    id: 'journey_map_multiple_journeys',
    name: 'Journey Map Multiple Journeys',
    tooltip: 'Compare the journey of different personas in one map.',
    category: 'lanes',
  },
  journey_map_dramatic_arc: {
    id: 'journey_map_dramatic_arc',
    name: 'Journey Map Dramatic Arc',
    tooltip: 'A graph showing a persona’s engagement level with a service over time.',
    category: 'lanes',
  },
  journey_map_channels: {
    id: 'journey_map_channels',
    name: 'Journey Map Channels Lane',
    tooltip:
      'Visualize the channels in which a customer has an interaction with an organization or brand to better understand cross-channel experiences and potential gaps.',
    category: 'lanes',
  },
  journey_map_backstage: {
    id: 'journey_map_backstage',
    name: 'Journey Map Backstage Lane',
    tooltip:
      'Front or backstage actions that are taken to deliver a service that a customer may not or only be partly aware of and able to observe.',
    category: 'lanes',
  },
  journey_map_separator: {
    id: 'journey_map_separator',
    name: 'Journey Map Separator Lane',
    tooltip: 'A separator is a visual aid to help you organize your lanes.',
    category: 'lanes',
  },
  journey_map_description: {
    id: 'journey_map_description',
    name: 'Journey Map Description',
    tooltip:
      'Add a text description to your journey map as an additional info. It will also appear in your exported journey map.',
    category: 'lanes',
  },
  journey_map_live_data: {
    id: 'journey_map_live_data',
    name: 'Journey Map Live Data Lane',
    tooltip:
      'A live data lane allows you to connect a step to an external source of data like a Google Spreadsheet.',
    category: 'lanes',
  },
  journey_map_file: {
    id: 'journey_map_file',
    name: 'Journey Map File Lane',
    tooltip:
      'Use the file lane to attach relevant documents to support each step of your journey map.',
    category: 'lanes',
  },
  journey_map_link: {
    id: 'journey_map_link',
    name: 'Journey Map Link Lane',
    tooltip: 'The journey map link lane allows you to connect a step with related journey maps.',
    category: 'lanes',
  },
  journey_map_jira: {
    id: 'journey_map_jira',
    name: 'Journey Map Jira Lane',
    tooltip: 'The Jira lane allows you to connect a step with related Jira issues.',
    category: 'lanes',
  },
  // exports
  persona_png: {
    id: 'persona_png',
    name: 'Persona PNG Export',
    tooltip: 'Export the persona as PNG image.',
    category: 'exports',
  },
  journey_map_poster_png: {
    id: 'journey_map_poster_png',
    name: 'Journey Map PNG Export',
    tooltip: 'Export the entire journey map as a poster that you can print on a plotter.',
    category: 'exports',
  },
  stakeholder_map_png: {
    id: 'stakeholder_map_png',
    name: 'Stakeholder Map PNG Export',
    tooltip: 'Export the entire stakeholder map as poster.',
    category: 'exports',
  },
  persona_pdf: {
    id: 'persona_pdf',
    name: 'Persona PDF Export',
    tooltip: 'Export the Persona as multi page PDF.',
    category: 'exports',
  },
  journey_map_poster_pdf: {
    id: 'journey_map_poster_pdf',
    name: 'Journey Map Poster PDF Export',
    tooltip: 'Export your entire journey map to a scalable vector PDF.',
    category: 'exports',
  },
  journey_map_emotional_journey_pdf: {
    id: 'journey_map_emotional_journey_pdf',
    name: 'Journey Map Emotional Journey PDF Export',
    tooltip:
      'Export focused on the emotional journey of a persona. Printer-friendly, suitable for A3 or A4 paper.',
    category: 'exports',
  },
  stakeholder_map_pdf: {
    id: 'stakeholder_map_pdf',
    name: 'Stakeholder Map PDF Export',
    tooltip: 'Export stakeholder map to a scalable vector PDF.',
    category: 'exports',
  },
  persona_sharing: {
    id: 'persona_sharing',
    name: 'Persona Sharing',
    tooltip: 'Share a link that will give users read-only access to the persona.',
    category: 'exports',
  },
  journey_map_sharing: {
    id: 'journey_map_sharing',
    name: 'Journey Map Sharing',
    tooltip: 'Share a link that will give users read-only access to the journey map.',
    category: 'exports',
  },
  journey_map_excel_export: {
    id: 'journey_map_excel_export',
    name: 'Journey Map Excel Export',
    tooltip:
      'Export the entire journey map as a spreadsheet that you can use in Microsoft Excel and Google Sheets.',
    category: 'exports',
  },
  journey_map_comment_mode: {
    id: 'journey_map_comment_mode',
    name: 'Journey Map Comment Mode',
    tooltip: 'Activate comment mode to collect feedback on your journey map.',
    category: 'exports',
  },
  journey_map_powerpoint_export: {
    id: 'journey_map_powerpoint_export',
    name: 'Journey Powerpoint Export',
    tooltip:
      'Export the entire journey map as a presentation that you can use in Microsoft PowerPoint and Google Slides.',
    category: 'exports',
  },
  persona_powerpoint_export: {
    id: 'persona_powerpoint_export',
    name: 'Persona Powerpoint Export',
    tooltip:
      'Export the entire persona as a presentation that you can use in Microsoft PowerPoint and Google Slides.',
    category: 'exports',
  },
  sharing_password: {
    id: 'sharing_password',
    name: 'Sharing Password',
    tooltip: 'Project your read only sharing links with password',
    category: 'exports',
  },
  // templates
  enterprise_uploads: {
    id: 'enterprise_uploads',
    name: 'Enterprise Image',
    tooltip: 'Provide your must-use images and logo files. ',
    category: 'templates',
  },
  journey_map_default_templates: {
    id: 'journey_map_default_templates',
    name: 'Journey Map Default Templates',
    tooltip:
      'Predefined templates with a customizable structure help you to easily start visualize your customer experience.',
    category: 'templates',
  },
  project_duplicate: {
    id: 'project_duplicate',
    name: 'Project Duplicate',
    tooltip: 'Easily re-create / build on existing projects.',
    category: 'templates',
  },
  persona_duplicate: {
    id: 'persona_duplicate',
    name: 'Persona Duplicate',
    tooltip: 'Easily re-create / build on existing personas.',
    category: 'templates',
  },
  stakeholder_map_duplicate: {
    id: 'stakeholder_map_duplicate',
    name: 'Stakeholder Map Duplicate',
    tooltip: 'Easily re-create / build on existing stakeholder maps, personas.',
    category: 'templates',
  },
  journey_map_duplicate: {
    id: 'journey_map_duplicate',
    name: 'Journey Map Duplicate',
    tooltip: 'Easily re-create / build on existing journey maps.',
    category: 'templates',
  },
  noun_project: {
    id: 'noun_project',
    name: 'Noun Project Images',
    tooltip:
      'The Noun Project is a curated collection of icons and illustrators created by a global design community.',
    category: 'templates',
  },
  default_images: {
    id: 'default_images',
    name: 'Smaply provided images',
    tooltip:
      'The Noun Project is a curated collection of icons and illustrators created by a global design community.',
    category: 'templates',
  },
  // imports
  capture_mobile: {
    id: 'capture_mobile',
    name: 'Smaply Capture Mobile',
    tooltip: 'Digitize your pen and paper maps via a separate app (Android, iOS).',
    category: 'imports',
  },
  capture_miro: {
    id: 'capture_miro',
    name: 'Smaply Capture Import Miro',
    tooltip: 'Import your journey maps from Miro.',
    category: 'imports',
  },
  capture_mural: {
    id: 'capture_mural',
    name: 'Smaply Capture Import Mural',
    tooltip: 'Import your journey maps from Mural.',
    category: 'imports',
  },
  capture_excel: {
    id: 'capture_excel',
    name: 'Smaply Capture Import Excel',
    tooltip: 'Import your journey maps from Excel.',
    category: 'imports',
  },
  capture_lucid: {
    id: 'capture_lucid',
    name: 'Smaply Capture Import Lucidspark',
    tooltip: 'Import your journey maps from Lucidspark.',
    category: 'imports',
  },
  capture_photo: {
    id: 'capture_photo',
    name: 'Smaply Capture Import Photo',
    tooltip: 'Import your journey maps by using photos or screenshots.',
    category: 'imports',
  },
  // branding
  export_logo: {
    id: 'export_logo',
    name: 'Export logo',
    tooltip: 'Add branding (e.g. your logo) to all exports.',
    category: 'branding',
  },
  subdomain: {
    id: 'subdomain',
    name: 'Custom Subdomain',
    tooltip: 'Have your own login page (e.g. yourcompany.smaply.com).',
    category: 'branding',
  },
  login_background: {
    id: 'login_background',
    name: 'Login Background',
    tooltip: 'Add your logo, background image and customized texts to your login page.',
    category: 'branding',
  },
  login_logo: {
    id: 'login_logo',
    name: 'Login logo',
    tooltip: 'Add your logo, background image and customized texts to your login page.',
    category: 'branding',
  },
  app_logo: {
    id: 'app_logo',
    name: 'In App Logo',
    tooltip: 'Replace all Smaply logos with your own in-app.',
    category: 'branding',
  },
  enterprise_colors: {
    id: 'enterprise_colors',
    name: 'Enterprise color',
    tooltip:
      'Align the used colour scheme (e.g. for text elements) to your CD and make it available to all users.',
    category: 'branding',
  },
  // integrations
  single_sign_on: {
    id: 'single_sign_on',
    name: 'Single Sign On',
    tooltip: 'Better access management by using the ID provider of your choice.',
    category: 'integrations',
  },
  api: {
    id: 'api',
    name: 'API access',
    tooltip:
      'Create personas and maps from within other tools. More possibilities to come in 2022.',
    category: 'integrations',
  },
  key_account_manager: {
    id: 'key_account_manager',
    name: 'Key Account Manager',
    tooltip:
      'An expert by your side. Have one point of contact and benefit from our expertise based on long-term relationships.',
    category: 'integrations',
  },
  hosting_options: {
    id: 'hosting_options',
    name: 'Dedicated instance / on-premise hosting',
    tooltip:
      'Host your account on a single instance (EU or US) or host Smaply on your own server (extra costs apply to both)',
    category: 'integrations',
  },
  contracting: {
    id: 'contracting',
    name: 'Contracting / Procurement handling',
    tooltip: 'Full onboarding services (contracting, SLA, MSA, NDA,...). No extra costs.',
    category: 'integrations',
  },
  // support
  self_service_support: {
    id: 'self_service_support',
    name: 'Self-service support',
    tooltip: 'Learn how to use Smaply and its features best in our helpdesk.',
    category: 'support',
  },
  email_support: {
    id: 'email_support',
    name: '24h email support',
    tooltip: 'Get email tech support with a 24h response time guaranteed.',
    category: 'support',
  },
  phone_support: {
    id: 'phone_support',
    name: "Phone / Chat support (key account's number)",
    tooltip: 'Your key account manager helps you solve problems in a faster and more personal way.',
    category: 'support',
  },
  premium_support: {
    id: 'premium_support',
    name: 'Premium support',
    tooltip: 'Maximum 12 h response time (unless otherwise agreed in contracts).',
    category: 'support',
  },
} as const;

export const organizationLimits = {
  organization_users: {
    id: 'organization_users',
    name: 'Users',
    category: 'general',
  },
  projects: {
    id: 'projects',
    name: 'Projects',
    category: 'general',
  },
  journey_maps: {
    id: 'journey_maps',
    name: 'Journey maps',
    category: 'general',
  },
  personas: {
    id: 'personas',
    name: 'Personas',
    category: 'general',
  },
  stakeholder_maps: {
    id: 'stakeholder_maps',
    name: 'Stakeholder Maps',
    category: 'general',
  },
} as const;

export const featureCategories = {
  general: {
    name: 'General',
  },
  lanes: {
    name: 'Lanes & Elements',
  },
  exports: {
    name: 'Export Types & Features',
  },
  templates: {
    name: 'Templates, icons & images',
  },
  imports: {
    name: 'Imports',
  },
  branding: {
    name: 'Branding',
  },
  integrations: {
    name: 'Business services & integrations',
  },
  support: {
    name: 'Support',
  },
} as const;

const defaultListItems = [
  'Custom logo branding',
  'Different type of exports (e.g. Excel)',
  'Sharing mode',
];

export const contentIfBlocked = {
  generic: {
    title: 'Upgrade your plan to have access to this feature',
    description: '',
    listItems: [] as string[],
    image: '',
    chipName: '',
  },
  journey_map_poster_pdf: {
    title: 'Upgrade your plan to have access to poster PDF export and other features:',
    description: '',
    listItems: defaultListItems,
    image: 'https://app.smaply.com/images/blocked_features/poster-preview-sm.png',
    chipName: 'pdf',
  },
  journey_map_emotional_journey_pdf: {
    title: 'Upgrade your plan to have access to A4 format PDF export and other features:',
    description: '',
    listItems: defaultListItems,
    image: 'https://app.smaply.com/images/blocked_features/a4-preview-small.png',
    chipName: 'pdf',
  },
  journey_map_excel_export: {
    title: 'Upgrade your plan to have access to Excel export and other features:',
    description: '',
    listItems: defaultListItems,
    image: 'https://app.smaply.com/images/blocked_features/xls-preview-small.png',
    chipName: 'xls',
  },
  journey_map_powerpoint_export: {
    title: 'Upgrade your plan to have access to Power Point export and other features:',
    description: '',
    listItems: defaultListItems,
    image: 'https://app.smaply.com/images/blocked_features/ppt-preview-small.png',
    chipName: 'ppt',
  },
  journey_map_sharing: {
    title: 'Upgrade your plan to have access to sharing mode and other features:',
    description: '',
    listItems: defaultListItems,
    image: 'https://app.smaply.com/images/blocked_features/sharing-preview-small.png',
    chipName: '',
  },
  journey_map_comment_mode: {
    title: 'Upgrade your plan to have access to comment mode and other features:',
    description: '',
    listItems: defaultListItems,
    image: 'https://app.smaply.com/images/blocked_features/comment-preview-small.png',
    chipName: '',
  },
  project_limit_reached: {
    title: 'Let your journey continue beyond this milestone!',
    description:
      'Look at you go, you’re becoming a journey mapping pro! It might be time to upgrade your plan!',
    listItems: [
      'Unlimited journey maps, personas and system maps',
      'Custom logo branding',
      'Export to different type of files',
      'Sharing mode',
      'Image library',
    ],
    image: ImagePathOnMap,
    chipName: '',
  },
};

export const userLimitByPlan = {
  'smaply-free-eur-m': '1',
  'smaply-basic-eur-m': '19 Euro / user',
  'smaply-pro-eur-m': '29 Euro / user',
  'smaply-free-eur-a': '1',
  'smaply-basic-eur-a': '199 Euro / user',
  'smaply-pro-eur-a': '299 Euro / user',
  [enterPrisePlanChargebeeIdYear]: 'Custom',
};

/*
The limits needed to be raised for Free plan in backend, when we introduced example/demo projects/maps,
but the original (lower) limit should still be shown in frontend.
We hard-coded the Free plan limits here below now, because we did not want to spend time changing backend at the time.
More info: https://more-than-metrics.slack.com/archives/CD0129AN5/p1662114670097159
*/
export const freePlanLimits = {
  projects: 1,
  personas: 3,
  journey_maps: 3,
  stakeholder_maps: 3,
};
