export const entityNames = {
  ADDITIONAL_FEATURES: 'additionalFeatures',
  BILLING_INFORMATION: 'billingInformation',
  CURRENT_PLAN: 'currentPlan',
  JIRA_INTEGRATION: 'jiraIntegration',
  JIRA_ISSUES: 'jiraIssues',
  JIRA_SELECTED_ISSUE_KEYS: 'jiraSelectedIssueKeys',
  JOURNEY_MAP_EXAMPLES: 'journeyMapExamples',
  JOURNEY_MAP_TAG_ASSOCIATIONS: 'journeyMapTagAssociations',
  JOURNEY_MAP_USER_ASSOCIATIONS: 'journeyMapUserAssociations',
  JOURNEY_MAP: 'journeyMap',
  JOURNEY_MAPS: 'journeyMaps',
  ORGANIZATION: 'organization',
  PLAN_VALIDATION: 'planValidation',
  PLANS: 'plans',
  PROJECT_TAG_ASSOCIATIONS: 'projectTagAssociations',
  SELECTED_PROJECT: 'selectedProject',
  SUBSCRIPTION: 'subscription',
  TAGS: 'tags',
  USERS: 'users',
  WALLSYNC: 'wallsync',
} as const;
