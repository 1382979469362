import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { allowedStatuses } from '../../../../state/constants/allowedStatuses';
import { styled } from '@mui/material/styles';
import { useCharCount } from './useCharCount';
import { CharCountLabel } from '../CharCountLabel/CharCountLabel';
import { InputStateLabel } from '../InputStateLabel/InputStateLabel';
import { Input } from '../Input/Input';
import { InputProps } from '../Input/InputProps';

export type InputCharCountProps = InputProps & {
  maxLength: number;
  minHeight?: number;
  defaultValue?: string;
  onChange?: (value: string) => void;
};

const StyledInput = styled(Input)(() => ({
  '& .MuiInputLabel-root': {
    width: '100%',
  },
}));

export const InputCharCount = ({
  maxLength,
  label,
  minHeight,
  status,
  defaultValue = '',
  onChange,
  ...otherProps
}: InputCharCountProps) => {
  const isLoading = status === allowedStatuses.LOADING || status === allowedStatuses.PERSISTING;
  const isSaved = status === allowedStatuses.PERSISTED;

  const { charCountExceeded, onValueLengthChange } = useCharCount(defaultValue.length, maxLength);
  const [value, setValue] = useState(defaultValue);
  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setValue(newValue);
    onValueLengthChange(newValue.length);
    if (onChange) onChange(event);
  };

  const hasErrors = status === allowedStatuses.ERROR;

  const Label = (
    <Box display="flex" justifyContent="space-between">
      {label}
      <Box display="flex">
        <Box mr={(isLoading || isSaved || hasErrors) && 2}>
          <CharCountLabel
            error={hasErrors || charCountExceeded}
            maxLength={maxLength}
            value={value}
          />
        </Box>
        <InputStateLabel status={status} />
      </Box>
    </Box>
  );

  return (
    <StyledInput
      {...otherProps}
      fullWidth
      onChange={handleChange}
      inputProps={{ style: { minHeight } }}
      label={Label}
      status={charCountExceeded ? allowedStatuses.ERROR : status}
      defaultValue={defaultValue}
    />
  );
};
