import isString from 'lodash/isString';
import { Tag } from '../../types/tags';
import { TagsApi } from './tagsApi';

export const updateTag = async (organizationId: string, data: Partial<Tag>): Promise<Tag> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: Tag = await TagsApi.put(organizationId, data.id, {
      json: data,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
