import React, { memo, useState, useEffect } from 'react';
import { updateTag } from '../../../../api/tags/updateTag';
import { deleteTag } from '../../../../api/tags/deleteTag';
import { postTag as createTag } from '../../../../api/tags/postTag';
import { postProjectTagAssociation } from '../../../../api/project-tags-association/postProjectTagAssociation';
import { deleteProjectTagAssociation } from '../../../../api/project-tags-association/deleteProjectTagAssociation';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import { ProjectTagsInput } from './ProjectTagsInput';
import { TagsOption } from '../TagsOption';
import { TagsEditDialog } from '../TagsEditDialog';
import { TagsDeleteDialog } from '../TagsDeleteDialog';
import { Chip } from '../../../atoms/Chip/Chip';
import { useTags } from './hooks/useTags';
import { useProjectTags } from './hooks/useProjectTags';
import { Tag } from '../../../../types/tags';

export interface TagsProps {
  projectId: string;
}

const TagsComp = ({ projectId }: TagsProps) => {
  const {
    entities: { organization },
  } = useEntities(entityNames.ORGANIZATION);
  const [tags, isTagsLoading, updateTagsList, deleteFromList] = useTags(organization.slug);
  const [projectTags, tagAssociationsList, findTagAssociation] = useProjectTags(
    organization.slug,
    projectId
  );
  const [selectedTags, setSelectedTags] = useState(projectTags);
  const [availableTags, setAvailableTags] = useState(tags);
  const [tagAssociations, setTagAssociations] = useState(tagAssociationsList);
  const [editDialogOpen, toggleEditDialog] = useState(false);
  const [deleteDialogOpen, toggleDeleteDialog] = useState(false);
  const [editDialogValue, setEditDialogValue] = useState<Tag | null>(null);
  const [deleteDialogValue, setDeleteDialogValue] = useState<Tag | null>(null);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setAvailableTags(tags);
      setSelectedTags(projectTags);
      setTagAssociations(tagAssociationsList);
    }

    return () => {
      isMounted = false;
    };
  }, [tags, projectTags, tagAssociationsList]);

  const handleEditClose = () => {
    toggleEditDialog(false);
    setEditDialogValue(null);
  };

  const handleDeleteClose = () => {
    toggleDeleteDialog(false);

    if (!deleteDialogOpen) {
      setDeleteDialogValue(null);
    }
  };

  const handleEditSubmit = async (event: any) => {
    event.preventDefault();

    const updatedTag = await updateTag(organization.slug, editDialogValue);

    setSelectedTags((prevState) => updateTagsList(prevState, updatedTag));
    setAvailableTags((prevState) => updateTagsList(prevState, updatedTag));

    handleEditClose();
  };

  const handleDeleteSubmit = async (event: any) => {
    event.preventDefault();

    await deleteTag(organization.slug, deleteDialogValue.id);
    const tagAssociation = findTagAssociation(deleteDialogValue, tagAssociations);

    setSelectedTags((prevState) => deleteFromList(prevState, deleteDialogValue));
    setAvailableTags((prevState) => deleteFromList(prevState, deleteDialogValue));

    if (tagAssociation) {
      setTagAssociations((prevState) => deleteFromList(prevState, tagAssociation));
    }

    handleDeleteClose();
  };

  const onTagSelect = async (tag: Tag) => {
    const newTagAssociation = await postProjectTagAssociation(organization.slug, projectId, tag.id);

    setTagAssociations((prevState) => [...prevState, newTagAssociation]);
    setSelectedTags((prevState) => [...prevState, tag]);
  };

  const onTagCreate = async (value: Tag | string) => {
    const newTag = await createTag(
      organization.slug,
      typeof value === 'string' ? value : value.name
    );
    await onTagSelect(newTag);

    setAvailableTags((prevState) => [...prevState, newTag]);
  };

  const onTagDeselect = async (tag: Tag) => {
    const tagAssociation = findTagAssociation(tag, tagAssociations);
    await deleteProjectTagAssociation(organization.slug, projectId, tagAssociation.id);

    setSelectedTags((prevState) => deleteFromList(prevState, tag));
    setTagAssociations((prevState) => deleteFromList(prevState, tagAssociation));
  };

  const onTagEdit = (event: React.FormEvent, tag: Tag) => {
    event.preventDefault();
    event.stopPropagation();

    setEditDialogValue((prevState) => ({
      ...prevState,
      ...tag,
    }));

    toggleEditDialog(true);
  };

  const onTagDelete = (event: React.FormEvent, tag: Tag) => {
    event.preventDefault();
    event.stopPropagation();

    setDeleteDialogValue(tag);
    toggleDeleteDialog(true);
  };

  return (
    <>
      <ProjectTagsInput
        loading={isTagsLoading}
        selectedTags={selectedTags}
        availableTags={availableTags}
        limitTags={10}
        variant="default"
        inputLabel="project tags"
        inputPlaceholder="add tag"
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <Chip
              key={index}
              size="small"
              disabled={index === 0}
              label={option.name}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderOption={(props, option) => {
          return (
            <TagsOption
              {...props}
              option={option}
              handleDeleteClick={(event) => {
                onTagDelete(event, option);
              }}
              handleEditClick={(event) => {
                onTagEdit(event, option);
              }}
            />
          );
        }}
        handleChange={(event, value: Tag[], reason, details) => {
          // https://github.com/mui-org/material-ui/issues/26643
          if (event.type === 'blur') {
            return;
          }

          if (reason === 'createOption' || details.option.newTag) {
            // create-option is only triggered by enter
            // newTag flag is pushed during filtering stage to distinguish for mouselick.

            // for selecting/deselecting already existing tags on enter
            const availableTag = availableTags.find((tag) => details.option === tag.name);

            if (availableTag) {
              const selectedTag = selectedTags.find((tag) => tag.id === availableTag.id);

              // eslint-disable-next-line
              selectedTag ? onTagDeselect(selectedTag) : onTagSelect(availableTag);
            } else {
              onTagCreate(details.option);
            }
          } else if (reason === 'removeOption') {
            onTagDeselect(details.option as Tag);
          } else if (reason === 'selectOption') {
            onTagSelect(details.option as Tag);
          }
        }}
      />
      <TagsEditDialog
        open={editDialogOpen}
        handleClose={handleEditClose}
        handleInputChange={(value) => {
          setEditDialogValue({ ...editDialogValue, name: value });
        }}
        handleSubmit={handleEditSubmit}
        tag={editDialogValue}
      />
      <TagsDeleteDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteClose}
        handleSubmit={handleDeleteSubmit}
        tag={deleteDialogValue}
      />
    </>
  );
};

export const ProjectTags = memo(TagsComp);
