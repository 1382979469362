import React from 'react';
import { nanoid } from 'nanoid';
import { Typography, Box } from '@mui/material';
import Link from '@mui/material/Link';
import { useTheme, Theme } from '@mui/material/styles';
import { entityNames } from '../../../state/constants/entityNames';
import { useEntities } from '../../../state/hooks/useEntities';
import { SUPPORT_EMAIL } from '../../../constants/emailAddresses';
import { colors } from '../../../theme';
import { ButtonBase } from '../../atoms/ButtonBase/ButtonBase';
import { Template } from '../../templates/Template';
import { PlanCardSkeleton } from './PlanCardSkeleton';
import { userRoles } from '../../../constants/userRoles';
import { billingHasPlanPrice } from '../../../business-logic/billingInformation';
import { ErrorMessage } from '../Alerts/GenericAlert';
import { Card } from '../../atoms/Card/Card';
import { currencyTranslations } from '../../../constants/currencyTranslations';
import { CardContent } from '../../atoms/Card/CardContent';
import { IconText } from '../../atoms/Icon/IconText';
import { subscriptionStatus } from '../../../constants/subscriptionStatus';
import { subscriptionIsInTrial } from '../../../business-logic/subscription';

export type PlanCardProps = {
  userIsAdmin?: Boolean;
  actions?: Array<{ name: string; onClick?: (evt: React.FormEvent) => void }>;
};

// @TODO move this to translations
const billingFrequencyTranslations = {
  month: 'Per user, billed monthly',
  year: 'Per user, billed annually',
};

export const PlanCard = ({ actions }: PlanCardProps) => {
  const theme: Theme = useTheme();

  const {
    entities: { billingInformation, subscription, currentPlan, organization },
    hasErrors,
    isLoading,
  } = useEntities(
    entityNames.BILLING_INFORMATION,
    entityNames.CURRENT_PLAN,
    entityNames.SUBSCRIPTION,
    entityNames.ORGANIZATION
  );

  const userIsAdmin = Boolean(organization.role === userRoles.ADMIN);

  const shouldDisplayPriceAndVAT =
    billingHasPlanPrice(billingInformation) && userIsAdmin && subscriptionIsInTrial(subscription);

  if (hasErrors) {
    return <ErrorMessage />;
  }

  return (
    <Template
      skeleton={<PlanCardSkeleton />}
      isLoading={isLoading}
      errorComponent={<ErrorMessage />}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <CardContent sx={{ backgroundColor: colors.blue[200] }}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <Typography id="current-plan-name" component="p" variant="boldXXL">
                {currentPlan.display_name}
              </Typography>
              {shouldDisplayPriceAndVAT && (
                <Typography component="p" variant="boldXXL" color={colors.iron}>
                  {`${billingInformation.plan_price} ${
                    currencyTranslations[currentPlan.currency_code]
                  }`}
                </Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="start">
              {currentPlan.status === subscriptionStatus.ACTIVE && (
                <IconText
                  iconName="check-double"
                  fontVariant="boldXXS"
                  color={colors.green[500]}
                  text="YOUR CURRENT PLAN"
                />
              )}
              {shouldDisplayPriceAndVAT && (
                <Box>
                  <Typography component="p" color={colors.carbon} variant="regularXXS">
                    {billingFrequencyTranslations[billingInformation.billing_frequency]}
                  </Typography>
                  <Typography
                    textAlign="right"
                    component="p"
                    color={colors.carbon}
                    variant="regularXXS"
                  >
                    {billingInformation.taxable ? `Incl. VAT ${billingInformation.tax_rate}%` : ''}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {actions && userIsAdmin && (
            <Box display="flex" flexDirection="column" mt={4}>
              {actions &&
                actions.map((action) => (
                  <Box key={nanoid()} mt={theme.spacing(2)}>
                    <ButtonBase onClick={action.onClick}>
                      <IconText
                        text={action.name}
                        iconName="edit"
                        fontVariant="boldXXS"
                        color={colors.iron}
                        hoverColor={colors.onyx}
                      />
                    </ButtonBase>
                  </Box>
                ))}
            </Box>
          )}
        </CardContent>
        <Box mt={4} display="flex">
          <Link href="https://www.smaply.com/terms-conditions" target="_blank">
            <IconText
              text="TERMS AND CONDITIONS"
              iconName="arrow-circle-right"
              fontVariant="boldXXS"
              color={colors.aluminium}
            />
          </Link>
          <Link ml={4} href={`mailto:${SUPPORT_EMAIL}`}>
            <IconText
              text="CONTACT SUPPORT"
              iconName="info-circle"
              fontVariant="boldXXS"
              color={colors.aluminium}
            />
          </Link>
        </Box>
      </Card>
    </Template>
  );
};
