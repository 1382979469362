import { useEffect, useRef, useState } from 'react';
import { default as produce, Draft } from 'immer';
import { allowedStatuses } from '../constants/allowedStatuses';
import { useStore } from '../config/store';
import { AppStateInterface, EntitiesState, EntityTypes, ErrorType } from '../config/storeTypes';
import { reduceEntities } from './utils/reduceEntities';
import { getStaticState } from '../helpers/getStaticState';
import { AllowedStatuses, EntityNames } from '../../types/state';

const getInitialState = (entityNames: EntityNames[], state: AppStateInterface) => {
  return {
    entities: reduceEntities(entityNames, state),
    statuses: reduceEntities(entityNames, state, 'statuses'),
    errors: reduceEntities(entityNames, state, 'errors'),
    hasErrors: false,
    isLoading: true,
  };
};

type UseEntitiesState = {
  entities: Partial<{ [key in EntityNames]: EntitiesState[key] }>;
  statuses: Partial<{ [key in EntityNames]: AllowedStatuses }>;
  errors: Partial<{ [key in EntityNames]: ErrorType | ErrorType[] }>;
  hasErrors: boolean;
  isLoading: boolean;
};

const isLoading = ({ statuses }: UseEntitiesState) =>
  Object.keys(statuses)
    // @ts-ignore
    .map((key) => statuses[key])
    .includes(allowedStatuses.LOADING || allowedStatuses.PERSISTING);

const hasErrors = ({ statuses }: Draft<UseEntitiesState>) =>
  Object.keys(statuses)
    // @ts-ignore
    .map((key) => statuses[key])
    .includes(allowedStatuses.ERROR);

export const useEntities = (...entityNames: EntityNames[]): UseEntitiesState => {
  const staticState = getStaticState();
  const stateRef = useRef<UseEntitiesState>(getInitialState(entityNames, staticState));
  const [entitiesState, setEntitiesState] = useState<UseEntitiesState>(stateRef.current);

  const onStateChange = (
    entityState: EntityTypes | AllowedStatuses | ErrorType[],
    stateKey: keyof EntitiesState | 'entities',
    entityName: EntityNames
  ) => {
    stateRef.current = produce(stateRef.current, (draft) => {
      /* eslint-disable no-param-reassign */
      // @ts-ignore
      draft[stateKey][entityName] = entityState;
      draft.hasErrors = hasErrors(draft);
    });

    if (!isLoading(stateRef.current)) {
      stateRef.current = { ...stateRef.current, isLoading: false };
      setEntitiesState(stateRef.current);
    }
  };

  useEffect(() => {
    const subscriptions = [] as any[];

    entityNames.forEach((entityName) => {
      subscriptions.push(
        useStore.subscribe(
          (state: AppStateInterface) => state.entities[entityName],
          (entityState: EntityTypes) => onStateChange(entityState, 'entities', entityName)
        )
      );

      subscriptions.push(
        useStore.subscribe(
          (state: AppStateInterface) => state.entities.statuses[entityName],
          (entityState: AllowedStatuses) => onStateChange(entityState, 'statuses', entityName)
        )
      );

      subscriptions.push(
        useStore.subscribe(
          (state: AppStateInterface) => state.entities.errors[entityName],
          (entityState: ErrorType[]) => onStateChange(entityState, 'errors', entityName)
        )
      );
    });

    return function cleanup() {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [entityNames]);

  return entitiesState;
};
