import { useEffect, useState } from 'react';
import { getTags } from '../../../../../api/tags/getTags';
import { Tag } from '../../../../../types/tags';
import { useAbortSignal } from '../../../../../utils/hooks/useAbortSignal';

export const useTags = (
  organizationId: string
): [Tag[], boolean, typeof updateList, typeof removeFromList, Error] => {
  const [resultList, setResultList]: [Tag[], React.Dispatch<React.SetStateAction<Tag[]>>] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const abortSignal: AbortSignal = useAbortSignal();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: Array<Tag> = await getTags(organizationId, abortSignal);

        setResultList(data);
      } catch (e) {
        setResultList([]);
        setError(e);

        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [organizationId, abortSignal]);

  const updateList = (tagsList: Tag[], tag: Tag) => {
    const index = tagsList.findIndex((t) => t.id === tag.id);
    const newTags = [...tagsList];
    newTags[index] = tag;

    return newTags;
  };

  const removeFromList = <T extends { id: number }>(list: T[], item: T) => {
    return list.filter((i) => item.id !== i.id);
  };

  return [resultList, isLoading, updateList, removeFromList, error];
};
