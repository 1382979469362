import React from 'react';
import { Box } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { colors } from '../../../theme';

export type CircleProps = {
  label: string;
  size?: number;
  color?: string;
  colorText?: string;
};

export const Circle = ({
  label = '',
  size = 6,
  color = colors.blue[500],
  colorText = colors.white,
}: CircleProps) => {
  const theme: Theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        minHeight: theme.spacing(size),
        minWidth: theme.spacing(size),
        maxHeight: theme.spacing(size),
        maxWidth: theme.spacing(size),
        background: color,
        borderRadius: '50%',
      }}
    >
      <Typography variant="regularXXS" color={colorText}>
        {label}
      </Typography>
    </Box>
  );
};
