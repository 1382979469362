import './DefaultRichTextInput.css';
import { nanoid } from 'nanoid';
import React, { useState, useRef, useEffect } from 'react';
import isString from 'lodash/isString';
import Box from '@mui/material/Box';
import { allowedStatuses } from '../../../../../state/constants/allowedStatuses';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import { TextEditorToolbar } from '../../../TextEditorToolbar/TextEditorToolbar';
import { QuillFormats } from '../quillFormats';
import { RichTextInputProps, RichTextInputValue } from '../RichTextInputProps';
import { InputLabel } from '../../InputLabel/InputLabel';
import { colors } from '../../../../../theme';
import { InputStateLabel } from '../../InputStateLabel/InputStateLabel';
import { fixBlurOnPasteIssue } from '../helpers/fixBlurOnPasteIssue';

const StyledTextEditor = styled(Box)(({ theme }) => ({
  background: colors.white,
  borderRadius: theme.shape.borderRadius,
  '&.Mui-error': {
    backgroundColor: theme.palette.error.light,
  },
  '&:focus-within:not(.Mui-error)': {
    color: colors.blue[600],
    backgroundColor: colors.blue[200],
    '& .MuiInputLabel-root': {
      color: colors.blue[600],
    },
  },
  '.ql-container': {
    ...theme.typography.regularS,
  },
}));

const formats: QuillFormats = ['bold', 'italic', 'list', 'link'];

export const DefaultRichTextInput = ({
  id,
  initialValue,
  value: inputValue,
  disabled,
  label,
  status = allowedStatuses.IDLE,
  fullHeight,
  onChange,
  onBlur,
  onEditorMount,
  onFocus,
}: Partial<RichTextInputProps>) => {
  const [value, setValue] = useState<string>(initialValue ? initialValue.html : '');
  const errorClassName = status === allowedStatuses.ERROR ? 'Mui-error' : '';
  const isLoading = status === allowedStatuses.LOADING;

  const editorIdRef = useRef(`${id}_${nanoid()}`);
  const toolbarId = `toolbar_${editorIdRef.current}`;
  const modules = {
    toolbar: `#${toolbarId}`,
  };
  const [toolbarRendered, setToolbarRendered] = useState<boolean>(false);

  const editorRef = useRef(null);
  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      if (onEditorMount) onEditorMount(editor);
    }
  }, [editorRef, toolbarRendered, onEditorMount]);

  return (
    <StyledTextEditor
      id={editorIdRef.current}
      sx={fullHeight && { height: '100%' }}
      position="relative"
      className={errorClassName}
    >
      <Box>
        {label && isString(label) ? (
          <InputLabel
            sx={{
              position: 'static',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            className={errorClassName}
          >
            {label}
            <InputStateLabel status={status} />
          </InputLabel>
        ) : (
          label
        )}
      </Box>
      <Box ml={1}>
        <TextEditorToolbar
          id={toolbarId}
          buttonActiveClassName="ql-active"
          buttonClassNames={{
            bold: 'ql-bold',
            italic: 'ql-italic',
            list: 'ql-list',
          }}
          onDOMElementRendered={() => setToolbarRendered(true)}
        />
      </Box>
      {toolbarRendered && (
        <ReactQuill
          ref={editorRef}
          style={fullHeight && { height: '100%' }}
          modules={modules}
          formats={formats}
          theme={null}
          value={inputValue ? inputValue.html : value}
          readOnly={isLoading || disabled}
          onChange={(content, delta, source, editor) => {
            const currentValue: RichTextInputValue = {
              html: content,
              text: editor.getText(),
            };
            if (onChange) {
              onChange(currentValue);
            } else {
              setValue(content);
            }
          }}
          onBlur={(previousRange, source, editor) => {
            fixBlurOnPasteIssue(editor, onBlur);
          }}
          onFocus={() => {
            if (onFocus) onFocus();
          }}
        />
      )}
    </StyledTextEditor>
  );
};
