import ky, { Options } from 'ky';
/**
 * Acts as an interface to globalThis.fetch with timeout. If the request takes
 * more than the specified time then it rejects the timeout with an error.
 *
 * @param {RequestInfo} url - URL string or a Request object.
 * @param {RequestInit} [options + timeout key] - The options object to be passed to http.request. More info in https://fetch.spec.whatwg.org/#requestinit
 * @returns {Promise} Returns the response promise or a timeout promise which rejects with an error
 * @example
 *
 * fetchWithTimeout('https://www.example.com')
 * // => Promise<pending>
 *
 * fetchWithTimeout('https://www.example.com', { method: 'POST' })
 * // => Promise<pending>
 *
 * fetchWithTimeout('https://www.example.com', { method: 'POST', timeout: 2000 })
 * // => Promise<pending>
 */

// export const fetchWithTimeout = async (
//   url: RequestInfo,
//   options?: RequestInit,
//   timeout = 1000
// ): Promise<Response | ReturnType<typeof setTimeout>> => {
//   let timeoutId: ReturnType<typeof setTimeout>;
//   const challenger: Promise<ReturnType<typeof setTimeout>> = new Promise<never>((_, reject) => {
//     timeoutId = setTimeout(() => reject(new Error('timeout')), timeout);
//   });
//   const contender: Promise<Response> = fetch(url, options);
//   try {
//     return Promise.race([contender, challenger]);
//   } finally {
//     clearTimeout(timeoutId);
//   }
// };

export const fetchWithTimeout = async (
  url: RequestInfo,
  options?: Options,
  timeout = 1000
): Promise<Response> => {
  const controller = new AbortController();

  setTimeout(() => controller.abort(), timeout);

  const response = await ky(url, {
    ...options,
    signal: controller.signal,
  });

  return response;
};
