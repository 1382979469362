import { journeyMaps } from '../../mocks/journeyMap';
import { organization } from '../../mocks/organization';
import { currentPlan } from '../../mocks/plans';
import { selectedProject } from '../../mocks/selectedProject';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { JiraIntegration, JiraIssue } from '../../types/jira';
import { JourneyMap, JourneyMapExample } from '../../types/journeyMap';
import { Organization } from '../../types/organization';
import { BillingInformation, CurrentPlan, PlanValidation, Subscription } from '../../types/pricing';
import { Project } from '../../types/project';
import { AdditionalFeatureType } from '../../types/subscription';
import { WallsyncMetaData } from '../../types/wallsync';
import { allowedStatuses } from '../constants/allowedStatuses';
import { AppStateInterface, StoreMethodsNames } from './storeTypes';

export const appInitialState: Omit<AppStateInterface, StoreMethodsNames> = {
  entities: {
    additionalFeatures: [] as AdditionalFeatureType[],
    billingInformation: {} as BillingInformation,
    currentPlan: shouldMockSmaplyAPI ? (currentPlan as CurrentPlan) : ({} as CurrentPlan),
    jiraIntegration: {} as JiraIntegration,
    jiraIssues: [] as JiraIssue[],
    jiraSelectedIssueKeys: [],
    journeyMap: {} as JourneyMap,
    journeyMapExamples: [] as JourneyMapExample[],
    journeyMaps: shouldMockSmaplyAPI ? journeyMaps : ([] as JourneyMap[]),
    journeyMapTagAssociations: {},
    journeyMapUserAssociations: {},
    organization: shouldMockSmaplyAPI ? organization : ({} as Organization),
    plans: {},
    planValidation: {} as PlanValidation,
    projectTagAssociations: {},
    selectedProject: shouldMockSmaplyAPI ? selectedProject : ({} as Project),
    subscription: {} as Subscription,
    tags: {},
    users: {},
    wallsync: {} as WallsyncMetaData,
    result: {
      additionalFeatures: [],
      jiraIntegration: [],
      journeyMapTagAssociations: [],
      journeyMapUserAssociations: [],
      plans: [],
      projectTagAssociations: [],
      tags: [],
      users: [],
    },
    errors: {
      additionalFeatures: [],
      billingInformation: [],
      currentPlan: [],
      jiraIntegration: [],
      jiraIssues: [],
      jiraSelectedIssueKeys: [],
      journeyMap: [],
      journeyMapExamples: [],
      journeyMaps: [],
      journeyMapTagAssociations: [],
      journeyMapUserAssociations: [],
      organization: [],
      plans: [],
      planValidation: [],
      projectTagAssociations: [],
      selectedProject: [],
      subscription: [],
      tags: [],
      users: [],
      wallsync: [],
    },
    statuses: {
      additionalFeatures: allowedStatuses.LOADING,
      billingInformation: allowedStatuses.LOADING,
      currentPlan: allowedStatuses.LOADING,
      jiraIntegration: allowedStatuses.LOADING,
      jiraIssues: allowedStatuses.LOADING,
      jiraSelectedIssueKeys: allowedStatuses.IDLE,
      journeyMap: allowedStatuses.IDLE,
      journeyMapExamples: allowedStatuses.IDLE,
      journeyMaps: allowedStatuses.IDLE,
      journeyMapTagAssociations: allowedStatuses.LOADING,
      journeyMapUserAssociations: allowedStatuses.IDLE,
      organization: allowedStatuses.IDLE,
      plans: allowedStatuses.LOADING,
      planValidation: allowedStatuses.LOADING,
      projectTagAssociations: allowedStatuses.LOADING,
      selectedProject: allowedStatuses.IDLE,
      subscription: allowedStatuses.LOADING,
      tags: allowedStatuses.LOADING,
      users: allowedStatuses.LOADING,
      wallsync: allowedStatuses.LOADING,
    },
  },
};
