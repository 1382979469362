import { BlockedFeatureBox, BlockedFeatureDialog } from './blocked_feature_box';
import { CreateJourneyMap } from './journey_map';
import { JiraIssueCard, JiraIssuesDialog, JiraSettings } from './jira';
import { LearnMenuHeader, LearnMenuHeaderBare } from './learn_menu';
import { MetadataBar } from './metadata_bar';
import { PlanTemplate, PlanAlertTrialEnded, PlanAlertLocked } from './plan';
import { ProjectTags } from './tags';
import { Slider } from './slider';

export const ReactComponents: { [componentName: string]: any } = {
  BlockedFeatureBox,
  BlockedFeatureDialog,
  CreateJourneyMap,
  JiraIssueCard,
  JiraIssuesDialog,
  JiraSettings,
  LearnMenuHeader,
  LearnMenuHeaderBare,
  MetadataBar,
  PlanAlertLocked,
  PlanAlertTrialEnded,
  PlanTemplate,
  ProjectTags,
  Slider,
};

export default ReactComponents;
