import { JourneyMapTagAssociationsApi } from './journeyMapTagAssociationApi';

const {
  get: getJourneyMapTagAssociations,
  post: postJourneyMapTagAssociation,
  remove: deleteJourneyMapTagAssociation,
} = JourneyMapTagAssociationsApi;

export {
  getJourneyMapTagAssociations,
  postJourneyMapTagAssociation,
  deleteJourneyMapTagAssociation,
};
