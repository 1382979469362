import { AppStateInterface } from '../config/storeTypes';
import { useStore } from '../config/store';
import { OrganizationLimits } from '../../types/subscription';

export const useOrganizationLimits = (limitsToCheck: Partial<OrganizationLimits>) => {
  const limits = useStore((state: AppStateInterface) => state.entities.organization.limits);

  const limitsReached: Partial<Record<keyof OrganizationLimits, boolean>> = Object.keys(
    limitsToCheck
  ).reduce(
    (accumulator, limitKey) => ({
      ...accumulator,
      // @ts-ignore
      [limitKey]: limitsToCheck[limitKey] >= limits[limitKey],
    }),
    {}
  );

  return {
    limits,
    limitsReached,
  };
};
