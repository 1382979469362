import React from 'react';
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';
import MuiTableBody, { TableBodyProps as MuiTableBodyProps } from '@mui/material/TableBody';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@mui/material/TableCell';
import MuiTableContainer, {
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material/TableContainer';
import MuiTableHead, { TableHeadProps as MuiTableHeadProps } from '@mui/material/TableHead';
import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { colors } from '../../../theme';

export const Table = (props: MuiTableProps) => <MuiTable {...props} />;

export const TableBody = (props: MuiTableBodyProps) => <MuiTableBody {...props} />;

const StyledTableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  border: 'none',
  boxShadow: `inset 0px 1px 0px ${colors.silver}`,
}));
export const TableCell = (props: MuiTableCellProps) => <StyledTableCell {...props} />;

export const TableContainer = (props: MuiTableContainerProps) => <MuiTableContainer {...props} />;

export const TableHead = (props: MuiTableHeadProps) => <MuiTableHead {...props} />;

export const TableRow = (props: MuiTableRowProps) => <MuiTableRow {...props} />;
