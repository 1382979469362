import create from 'zustand/vanilla';
import createUseStore, { GetState, SetState } from 'zustand';
import {
  devtools,
  StoreApiWithSubscribeWithSelector,
  subscribeWithSelector,
} from 'zustand/middleware';
import flow from 'lodash/flow';
import { entitySlice } from '../slices/entitySlice';
import { normalizeMiddleware } from './normalizeMiddleware';
import { appInitialState } from './initialState';
import { AppStateInterface } from './storeTypes';

const applyMiddlewares = flow(devtools, subscribeWithSelector, normalizeMiddleware);

export const store = create<
  AppStateInterface,
  SetState<AppStateInterface>,
  GetState<AppStateInterface>,
  StoreApiWithSubscribeWithSelector<AppStateInterface>
>(
  applyMiddlewares((set: SetState<AppStateInterface>, get: GetState<AppStateInterface>) => ({
    ...appInitialState,
    ...entitySlice(set, get),
  }))
);

export const useStore = createUseStore(store);
