import { Options } from 'ky';
import { get, post, remove } from '../config';

export const UserAssociationApi = {
  get: (organizationId: string, projectId: string, journeyMapId: string, params?: Options) =>
    get(
      `web/v1/organizations/${organizationId}/projects/${projectId}/journey_maps/${journeyMapId}/user_associations`,
      params
    ),
  post: (organizationId: string, projectId: string, journeyMapId: string, userId: string) =>
    post(
      `web/v1/organizations/${organizationId}/projects/${projectId}/journey_maps/${journeyMapId}/user_associations`,
      { json: { user_id: userId } }
    ),
  remove: (organizationId: string, projectId: string, journeyMapId: string, id: string) =>
    remove(
      `web/v1/organizations/${organizationId}/projects/${projectId}/journey_maps/${journeyMapId}/user_associations/${id}`
    ),
};
