import React from 'react';
import { render } from '@testing-library/react';
import { ThemeProvider } from '@mui/material';
import { contentIfBlocked } from '../../../constants/additionalFeatures';
import { BlockedFeatureBox } from './BlockedFeatureBox';
import { K2Theme } from '../../../theme';

describe('BlockedFeatureBox', () => {
  it('should render', () => {
    const { baseElement } = render(
      <ThemeProvider theme={K2Theme}>
        <BlockedFeatureBox feature={Object.keys(contentIfBlocked)[0]} />
      </ThemeProvider>
    );

    expect(baseElement).toBeTruthy();
  });
});
