import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

// temp since the alert thats been made for pricing is not an atom it has way too much logic and non base styles
// it should be moved to molecules and we need to make a more dumb generic one.
export const Alert = styled(MuiAlert)(() => ({
  '& .MuiAlert-message': {
    display: 'flex',
    flexGrow: 1,
  },
}));
