import React from 'react';
import MuiSlider, { SliderProps as MuiSliderProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { colors } from '../../../theme';

export interface SliderProps extends MuiSliderProps {
  onChangeCommitted?: (event: React.SyntheticEvent | Event, value: number | number[]) => void;
  defaultValue?: number;
}

export const StyledSlider = styled(MuiSlider)(() => ({
  color: colors.blue['300'],
  '.MuiSlider-thumb': {
    color: colors.blue['500'],
  },
  '.MuiSlider-rail': {
    opacity: 1, // To make the lines similar color on both sides of the circle.
  },
  '.MuiSlider-mark': {
    color: colors.blue['500'],
  },
  '.MuiSlider-markActive': {
    color: colors.blue['500'],
  },
}));

export const Slider = ({ onChangeCommitted, defaultValue, ...rest }: SliderProps) => {
  return (
    <StyledSlider defaultValue={defaultValue} onChangeCommitted={onChangeCommitted} {...rest} />
  );
};
