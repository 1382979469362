import React from 'react';
import { useEntities } from '../../../state/hooks/useEntities';
import { entityNames } from '../../../state/constants/entityNames';
import { Typography } from '@mui/material';
import { colors } from '../../../theme';
import { ButtonBase } from '../../atoms/ButtonBase/ButtonBase';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '../../atoms/Table/Table';
import { planDisplayNames } from '../../../constants/planDisplayNames';
import { currencyTranslations } from '../../../constants/currencyTranslations';

export type PlanTableProps = {
  handleRedirectToInvoices: () => Promise<void>;
};

const billingFrequencyTranslations = {
  month: 'Monthly',
  year: 'Annually',
};

export const PlanTable = ({ handleRedirectToInvoices }: PlanTableProps) => {
  /* eslint-disable */
  const {
    entities: {
      currentPlan: { chargebee_id, currency_code },
      billingInformation: {
        plan_price_net,
        taxable,
        tax_amount,
        tax_rate,
        total_amount,
        billing_frequency,
        next_billing_date,
        user_addon_price,
        user_addons,
        user_addon_total_price_net,
        included_users,
      },
    },
  } = useEntities(entityNames.BILLING_INFORMATION, entityNames.CURRENT_PLAN);
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="regularXS" color={colors.onyx}>
                Plan
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="regularXS" color={colors.iron}>
                {planDisplayNames[chargebee_id]}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="regularXS" color={colors.iron}>
                {`${plan_price_net.toFixed(2)} ${currencyTranslations[currency_code]}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="regularXS" color={colors.onyx}>
                Users (included)
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="regularXS" color={colors.iron}>
                {included_users}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="regularXS" color={colors.iron}>
                {`0.00 ${currencyTranslations[currency_code]}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="regularXS" color={colors.onyx}>
                {`Additional users (${user_addon_price} ${currencyTranslations[currency_code]} per user)`}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="regularXS" color={colors.iron}>
                {user_addons}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="regularXS" color={colors.iron}>
                {`${user_addon_total_price_net.toFixed(2)} ${currencyTranslations[currency_code]}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="regularXS" color={colors.onyx}>
                {`VAT ${tax_rate}%`}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="regularXS" color={colors.iron}>
                {taxable ? 'Applicable' : 'Not applicable'}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="regularXS" color={colors.iron}>
                {`${tax_amount.toFixed(2)} ${currencyTranslations[currency_code]}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: colors.smoke }}>
            <TableCell>
              <Typography variant="boldXS" color={colors.onyx}>
                TOTAL
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ textTransform: 'uppercase' }} variant="boldXS" color={colors.onyx}>
                {billingFrequencyTranslations[billing_frequency]}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="boldXS" color={colors.onyx}>
                {`${total_amount.toFixed(2)} ${currencyTranslations[currency_code]}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="regularXS" color={colors.onyx}>
                Next invoice
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="regularXS" color={colors.iron}>
                {next_billing_date}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <ButtonBase onClick={handleRedirectToInvoices}>
                <Typography variant="regularXS" color={colors.blue[500]}>
                  View invoices
                </Typography>
              </ButtonBase>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
