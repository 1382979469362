import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { useBackboneView } from './hooks/useBackboneView';
import { BackboneViewType } from './ReactBackboneTypes';

interface BackboneViewProps {
  backboneView: BackboneViewType<unknown>;
}

export const BackboneView = ({ backboneView }: BackboneViewProps) => {
  const id = uniqueId();
  useBackboneView(backboneView, `#${id}`);
  return <div id={id} />;
};
