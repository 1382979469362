export type Color = string;

export type MuiColor = {
  200: Color;
  300: Color;
  400: Color;
  500: Color;
  600: Color;
  700: Color;
};

export type ColorsType = {
  white: Color;
  fog: Color;
  smoke: Color;
  silver: Color;
  aluminium: Color;
  iron: Color;
  carbon: Color;
  onyx: Color;
  black: Color;
  red: MuiColor;
  orange: MuiColor;
  yellow: MuiColor;
  green: MuiColor;
  blue: MuiColor;
  purple: MuiColor;
  pink: MuiColor;
};

export const mono = {
  white: '#ffffff',
  fog: '#f9f9f9',
  smoke: '#ebebeb',
  silver: '#cdcfcf',
  aluminium: '#a1a6a6',
  iron: '#6a7272',
  carbon: '#3c4646',
  onyx: '#212525',
  black: '#000000',
};

export const colors: ColorsType = {
  ...mono,
  red: {
    200: '#fef0f0',
    300: '#fdd8d8',
    400: '#fab0b0',
    500: '#f46060',
    600: '#c34c4c',
    700: '#7a3030',
  },
  orange: {
    200: '#fff3ed',
    300: '#fde0d2',
    400: '#fbc1a4',
    500: '#f68349',
    600: '#c4683a',
    700: '#7b4124',
  },
  yellow: {
    200: '#fdf8e6',
    300: '#f9edc0',
    400: '#f3da80',
    500: '#e6b400',
    600: '#b89000',
    700: '#735a00',
  },
  // turquoise: {
  //   200: '#f6fafa',
  //   300: '#e7f1f1',
  //   400: '#cee2e2',
  //   500: '#9dc4c4',
  //   600: '#7d9c9c',
  //   700: '#4e6262',
  // },
  green: {
    200: '#e9f8f3',
    300: '#c6eddf',
    400: '#8ddbbf',
    500: '#1ab77f',
    600: '#149265',
    700: '#0d5b3f',
  },
  blue: {
    200: '#ecf4ff',
    300: '#cee4fd',
    400: '#9dc8fb',
    500: '#3a91f7',
    600: '#2e74c5',
    700: '#1d487b',
  },
  purple: {
    200: '#f7f2fd',
    300: '#e9dff8',
    400: '#d3bef1',
    500: '#a77de2',
    600: '#8564b4',
    700: '#533e71',
  },
  pink: {
    200: '#fdeff2',
    300: '#f9d6df',
    400: '#f2adbe',
    500: '#e55a7c',
    600: '#b74863',
    700: '#722d3e',
  },
};
