import isString from 'lodash/isString';
import { ProjectTagAssociationsApi } from './projectTagAssociationApi';

export const deleteProjectTagAssociation = async (
  organizationId: string,
  projectId: string,
  tagAssociationId: string | number
): Promise<void> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }

  try {
    await ProjectTagAssociationsApi.remove(organizationId, projectId, tagAssociationId);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
