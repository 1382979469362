import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { nanoid } from 'nanoid';
import { useEntities } from '../../../state/hooks/useEntities';
import { entityNames } from '../../../state/constants/entityNames';
import { getStaticState } from '../../../state/helpers/getStaticState';
import { allowedStatuses } from '../../../state/constants/allowedStatuses';
import { useStatuses } from '../../../state/hooks/useStatuses';
import { Template } from '../Template';
import { SidebarLayout } from '../Sidebar';
import { colors } from '../../../theme';
import {
  CreateJourneyMapImports,
  CreateJourneyMapTemplates,
} from '../../molecules/CreateJourneyMap';
import { CreateJourneyMapExamples } from '../../molecules/CreateJourneyMap/CreateJourneyMapExamples';
import { CreateJourneyMapSkeleton } from './CreateJourneyMapSkeleton';
import { useScrollToElement } from '../../../utils/hooks/useScrollToElement';
import { LinearProgress } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';

export type CreateJourneyMapProps = {
  showFlashMessage?: () => void;
};

export const CreateJourneyMap = ({ showFlashMessage }: CreateJourneyMapProps) => {
  const { getWallsync, getAdditionalFeatures } = getStaticState();
  const { isLoading } = useEntities(entityNames.WALLSYNC, entityNames.ADDITIONAL_FEATURES);

  const navItems = {
    templates: { name: 'Templates', ...useScrollToElement() },
    imports: { name: 'Imports', ...useScrollToElement() },
    examples: { name: 'Examples', ...useScrollToElement() },
  };

  const status = useStatuses(entityNames.JOURNEY_MAP);

  useEffect(() => {
    getWallsync();
    getAdditionalFeatures();
  }, [getWallsync, getAdditionalFeatures]);

  const theme: Theme = useTheme();

  return (
    <>
      {status === allowedStatuses.PERSISTING && (
        <Box sx={{ position: 'fixed', zIndex: theme.zIndex.modal, width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Template skeleton={<CreateJourneyMapSkeleton />} isLoading={isLoading}>
        <SidebarLayout
          drawerContent={
            <Box sx={{ py: 8 }}>
              <List
                subheader={
                  <Typography sx={{ display: 'block', mx: 6, mb: 6 }} variant="boldM">
                    New journey map
                  </Typography>
                }
              >
                {Object.values(navItems).map(({ name, scrollToElement }) => (
                  <ListItemButton
                    sx={{
                      px: 6,
                      '&:hover': {
                        backgroundColor: colors.blue[200],
                      },
                    }}
                    component="a"
                    onClick={scrollToElement}
                    key={nanoid()}
                  >
                    <ListItemText primaryTypographyProps={{ variant: 'regularS' }} primary={name} />
                  </ListItemButton>
                ))}
              </List>
            </Box>
          }
          mainContent={
            <Box sx={{ pl: 3, py: 8 }}>
              <CreateJourneyMapTemplates ref={navItems.templates.ref} />
              <Divider sx={{ borderColor: colors.smoke, margin: '36px 0' }} />
              <CreateJourneyMapImports ref={navItems.imports.ref} />
              <Divider sx={{ borderColor: colors.smoke, margin: '36px 0' }} />
              <CreateJourneyMapExamples
                ref={navItems.examples.ref}
                showFlashMessage={showFlashMessage}
              />
            </Box>
          }
        />
      </Template>
    </>
  );
};
