import React from 'react';
import { nanoid } from 'nanoid';
import { useTheme, Theme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { colors } from '../../../theme';
import { Skeleton } from '../../atoms/Skeleton/Skeleton';

export const JiraIssuesListSkeleton = () => {
  const theme: Theme = useTheme();

  return (
    <List sx={{ width: '100%', padding: '0px' }}>
      {[1, 2, 3, 4, 5].map(() => (
        <ListItem
          key={nanoid()}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            padding: 0,
            minHeight: theme.spacing(14),
            maxHeight: theme.spacing(14),
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            sx={{
              bgcolor: colors.silver,
              borderRadius: 1,
              minHeight: theme.spacing(14),
              maxHeight: theme.spacing(14),
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};
