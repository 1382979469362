import React from 'react';
import { allowedStatuses } from '../../../../state/constants/allowedStatuses';
import { IconText } from '../../Icon/IconText';
import { CircularProgress } from '../../CircularProgress/CircularProgress';
import { InputProps } from '../Input/InputProps';
import { colors } from '../../../../theme';

export const InputStateLabel = ({ status, errorMessage }: Partial<InputProps>) => {
  const isLoading = status === allowedStatuses.LOADING || status === allowedStatuses.PERSISTING;
  const isSaved = status === allowedStatuses.PERSISTED;
  const isError = status === allowedStatuses.ERROR;
  if (isLoading || isSaved || isError) {
    return (
      <>
        {isLoading && <CircularProgress size={16} />}
        {isSaved && (
          <IconText
            color={colors.green[500]}
            fontVariant="boldXXS"
            iconName="check-circle"
            text="SAVED"
          />
        )}
        {isError && (
          <IconText
            color={colors.red[500]}
            fontVariant="boldXXS"
            iconName="exclamation-circle"
            text={errorMessage || 'Not saved'}
          />
        )}
      </>
    );
  }
  return null;
};
