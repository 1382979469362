import { produce } from 'immer';
import { GetState, SetState } from 'zustand/vanilla';
import { entityNames } from '../../constants/entityNames';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { errorsMethods } from '../errors/errorsSelectors';
import { statusesMethods } from '../statuses/statusesSelector';
import { AppStateInterface } from '../../config/storeTypes';
import { HTTPError } from 'ky';
import { JourneyMap, JourneyMapExample } from '../../../types/journeyMap';
import { journeyMapTemplates } from '../../../constants/journeyMapTemplates';
import { journeyMapExamples } from '../../../constants/journeyMapExamples';
import { getJourneyMapExamples } from '../../../api/journey-map/getExamples';
import { postJourneyMapDuplicateExample } from '../../../api/journey-map/postJourneyMapDuplicateExample';
import { routes } from '../../../constants/routes';
import { createJourneyMap } from '../../../api/journey-map/postJourneyMap';
import { postJourneyMapDuplicate } from '../../../api/journey-map/postJourneyMapDuplicate';
import { updateJourneyMap } from '../../../api/journey-maps/updateJourneyMap';
import { environment, environments } from '../../../utils/environment';

export const journeyMapSlice = (
  set: SetState<AppStateInterface>,
  get: GetState<AppStateInterface>
) => ({
  getJourneyMapTemplates: () => {
    return journeyMapTemplates;
  },
  getJourneyMapExamples: async () => {
    const entityName = entityNames.JOURNEY_MAP_EXAMPLES;
    const {
      entities: { organization, selectedProject },
    } = get();

    const { setEntityStatus } = statusesMethods(get());

    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const result: JourneyMapExample[] =
        environment === environments.STORYBOOK
          ? journeyMapExamples
          : await getJourneyMapExamples(organization.slug, selectedProject.slug);

      set((state: AppStateInterface) =>
        produce(state, (draft) => {
          /* eslint-disable no-param-reassign */
          draft.entities.journeyMapExamples = result;
        })
      );

      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  createJourneyMapFromExample: async (exampleId: string) => {
    const entityName = entityNames.JOURNEY_MAP;

    const { setEntityStatus } = statusesMethods(get());
    const { addError } = errorsMethods(get());
    const {
      entities: { organization, selectedProject },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.PERSISTING);
      const journeyMap: JourneyMap = await postJourneyMapDuplicateExample(
        organization.slug,
        selectedProject.slug,
        exampleId,
        { json: {} }
      );
      setEntityStatus(entityName, allowedStatuses.IDLE);

      window.Smaply.redirectTo(
        routes.journeyMap(organization.slug, selectedProject.slug, journeyMap.slug)
      );
    } catch (error) {
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  createJourneyMap: async () => {
    const entityName = entityNames.JOURNEY_MAP;

    const { setEntityStatus } = statusesMethods(get());
    const { addError } = errorsMethods(get());
    const {
      entities: { organization, selectedProject },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.PERSISTING);
      const journeyMap: JourneyMap = await createJourneyMap(
        organization.slug,
        selectedProject.slug,
        { json: {} }
      );
      setEntityStatus(entityName, allowedStatuses.IDLE);

      window.Smaply.redirectTo(
        routes.journeyMap(organization.slug, selectedProject.slug, journeyMap.slug)
      );
    } catch (error) {
      addError(error, entityName);
      if (typeof window.showErrorFlashMessage === 'function') window.showErrorFlashMessage(error);
      setEntityStatus(entityName, allowedStatuses.IDLE);
    }
  },
  createJourneyMapFromTemplate: async (templateId: string) => {
    const entityName = entityNames.JOURNEY_MAP;

    const { setEntityStatus } = statusesMethods(get());
    const { addError } = errorsMethods(get());
    const {
      entities: { organization, selectedProject },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.PERSISTING);
      const journeyMap: JourneyMap = await postJourneyMapDuplicate(
        organization.slug,
        selectedProject.slug,
        templateId,
        { json: {} }
      );
      setEntityStatus(entityName, allowedStatuses.IDLE);

      window.Smaply.redirectTo(
        routes.journeyMap(organization.slug, selectedProject.slug, journeyMap.slug)
      );
    } catch (error) {
      addError(error, entityName);
      if (typeof window.showErrorFlashMessage === 'function') window.showErrorFlashMessage(error);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  setJourneyMap: (journeyMap: JourneyMap) => {
    set((state: AppStateInterface) =>
      produce(state, (draft) => {
        /* eslint-disable no-param-reassign */
        draft.entities.journeyMap = journeyMap;
      })
    );
  },
  setJourneyMaps: (journeyMaps: JourneyMap[]) => {
    set((state: AppStateInterface) =>
      produce(state, (draft) => {
        /* eslint-disable no-param-reassign */
        draft.entities.journeyMaps = journeyMaps;
      })
    );
  },
  updateJourneyMap: async (
    projectId: string,
    journeyMapId: string,
    journeyMap: Partial<JourneyMap>
  ) => {
    const entityName = entityNames.JOURNEY_MAP;
    const {
      setEntityStatus,
      setJourneyMap,
      addError,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityName, allowedStatuses.PERSISTING);
      const updatedJourneyMap = await updateJourneyMap(
        organization.slug,
        projectId,
        journeyMapId,
        journeyMap
      );
      setJourneyMap(updatedJourneyMap);
      setEntityStatus(entityName, allowedStatuses.PERSISTED);
    } catch (error) {
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
});
