import { StatusesType } from '../config/storeTypes';
import { allowedStatuses } from '../constants/allowedStatuses';

export const combineStatuses = (statuses: Partial<StatusesType>) => {
  const asArray = Object.values(statuses);
  if (asArray.includes(allowedStatuses.LOADING)) {
    return allowedStatuses.LOADING;
  }
  if (asArray.includes(allowedStatuses.PERSISTING)) {
    return allowedStatuses.PERSISTING;
  }
  if (asArray.includes(allowedStatuses.ERROR)) {
    return allowedStatuses.ERROR;
  }
  if (asArray.includes(allowedStatuses.PERSISTED)) {
    return allowedStatuses.PERSISTED;
  }
  return allowedStatuses.IDLE;
};
