import isString from 'lodash/isString';
import { TagAssociation } from '../../types/tags';
import { ProjectTagAssociationsApi } from './projectTagAssociationApi';

export const getProjectTagAssociations = async (
  organizationId: string,
  projectId: string,
  abortSignal: AbortSignal
): Promise<TagAssociation[]> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: TagAssociation[] = await ProjectTagAssociationsApi.get(
      organizationId,
      projectId,
      {
        signal: abortSignal,
      }
    ).json();

    return result || [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};
