import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Tag } from '../../../types/tags';
import { Icon } from '../../atoms/Icon/Icon';
import { TagInputProps } from './ProjectTags/ProjectTagsInput';
import { IconButton } from '../../atoms/Icon/IconButton';

export interface TagsOptionProps {
  option: TagInputProps<Tag>;
  onClick?: (event: any) => void;
  handleEditClick: (event: React.FormEvent) => void;
  handleDeleteClick: (event: React.FormEvent) => void;
}

export const TagsOption = ({
  option,
  handleEditClick,
  handleDeleteClick,
  onClick,
  ...otherProps
}: TagsOptionProps) => {
  const handleClick = (event: any) => {
    const targetTagName = event.target.tagName.toLowerCase();
    if (targetTagName === 'svg' || targetTagName === 'path') {
      event.stopPropagation();
      return;
    }
    onClick(event);
  };
  return (
    <>
      <li {...otherProps} onClick={handleClick}>
        <Box
          sx={{
            overflowX: 'hidden',
            wordBreak: 'break-all',
          }}
          width="100%"
        >
          <Typography variant="regularXXS">
            {option.newTag ? option.newTagLabel : option.name}
          </Typography>
        </Box>
        {!option.newTag && (
          <div className="MuiIconContainer">
            <IconButton onClick={handleEditClick} size="small" aria-label="edit">
              <Icon iconName="edit" fontSize="inherit" />
            </IconButton>
            <IconButton onClick={handleDeleteClick} size="small" edge="end" aria-label="delete">
              <Icon iconName="trash" fontSize="inherit" />
            </IconButton>
          </div>
        )}
      </li>
    </>
  );
};
