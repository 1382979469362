import { getStatusKey } from './helpers';
import { AppStateInterface } from '../../config/storeTypes';
import { EntityNames } from '../../../types/state';

export const statusesMethods = (state: any) => ({
  setEntityStatus: state.setEntityStatus,
});

export const statusByEntity = (
  state: AppStateInterface,
  entityName: EntityNames,
  entityId?: string | number
) => {
  const key = getStatusKey(entityName, entityId);
  // @ts-ignore
  return state.entities.statuses[key];
};
