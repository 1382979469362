import { Options } from 'ky';
import { OrganizationId } from '../../types/organization';
import { get } from '../config';

export const PlansApi = {
  getCurrentPlan: (organizationId: OrganizationId, options: Options) =>
    get(`web/v1/organizations/${organizationId}/plan`, options),
  getPlans: (organizationId: OrganizationId, options: Options) =>
    get(`web/v1/organizations/${organizationId}/plans`, options),
  getBillingInformation: (organizationId: OrganizationId, options: Options) =>
    get(`web/v1/organizations/${organizationId}/billing_information`, options),
  getPlanValidation: (organizationId: OrganizationId, options: Options) =>
    get(`web/v1/organizations/${organizationId}/validate_new_plan`, options),
  getSubscription: (organizationId: OrganizationId, options: Options) =>
    get(`web/v1/organizations/${organizationId}/subscription`, options),
};
