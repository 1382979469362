import isString from 'lodash/isString';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { subscription } from '../../mocks/subscription';
import { Subscription } from '../../types/pricing';
import { PlansApi } from './plansApi';

export const getSubscription = async (
  organizationId: string,
  abortSignal?: AbortSignal
): Promise<Subscription> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (shouldMockSmaplyAPI) {
    return subscription;
  }

  try {
    const result: Subscription = await PlansApi.getSubscription(organizationId, {
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
