import { GetState, SetState } from 'zustand/vanilla';
import { entityNames } from '../../constants/entityNames';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { tagAssociationEntities } from '../../constants/tagAssociationEntities';
import { entityMethods } from '../entitySelectors';
import { statusesMethods } from '../statuses/statusesSelector';
import { AppStateInterface } from '../../config/storeTypes';
import { getTags } from '../../../api/tags/getTags';
import { tagFactory, tagsFactory } from '../../../business-logic/tagsFactory';
import { errorsMethods } from '../errors/errorsSelectors';
import { Tag } from '../../../types/tags';
import { postTag } from '../../../api/tags/postTag';
import { updateTag } from '../../../api/tags/updateTag';
import { deleteTag } from '../../../api/tags/deleteTag';

export const tagsSlice = (set: SetState<AppStateInterface>, get: GetState<AppStateInterface>) => ({
  getTags: async (abortSignal?: AbortSignal) => {
    const entityName = entityNames.TAGS;
    const {
      setDenormalizedData,
      entities: { organization },
    } = get();
    const { setEntityStatus } = statusesMethods(get());

    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const tags: Tag[] = await getTags(organization.slug, abortSignal);
      setDenormalizedData(tagsFactory(tags), entityName);
      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  createTag: async (
    tagName: string,
    associationEntity: keyof typeof tagAssociationEntities,
    projectId?: string,
    journeyMapId?: string
  ) => {
    const entityName = entityNames.TAGS;
    const { setEntityStatus } = statusesMethods(get());
    const {
      setDenormalizedData,
      entities: { organization },
    } = get();
    setEntityStatus(entityName, allowedStatuses.PERSISTING);
    try {
      const tag: Tag = await postTag(organization.slug, tagName);
      setDenormalizedData(tagFactory(tag), entityName);
      setEntityStatus(entityName, allowedStatuses.PERSISTED);

      if (associationEntity === tagAssociationEntities.journeyMap) {
        const { createJourneyMapTagAssociation } = get();
        await createJourneyMapTagAssociation(projectId, journeyMapId, String(tag.id));
      }

      if (associationEntity === tagAssociationEntities.selectedProject) {
        const { createProjectTagAssociation } = get();
        await createProjectTagAssociation(projectId, tag.id);
      }
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  updateTag: async (tag: Partial<Tag>) => {
    const entityName = entityNames.TAGS;

    const { setEntityStatus } = statusesMethods(get());
    const {
      setDenormalizedData,
      entities: { organization },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.PERSISTING, tag.id);
      setEntityStatus(entityName, allowedStatuses.PERSISTING);
      const updatedTag: Tag = await updateTag(organization.slug, tag);
      setDenormalizedData(tagFactory(updatedTag), entityName);
      setEntityStatus(entityName, allowedStatuses.PERSISTED, tag.id);
      setEntityStatus(entityName, allowedStatuses.PERSISTED);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR, tag.id);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  deleteTag: async (tagId: string | number) => {
    const entityName = entityNames.TAGS;
    const { setEntityStatus } = statusesMethods(get());
    const {
      entities: { organization },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      setEntityStatus(entityName, allowedStatuses.LOADING, tagId);
      await deleteTag(organization.slug, tagId);
      const { removeEntity } = entityMethods(get());
      removeEntity(entityName, tagId);
      setEntityStatus(entityName, allowedStatuses.IDLE);
      setEntityStatus(entityName, allowedStatuses.IDLE, tagId);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
      setEntityStatus(entityName, allowedStatuses.ERROR, tagId);
    }
  },
});
