import React, { useState, forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import { additionalFeatures as additionalFeaturesConstants } from '../../../constants/additionalFeatures';
import { useEntities } from '../../../state/hooks/useEntities';
import { entityNames } from '../../../state/constants/entityNames';
import { getStaticState } from '../../../state/helpers/getStaticState';
import { MediaCard } from '../Card/MediaCard';
import { Button } from '../../atoms/Button/Button';
import { nanoid } from 'nanoid';
import { HelpDeskLink } from '../HelpDesk/HelpDeskLink';
import Box from '@mui/material/Box';
import { ImportDialog } from './ImportDialog';
import { headerHeight } from '../../../constants/variables';
import { AdditionalFeatureType } from '../../../types/subscription';
import { BlockedFeatureAlert } from '../BlockedFeatures/BlockedFeatureAlert/BlockedFeatureAlert';
import { BlockedFeatureTooltip } from '../BlockedFeatures/BlockedFeatureTooltip/BlockedFeatureTooltip';

// eslint-disable-next-line react/display-name
export const CreateJourneyMapImports = forwardRef((props, ref) => {
  const {
    entities: { additionalFeatures },
  } = useEntities(entityNames.ADDITIONAL_FEATURES);

  const { getWallsyncIntegrations } = getStaticState();
  const integrations = getWallsyncIntegrations();

  const [importDialogOpen, toggleImportDialog] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const handleClose = () => {
    toggleImportDialog(false);
  };
  const onIntegrationClick = (integration: string) => {
    setSelectedIntegration(integration);
    toggleImportDialog(true);
  };

  const isFeatureAvailable = (featureId: AdditionalFeatureType) => {
    return additionalFeatures.includes(featureId);
  };

  return (
    <Box sx={{ scrollMarginTop: headerHeight }} {...props} ref={ref}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="regularXXL" id="imports">
          Import
        </Typography>
        <HelpDeskLink />
      </Box>
      {additionalFeatures.includes(additionalFeaturesConstants.capture_miro.id) ? null : (
        <Box sx={{ mt: 4 }}>
          <BlockedFeatureAlert>
            Upgrade your plan to import your journey map from other services.
          </BlockedFeatureAlert>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
        {integrations.map(({ title, name, description, image, featureId }) => (
          <MediaCard
            key={nanoid()}
            title={title}
            disabled={!isFeatureAvailable(featureId)}
            description={description}
            onClick={() => {
              onIntegrationClick(name);
            }}
            image={image}
            actions={
              <BlockedFeatureTooltip
                sx={{ maxWidth: 325 }}
                disableHoverListener={isFeatureAvailable(featureId)}
              >
                <Button
                  sx={{ m: 2 }}
                  disabled={!isFeatureAvailable(featureId)}
                  variant="text"
                  size="small"
                >
                  Import
                </Button>
              </BlockedFeatureTooltip>
            }
          />
        ))}
      </Box>
      <ImportDialog
        open={importDialogOpen}
        integration={selectedIntegration}
        handleClose={handleClose}
      />
    </Box>
  );
});
