import React from 'react';

export type ErrorState = {
  hasError: boolean;
};

export type GeneralErrorBoundaryProps = {
  errorComponent: React.ReactElement;
  children: React.ReactElement;
};

export class GeneralErrorBoundary extends React.Component<GeneralErrorBoundaryProps, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { errorComponent, children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return errorComponent;
    }

    return children;
  }
}
