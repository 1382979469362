import React from 'react';
import Box from '@mui/material/Box';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import Typography from '@mui/material/Typography';
import { Link } from '../../../atoms/Link/Link';
import { Tooltip, TooltipProps } from '../../../atoms/Tooltip/Tooltip';
import { colors } from '../../../../theme';

export const BlockedFeatureTooltip = ({
  children,
  title = 'Not available in your plan',
  ...rest
}: Partial<TooltipProps>) => {
  const {
    entities: { organization },
  } = useEntities(entityNames.ORGANIZATION);

  return (
    <Tooltip
      arrow
      {...rest}
      title={
        <Box display="flex" alignItems="center">
          <Typography variant="boldXXS" sx={{ textTransform: 'uppercase' }} color={colors.white}>
            {title}
          </Typography>
          <Link href={`/#${organization.slug}/plan`}>
            <Typography
              variant="boldXXS"
              sx={{ textTransform: 'uppercase', ml: 3 }}
              color={colors.blue[500]}
            >
              Upgrade Plan
            </Typography>
          </Link>
        </Box>
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
};
