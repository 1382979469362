import React from 'react';
import { Typography } from '@mui/material';
import { Tag } from '../../../types/tags';
import { Dialog } from '../../atoms/Dialog/Dialog';
import { DialogTitle } from '../../atoms/Dialog/DialogTitle';
import { DialogContent } from '../../atoms/Dialog/DialogContent';
import { DialogActions } from '../../atoms/Dialog/DialogActions';
import { Icon } from '../../atoms/Icon/Icon';
import { Button } from '../../atoms/Button/Button';

export interface TagsDeleteDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (event: React.FormEvent) => void;
  tag: Tag;
}

export const TagsDeleteDialog = ({
  open,
  handleClose,
  handleSubmit,
  tag,
}: TagsDeleteDialogProps) => {
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmit(event);
  };
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={handleClose}>Delete Tag</DialogTitle>
        <DialogContent>
          <Typography variant="regularXS">
            {`
            You are going to delete the tag “${
              tag ? tag.name : ''
            }”. This will remove the tag from all the projects and journey maps.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="danger" endIcon={<Icon iconName="trash" />}>
            Delete tag
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
