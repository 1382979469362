const unixTimeStampToDate = (unixTimeStamp: number) => new Date(unixTimeStamp * 1000);

const stringToDate = (date: string) => new Date(date);

const formatDate = (date: Date) => {
  return `${date.getDate()} ${date.toLocaleDateString('default', {
    month: 'long',
  })} ${date.getFullYear()}`;
};

const formatTime = (date: Date) => {
  return `${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
};

export const formatUnixDate = (unixTimeStamp: number) => {
  const date = unixTimeStampToDate(unixTimeStamp);
  return formatDate(date);
};

export const formatStringDate = (stringDate: string) => {
  const date = stringToDate(stringDate);
  return `${formatDate(date)} ${formatTime(date)}`;
};

export const formatStringToTime = (stringDate: string) => formatTime(stringToDate(stringDate));
