import React from 'react';
import process from 'process';
import Box from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import { Drawer, DrawerProps } from '../atoms/Drawer/Drawer';
import { colors } from '../../theme/colors/colors';

type SidebarLayoutProps = {
  drawerContent: JSX.Element;
  mainContent: JSX.Element;
  drawerProps?: DrawerProps;
  mainProps?: ContainerProps;
  sidebarWidth?: number;
};

export const SidebarLayout = ({
  drawerContent,
  mainContent,
  drawerProps,
  mainProps,
  sidebarWidth = 220,
}: SidebarLayoutProps) => {
  return (
    <Box sx={{ display: 'flex', backgroundColor: colors.white }}>
      <Drawer
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: sidebarWidth,
            // smaply navbar margin
            top: process.env.STORYBOOK ? 0 : 86,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
        {...drawerProps}
      >
        {drawerContent}
      </Drawer>
      <Container
        component="main"
        maxWidth={false}
        sx={{ flexGrow: 1, m: 'initial' }}
        {...mainProps}
      >
        {mainContent}
      </Container>
    </Box>
  );
};
