import { default as blank } from '../images/journey_map/templates/blank.svg';
import { default as communicationJourney } from '../images/journey_map/templates/communication_journey.svg';
import { default as comparisonJourney } from '../images/journey_map/templates/comparison_journey_map.svg';
import { default as empathyJourney } from '../images/journey_map/templates/empathy_journey_map.svg';
import { default as serviceBlueprint } from '../images/journey_map/templates/service_blueprint.svg';

export const journeyMapTemplates = [
  {
    id: 0,
    title: 'Blank',
    description: 'Create your own journey map from scratch.',
    image: blank,
  },
  {
    id: 1,
    title: 'Service Blueprint',
    description: 'Connect customer interactions with backstage processes in a classic format',
    image: serviceBlueprint,
  },
  {
    id: 2,
    title: 'Communication Journey',
    description:
      'A simple and straightforward template to communicate a visually appealing customer journey.',
    image: communicationJourney,
  },
  {
    id: 3,
    title: 'Empathy Journey Map',
    description:
      'Visualize what customers think, say, do, and feel, as well as their pains and gains throughout their journey.',
    image: empathyJourney,
  },
  {
    id: 4,
    title: 'Comparison Journey Map',
    description:
      'Compare the journeys of different personas in one map (customer segments, customers and employees, etc.).',
    image: comparisonJourney,
  },
];
