import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import { additionalFeatures } from '../../../constants/additionalFeatures';
import { getStaticState } from '../../../state/helpers/getStaticState';
import { allowedStatuses } from '../../../state/constants/allowedStatuses';
import { entityNames } from '../../../state/constants/entityNames';
import { MetadataAssignmentField } from './MetadataFields/MetadataAssignmentField';
import { FeatureFlag } from '../../../utils/feature-flag/FeatureFlag';
import { MetadataField } from './MetadataFields/metadataFields';
import { MetadataDescriptionField } from './MetadataFields/MetadataDescriptionField';
import { MetadataInternalNotesField } from './MetadataFields/MetadataInternalNotesField';
import { MetadataTitleField } from './MetadataFields/MetadataTitleField';
import { AllowedStatuses } from '../../../types/state';
import { JourneyMap } from '../../../types/journeyMap';
import { RichTextInputValue } from '../../atoms/Inputs/RichTextInput/RichTextInputProps';

export type MetadataBarContentProps = {
  projectId: string;
};

export const MetadataBarContent = ({ projectId }: MetadataBarContentProps) => {
  const { updateJourneyMap } = getStaticState();

  const currentFieldRef = useRef<MetadataField>(null);

  const getFieldStatus = (field: MetadataField, status: AllowedStatuses) => {
    return currentFieldRef.current === field ? status : allowedStatuses.IDLE;
  };

  const saveJourneyMap = (field: MetadataField, value: string, journeyMap: JourneyMap) => {
    currentFieldRef.current = field;
    updateJourneyMap(projectId, journeyMap.slug, {
      ...journeyMap,
      [field]: value,
    });
  };

  const onBlurCharCountField = (
    field: MetadataField,
    value: RichTextInputValue,
    isLengthExceeded: boolean,
    journeyMap: JourneyMap
  ) => {
    if (!isLengthExceeded) {
      saveJourneyMap(field, value.html, journeyMap);
    }
  };

  return (
    <Box display="flex">
      <Box minHeight="100%" width={391}>
        <MetadataTitleField getFieldStatus={getFieldStatus} saveJourneyMap={saveJourneyMap} />
      </Box>
      <Box ml={4} width={953}>
        <MetadataDescriptionField
          getFieldStatus={getFieldStatus}
          onBlurCharCountField={onBlurCharCountField}
        />
      </Box>
      <FeatureFlag featureId={additionalFeatures.journey_map_tags.id}>
        <Box ml={4} width={260}>
          <MetadataAssignmentField fieldType={entityNames.TAGS} projectId={projectId} />
        </Box>
      </FeatureFlag>
      <FeatureFlag featureId={additionalFeatures.journey_map_people.id}>
        <Box ml={4} width={260}>
          <MetadataAssignmentField fieldType={entityNames.USERS} projectId={projectId} />
        </Box>
      </FeatureFlag>
      <FeatureFlag featureId={additionalFeatures.journey_map_internal_notes.id}>
        <Box ml={4} width={953}>
          <MetadataInternalNotesField
            getFieldStatus={getFieldStatus}
            onBlurCharCountField={onBlurCharCountField}
          />
        </Box>
      </FeatureFlag>
    </Box>
  );
};
