import { JiraIntegration } from '../../types/jira';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { JiraApi } from './jiraApi';
import { jiraIntegrationNotIntegratedWithJiraUrl } from '../../mocks/jira';
import { delayPromise } from '../../utils/storybook';

export const putJiraIntegration = async (
  organizationSlug: string,
  jiraUrl: string,
  abortSignal: AbortSignal
): Promise<JiraIntegration> => {
  if (!organizationSlug) {
    const error = 'smaply-ui: missing organization slug';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: JiraIntegration = shouldMockSmaplyAPI
      ? await delayPromise(() => {
          return jiraIntegrationNotIntegratedWithJiraUrl;
        })
      : await JiraApi.putJiraIntegration(organizationSlug, {
          json: {
            jira_integration: { jira_url: jiraUrl },
          },
          signal: abortSignal,
        }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
