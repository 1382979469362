import { BillingInformation } from '../types/pricing';

export const billingInformation: BillingInformation = {
  allow_invitations: false,
  billing_frequency: 'month',
  country: 'undefined',
  included_users: null,
  next_billing_date: '2021-11-21',
  plan_price_net: 0,
  plan_price: 0,
  scheduled_for_cancellation: false,
  tax_amount: 0,
  tax_rate: 0,
  taxable: false,
  total_amount: 0,
  user_addon_price_net: 0,
  user_addon_price: 0,
  user_addon_total_price_net: 0,
  user_addons: 0,
};
