import process from 'process';
import { Project } from '../types/project';

export const selectedProject: Project = {
  id: '84142d83-bdc0-46f7-85e0-d45ab4340a8f',
  slug: process.env.SMAPLY_PROJECT_SLUG ? process.env.SMAPLY_PROJECT_SLUG : 'project',
  title: 'project',
  description: 'project',
  color: null,
  organization_id: 1,
  organization_slug: process.env.SMAPLY_ORGANIZATION_SLUG
    ? process.env.SMAPLY_ORGANIZATION_SLUG
    : 'smaply',
  example: false,
  archived: false,
  updated_at: '2021-10-27T08:19:51.000+02:00',
  wallsync: true,
  is_creator: true,
  users: [{ id: 1 }],
  tag_associations: [],
};
