import React from 'react';
import isString from 'lodash/isString';
import { allowedStatuses } from '../../../../state/constants/allowedStatuses';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import { colors } from '../../../../theme';
import { InputStateLabel } from '../InputStateLabel/InputStateLabel';
import { InputProps } from './InputProps';
import { Tooltip } from '../../Tooltip/Tooltip';
import { Icon } from '../../Icon/Icon';

const StyledInput = styled(Box)(({ theme }) => ({
  '> .MuiFormControl-root': {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  '& .MuiFormControl-root': {
    width: '100%',
    '.MuiFilledInput-root:before': {
      border: 'none',
    },
    '.MuiFilledInput-root:hover:before': {
      border: 'none',
    },
    '.MuiFilledInput-root:after': {
      border: 'none',
    },
    '.MuiInputBase-adornedEnd': {
      paddingRight: 'unset',
      '> input': {
        borderRight: `1px solid ${colors.silver}`,
      },
      '.MuiButtonBase-root.MuiIconButton-root': {
        padding: theme.spacing(4),
        width: theme.spacing(10),
        height: theme.spacing(14),
        borderRadius: 'unset',
      },
      '.MuiButtonBase-root:hover': {
        backgroundColor: colors.smoke,
      },
    },
    '.MuiInputAdornment-root': {
      marginRight: '0px',
    },
    'svg.MuiSvgIcon-root': {
      height: '1.5rem',
      width: '1.5rem',
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: colors.white,
    border: `1px solid ${colors.silver}`,
    borderRadius: theme.shape.borderRadius,
    color: colors.carbon,
    overflow: 'hidden',
    fontFamily: theme.typography.fontFamily,
    '&.Mui-error': {
      backgroundColor: theme.palette.error.light,
      borderColor: colors.red[500],
    },
    '&:hover:not(.Mui-error)': {
      backgroundColor: colors.white,
    },
    '&.Mui-focused:not(.Mui-error)': {
      color: colors.blue[600],
      backgroundColor: colors.blue[200],
      borderColor: colors.blue[500],
    },
    '&.Mui-disabled': {
      borderColor: colors.smoke,
      cursor: 'not-allowed',
      background: `${colors.white} linear-gradient(-45deg, transparent 49%, ${colors.smoke} 49%, ${colors.smoke} 51%, transparent 51%) repeat 0 0/6px 6px`,
      '&:hover': {
        borderColor: colors.smoke,
        cursor: 'not-allowed',
      },
    },
  },
  '& .MuiInputBase-input': {
    ...theme.typography.regularS,
    padding: `${theme.spacing(7)} ${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  '& .MuiInputBase-inputHiddenLabel': {
    padding: theme.spacing(2, 3),
  },
  '& .MuiInputBase-multiline': {
    padding: 0,
    minHeight: 'initial',
    lineHeight: 'initial',
  },
  '& .MuiInputLabel-root': {
    ...theme.typography.regularXXS,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: colors.iron,
    backgroundColor: 'initial',
    boxSizing: 'border-box',
    padding: theme.spacing(2, 3, 0, 3),
    width: '100%',
    '> .MuiBox-root > div': {
      textTransform: 'none',
    },
    '&.Mui-focused': {
      color: colors.blue[600],
    },
    '&.Mui-error': {
      color: colors.red[500],
    },
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(0px, 0px)',
  },
}));

export const Input = ({
  label,
  status = allowedStatuses.IDLE,
  fullHeight,
  errorMessage,
  ...otherProps
}: InputProps): JSX.Element => {
  const Label = isString(label) ? (
    <Box display="flex" justifyContent="space-between">
      {label}
      <InputStateLabel status={status} errorMessage={errorMessage} />
    </Box>
  ) : (
    label
  );

  return (
    <StyledInput
      sx={
        fullHeight && {
          height: '100%',
          '& .MuiFormControl-root': { height: '100%' },
          '& .MuiFilledInput-root': { minHeight: '100%', alignItems: 'flex-start' },
        }
      }
      position="relative"
    >
      <TextField
        InputProps={{ disableUnderline: true }}
        fullWidth
        hiddenLabel={!label}
        label={Label}
        InputLabelProps={{ shrink: true }}
        {...otherProps}
        variant="filled"
        error={status === allowedStatuses.ERROR}
      />
    </StyledInput>
  );
};

export const InputWithCopyButton = ({ ...otherProps }: InputProps): JSX.Element => {
  const defaultTooltipTitle = 'Copy text to clipboard';
  const [tooltipTitle, setTooltip] = React.useState(defaultTooltipTitle);

  const handleTooltipSelected = () => {
    setTooltip('Copied!');
  };

  const handleTooltipNoselect = () => {
    setTooltip(defaultTooltipTitle);
  };

  return (
    <Input
      InputProps={{
        endAdornment: (
          <Tooltip title={tooltipTitle} disableFocusListener>
            <IconButton
              color="primary"
              onMouseLeave={handleTooltipNoselect}
              onClick={() => {
                handleTooltipSelected();
                navigator.clipboard.writeText(
                  otherProps.defaultValue ? otherProps.defaultValue : otherProps.value
                );
              }}
            >
              <Icon iconPrefix="fal" iconName="copy" />
            </IconButton>
          </Tooltip>
        ),
      }}
      {...otherProps}
    />
  );
};
