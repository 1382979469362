import { JourneyMapExample } from '../types/journeyMap';

export const journeyMapExamples: JourneyMapExample[] = [
  {
    id: 0,
    title: 'VOLUNTEER ENGAGEMENT',
    description:
      'This mid-level example journey map focuses on Jess and their experience of engaging as a volunteer.',
    image_asset_path: '',
  },
];
