import { Options } from 'ky';
import { JourneyMapUserAssociationType } from '../../types/user';
import { UserAssociationApi } from './userAssociationApi';

export const getJourneyMapUserAssociations = async (
  organizationId: string,
  projectId: string,
  journeyMapId: string,
  params?: Options
) => {
  if (!organizationId) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }
  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }
  if (!journeyMapId) {
    const error = 'smaply-ui: missing journey map id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: JourneyMapUserAssociationType[] = await UserAssociationApi.get(
      organizationId,
      projectId,
      journeyMapId,
      params
    ).json();
    return result;
  } catch (error) {
    console.error(error);
    const serverErrors = await error.json();
    throw serverErrors;
  }
};
