import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import values from 'lodash/values';
import { useEntities } from '../../../state/hooks/useEntities';
import { getStaticState } from '../../../state/helpers/getStaticState';
import { allowedStatuses } from '../../../state/constants/allowedStatuses';
import { entityNames } from '../../../state/constants/entityNames';
import { combineStatuses } from '../../../state/helpers/combineStatuses';
import { sortAlphabetically } from '../../../utils/sorting/sortingHelper';
import { Input } from '../../atoms/Inputs/Input/Input';
import { ChipUser } from '../../atoms/Chip/Chip';
import { colors } from '../../../theme';
import { MultiSelectInput } from '../../atoms/Inputs/MultiSelectInput/MultiSelectInput';
import { User } from '../../../types/user';

export type JourneyMapUsersInputProps = {
  projectId: string;
};

export const JourneyMapUsersInput = ({ projectId }: JourneyMapUsersInputProps) => {
  const {
    getProjectUsers,
    getJourneyMapUserAssociations,
    createJourneyMapUserAssociation,
    deleteJourneyMapUserAssociation,
    removeAllErrors,
    entities: { journeyMap },
  } = getStaticState();
  const {
    entities: { users, journeyMapUserAssociations },
    isLoading,
    statuses,
  } = useEntities(entityNames.USERS, entityNames.JOURNEY_MAP_USER_ASSOCIATIONS);
  useEffect(() => {
    getProjectUsers(projectId);
    getJourneyMapUserAssociations(projectId, journeyMap.slug);
  }, [projectId, journeyMap, getProjectUsers, getJourneyMapUserAssociations]);

  const usersArray = sortAlphabetically(values(users), 'first_name') as User[];
  const usersAssociationArray = values(journeyMapUserAssociations);
  const associatedUserIds = usersAssociationArray.map(({ user_id }) => user_id);
  const selectedUsers = usersArray.filter(({ id }) => associatedUserIds.includes(id));

  const disabled = usersArray.length === 0;

  const cleanErrorState = () => {
    if (statuses.users === allowedStatuses.ERROR) {
      removeAllErrors(entityNames.USERS);
    }
  };

  if (isLoading) {
    return <Input label="people" status={allowedStatuses.LOADING} />;
  }

  return (
    <MultiSelectInput
      onOptionSelect={(option: User) => {
        cleanErrorState();
        createJourneyMapUserAssociation(projectId, journeyMap.slug, String(option.id));
      }}
      onOptionDeSelect={(option: User) => {
        cleanErrorState();
        const userAssociation = usersAssociationArray.find(({ user_id }) => user_id === option.id);
        deleteJourneyMapUserAssociation(projectId, journeyMap.slug, String(userAssociation.id));
      }}
      availableOptions={usersArray}
      selectedOptions={selectedUsers}
      labelKey="full_name"
      idKey="id"
      renderInput={({ InputProps, InputLabelProps, ...params }) => (
        <Input
          status={combineStatuses(statuses)}
          onKeyDown={(event) => {
            if (event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
          {...params}
          InputLabelProps={{ ...InputLabelProps, shrink: true }}
          InputProps={{ ...InputProps, disableUnderline: true, readOnly: true }}
          label="people"
          sx={
            disabled
              ? {
                  '& .MuiInputBase-root': {
                    '&.Mui-disabled': {
                      backgroundColor: colors.smoke,
                      border: `1px solid ${colors.white}`,
                    },
                    '&.Mui-disabled:hover': {
                      backgroundColor: colors.smoke,
                    },
                    '&.Mui-focused.Mui-disabled': {
                      backgroundColor: colors.smoke,
                    },
                    '& .MuiInputBase-input::placeholder': {
                      textTransform: 'capitalize',
                    },
                  },
                }
              : null
          }
          placeholder={disabled ? 'There are no people in your project' : ''}
          disabled={disabled}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Typography variant="regularXXS">{option.full_name}</Typography>
          </li>
        );
      }}
      renderTag={(option, index, onDelete) => (
        <ChipUser
          avatarProps={{ sx: { bgcolor: colors.blue[500] } }}
          style={{ marginRight: 4 }}
          label={option.full_name}
          onDelete={onDelete}
        />
      )}
      onKeyDown={() => {
        cleanErrorState();
      }}
    />
  );
};
