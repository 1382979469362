import isString from 'lodash/isString';
import { currentPlan } from '../../mocks/plans';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { CurrentPlan } from '../../types/pricing';
import { PlansApi } from './plansApi';

export const getCurrentPlan = async (
  organizationId: string,
  abortSignal?: AbortSignal
): Promise<CurrentPlan> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (shouldMockSmaplyAPI) {
    return currentPlan;
  }

  try {
    const result: CurrentPlan = await PlansApi.getCurrentPlan(organizationId, {
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    const serverError = await error.response.json();
    throw serverError;
  }
};
