import { normalize } from 'normalizr';
import { plans } from '../../../mocks/plans';
import { entitySchema } from '../schema';
import { entityNames } from '../../constants/entityNames';
import { store } from '../../config/store';

const initialState = store.getState();
const resetState = () => store.setState(initialState, true);

describe('denormalizedDataSlice', () => {
  afterEach(() => {
    resetState();
  });

  describe('setDenormalizedData', () => {
    it('should populate state using normalizeMiddleware', () => {
      const entityName = entityNames.PLANS;
      const { setDenormalizedData } = store.getState();
      setDenormalizedData(plans, entityName);
      const state = store.getState();

      const normalized = normalize({ [entityName]: plans }, entitySchema);
      const ids = normalized.result[entityName].map((id: string | number) => String(id));

      expect(state.entities[entityName]).toEqual(normalized.entities[entityName]);
      expect(state.entities.result[entityName]).toEqual(ids);
    });
  });
});
