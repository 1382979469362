import { schema } from 'normalizr';
import { entityNames } from '../constants/entityNames';

const error = new schema.Entity('errors');
const tag = new schema.Entity(entityNames.TAGS);
const projectTagAssociation = new schema.Entity(entityNames.PROJECT_TAG_ASSOCIATIONS);
const journeyMapTagAssociation = new schema.Entity(entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS);
const additionalFeatures = new schema.Entity(entityNames.ADDITIONAL_FEATURES);
const plans = new schema.Entity(entityNames.PLANS);
const planValidation = new schema.Entity(entityNames.PLAN_VALIDATION);
const billingInformation = new schema.Entity(entityNames.BILLING_INFORMATION);
const currentPlan = new schema.Entity(entityNames.CURRENT_PLAN);
const subscription = new schema.Entity(entityNames.SUBSCRIPTION);
const organization = new schema.Entity(entityNames.ORGANIZATION);
const selectedProject = new schema.Entity(entityNames.SELECTED_PROJECT);
const wallsync = new schema.Entity(entityNames.WALLSYNC);
const journeyMaps = new schema.Entity(entityNames.JOURNEY_MAPS);
const users = new schema.Entity(entityNames.USERS);
const journeyMapUserAssociation = new schema.Entity(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS);

export const entitySchema = {
  errors: [error],
  [entityNames.TAGS]: [tag],
  [entityNames.PROJECT_TAG_ASSOCIATIONS]: [projectTagAssociation],
  [entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS]: [journeyMapTagAssociation],
  [entityNames.PLANS]: [plans],
  [entityNames.ADDITIONAL_FEATURES]: [additionalFeatures],
  [entityNames.BILLING_INFORMATION]: [billingInformation],
  [entityNames.PLAN_VALIDATION]: [planValidation],
  [entityNames.CURRENT_PLAN]: [currentPlan],
  [entityNames.SUBSCRIPTION]: [subscription],
  [entityNames.ORGANIZATION]: organization,
  [entityNames.SELECTED_PROJECT]: selectedProject,
  [entityNames.WALLSYNC]: wallsync,
  [entityNames.JOURNEY_MAPS]: [journeyMaps],
  [entityNames.USERS]: [users],
  [entityNames.JOURNEY_MAP_USER_ASSOCIATIONS]: [journeyMapUserAssociation],
};
