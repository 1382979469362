import React from 'react';
import { Tag } from '../../../types/tags';
import { Dialog } from '../../atoms/Dialog/Dialog';
import { DialogTitle } from '../../atoms/Dialog/DialogTitle';
import { DialogContent } from '../../atoms/Dialog/DialogContent';
import { DialogActions } from '../../atoms/Dialog/DialogActions';
import { Input } from '../../atoms/Inputs/Input/Input';
import { Button } from '../../atoms/Button/Button';
import { Icon } from '../../atoms/Icon/Icon';

export interface TagsEditDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleInputChange: (inputValue: string) => void;
  tag: Tag | null;
}

export const TagsEditDialog = ({
  open,
  handleClose,
  handleSubmit,
  handleInputChange,
  tag,
}: TagsEditDialogProps) => {
  const onChange = (event: any) => {
    handleInputChange(event.target.value);
  };
  const onSubmit = (event: any) => {
    event.preventDefault();
    handleSubmit(event);
  };
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={handleClose} id="form-dialog-title">
          Edit Tag
        </DialogTitle>
        <DialogContent>
          <Input
            id="edit-tag-input"
            autoFocus
            value={tag ? tag.name : ''}
            onChange={onChange}
            label="Tag name"
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" endIcon={<Icon iconName="arrow-right" />}>
            Save changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
