import { UserAssociationApi } from './userAssociationApi';

export const deleteJourneyMapUserAssociation = async (
  organizationId: string,
  projectId: string,
  journeyMapId: string,
  id: string
) => {
  if (!organizationId) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }

  if (!journeyMapId) {
    const error = 'smaply-ui: missing journey map id';
    console.error(error);
    throw new Error(error);
  }

  if (!id) {
    const error = 'smaply-ui: missing journey map user association id';
    console.error(error);
    throw new Error(error);
  }

  try {
    await UserAssociationApi.remove(organizationId, projectId, journeyMapId, id).json();
  } catch (error) {
    console.error(error);
    const serverErrors = await error.json();
    throw serverErrors;
  }
};
