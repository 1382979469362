import React from 'react';
import Box from '@mui/material/Box';
import { Skeleton } from '../../atoms/Skeleton/Skeleton';

export const PlanTableSkeleton = () => {
  return (
    <Box flex={1} ml={6}>
      <Skeleton variant="rectangular" height={204} />
    </Box>
  );
};
