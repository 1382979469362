import React from 'react';
import Box from '@mui/material/Box';
import { ErrorMessage } from '../molecules/Alerts/GenericAlert';
import { GeneralErrorBoundary } from '../../utils/error-boundaries/GeneralErrorBoundary';

export type TemplateProps = {
  skeleton: React.ReactElement;
  children: React.ReactElement;
  errorComponent?: React.ReactElement;
  isLoading?: boolean;
};

const ErrorComponent = (
  <Box sx={{ p: 3 }}>
    <ErrorMessage />
  </Box>
);

export const Template = ({
  skeleton,
  children,
  isLoading,
  errorComponent = ErrorComponent,
}: TemplateProps) => {
  return isLoading ? (
    skeleton
  ) : (
    <GeneralErrorBoundary errorComponent={errorComponent}>{children}</GeneralErrorBoundary>
  );
};
