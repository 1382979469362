import React from 'react';
import {
  BlockedFeatureBox as SUIBlockedFeatureBox,
  BlockedFeatureBoxProps as SUIBlockedFeatureBoxProps
} from './src/components/molecules/BlockedFeatures/BlockedFeatureBox';
import {
  BlockedFeatureDialog as SUIBlockedFeatureDialog,
  BlockedFeatureDialogProps as SUIBlockedFeatureDialogProps
} from './src/components/molecules/BlockedFeatures/BlockedFeatureDialog';
import { K2Theme } from './src/theme/material/k2';
import { ThemeProvider } from '@mui/material';

export function BlockedFeatureBox(props: SUIBlockedFeatureBoxProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUIBlockedFeatureBox {...props} />
    </ThemeProvider>
  );
}

export function BlockedFeatureDialog(props: SUIBlockedFeatureDialogProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUIBlockedFeatureDialog {...props} />
    </ThemeProvider>
  );
}
