import { GetState, SetState } from 'zustand/vanilla';
import { default as produce } from 'immer';
import { tagsSlice } from './tags/tagsSlice';
import { statusesSlice } from './statuses/statusesSlice';
import { errorsSlice } from './errors/errorsSlice';
import { projectTagAssociationsSlice } from './tagAssociations/projectTagAssociationsSlice';
import { journeyMapTagAssociationsSlice } from './tagAssociations/journeyMapTagAssociationsSlice';
import { denormalizedDataSlice } from './denormalizedData/denormalizedDataSlice';
import { AppStateInterface } from '../config/storeTypes';
import { usersSlice } from './users/usersSlice';
import { journeyMapUserAssociationsSlice } from './userAssociations/journeyMapUserAssociationSlice';
import { jiraSlice } from './jira/jiraSlice';
import { additionalFeaturesSlice } from './additionalFeatures/additionalFeaturesSlice';
import { journeyMapSlice } from './journeyMap/journeyMapSlice';
import { organizationSlice } from './organization/organizationSlice';
import { plansSlice } from './plans/plansSlice';
import { wallsyncSlice } from './wallsync/wallsyncSlice';
import { projectSlice } from './project/projectSlice';

export const entitySlice = (
  set: SetState<AppStateInterface>,
  get: GetState<AppStateInterface>
) => ({
  removeEntity: (entityName: string, entityId: string) => {
    set((state) =>
      produce(state, (draft) => {
        /* eslint-disable no-param-reassign */

        // @ts-ignore
        // delete from main entities object
        delete draft.entities[entityName][entityId];

        // @ts-ignore
        const index = draft.entities.result[entityName].indexOf(entityId);
        // @ts-ignore
        draft.entities.result[entityName].splice(index, 1);
      })
    );
  },
  ...additionalFeaturesSlice(set, get),
  ...denormalizedDataSlice(set),
  ...errorsSlice(set, get),
  ...jiraSlice(set, get),
  ...journeyMapSlice(set, get),
  ...journeyMapTagAssociationsSlice(set, get),
  ...journeyMapUserAssociationsSlice(set, get),
  ...organizationSlice(set),
  ...plansSlice(set, get),
  ...projectSlice(set),
  ...projectTagAssociationsSlice(set, get),
  ...statusesSlice(set),
  ...tagsSlice(set, get),
  ...usersSlice(set, get),
  ...wallsyncSlice(set, get),
});
