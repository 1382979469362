import React from 'react';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import { MetadataField } from './metadataFields';
import { AllowedStatuses } from '../../../../types/state';
import { CharCountRichTextInput } from '../../../atoms/Inputs/RichTextInput/CharCount/CharCountRichTextInput';
import { RichTextInputValue } from '../../../atoms/Inputs/RichTextInput/RichTextInputProps';
import { JourneyMap } from '../../../../types/journeyMap';

export type MetadataDescriptionFieldProps = {
  getFieldStatus: (field: MetadataField, status: AllowedStatuses) => AllowedStatuses;
  onBlurCharCountField: (
    field: MetadataField,
    value: RichTextInputValue,
    isLengthExceeded: boolean,
    journeyMap: JourneyMap
  ) => void;
};

export const MetadataDescriptionField = ({
  getFieldStatus,
  onBlurCharCountField,
}: MetadataDescriptionFieldProps) => {
  const {
    entities: { journeyMap },
    statuses,
  } = useEntities(entityNames.JOURNEY_MAP);
  const { description } = journeyMap;
  return (
    <CharCountRichTextInput
      id="metadata_description"
      initialValue={{ html: description }}
      onBlur={(value, isLengthExceeded) =>
        onBlurCharCountField('description', value, isLengthExceeded, journeyMap)
      }
      status={getFieldStatus('description', statuses.journeyMap)}
      label="description"
      maxLength={500}
      fullHeight
    />
  );
};
