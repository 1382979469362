import React from 'react';
import { IconButton } from '@mui/material';
import { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { colors } from '../../../theme';
import { Icon } from '../Icon/Icon';
import { K2DialogTitle } from './K2Dialog';

export interface DialogTitleProps extends MuiDialogTitleProps {
  onClose?: () => void;
}

export const DialogTitle = ({ children, onClose, ...props }: DialogTitleProps) => {
  return (
    <K2DialogTitle {...props}>
      <Typography component="span" variant="boldXXS" color={colors.iron}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <Icon iconName="times" iconPrefix="fal" />
        </IconButton>
      ) : null}
    </K2DialogTitle>
  );
};
