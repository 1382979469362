import React from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import { useEntities } from '../../../state/hooks/useEntities';
import { entityNames } from '../../../state/constants/entityNames';
import { getStaticState } from '../../../state/helpers/getStaticState';
import { colors } from '../../../theme';
import { Icon } from '../../atoms/Icon/Icon';
import { JiraIssue } from '../../../types/jira';

type JiraIssuesListItemProps = {
  jiraIssue: JiraIssue;
};

export const JiraIssuesListItem = ({ jiraIssue }: JiraIssuesListItemProps) => {
  const { toggleSelectedJiraIssue } = getStaticState();

  const {
    entities: { jiraSelectedIssueKeys },
  } = useEntities(entityNames.JIRA_SELECTED_ISSUE_KEYS);

  const theme: Theme = useTheme();

  const onListItemClick = () => {
    toggleSelectedJiraIssue(jiraIssue.key);
  };

  const selected = jiraSelectedIssueKeys.includes(jiraIssue.key);

  return (
    <ListItem
      onClick={onListItemClick}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        padding: theme.spacing(2),
        minHeight: theme.spacing(14),
        border: `1px solid ${colors.silver}`,
        borderColor: selected ? colors.blue[500] : colors.silver,
        background: selected ? colors.blue[200] : colors.white,
        '&:hover': {
          background: colors.blue[200],
          cursor: 'pointer',
        },
      }}
    >
      <Box sx={{ flex: '1' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: theme.spacing(4),
            marginBottom: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              width: theme.spacing(4),
              height: theme.spacing(4),
              backgroundColor: colors.smoke,
            }}
          >
            {jiraIssue.issue_type_icon_url && (
              <img
                src={jiraIssue.issue_type_icon_url}
                alt={jiraIssue.key}
                width={theme.spacing(4)}
                height={theme.spacing(4)}
              />
            )}
          </Box>
          <Typography variant="boldXXS" color={colors.iron} sx={{ marginLeft: theme.spacing(1) }}>
            {jiraIssue.key}
          </Typography>
        </Box>
        <Box>
          <Typography variant="regularS">{jiraIssue.summary}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: theme.spacing(1),
          display: 'flex',
          alignItems: 'center',
          width: theme.spacing(4),
        }}
      >
        {selected && (
          <Icon
            iconPrefix="fal"
            iconName="check"
            sx={{ width: theme.spacing(4), color: colors.blue[500] }}
          />
        )}
      </Box>
    </ListItem>
  );
};
