import { SetState, GetState } from 'zustand/vanilla';
import { EntityNames } from '../../../types/state';
import { HTTPError } from 'ky';
import { nanoid } from 'nanoid';
import { default as produce } from 'immer';
import { AppStateInterface, ErrorType } from '../../config/storeTypes';
import { allowedStatuses } from '../../constants/allowedStatuses';

export const errorsSlice = (
  set: SetState<AppStateInterface>,
  get: GetState<AppStateInterface>
) => ({
  addError: (error: HTTPError, entityName: EntityNames, entityId?: string | number) => {
    const errorEntity: ErrorType = {
      ...error,
      id: nanoid(),
      entityName,
      entityId: entityId || null,
    };
    set((state) =>
      produce(state, (draft) => {
        draft.entities.errors[entityName].push(errorEntity);
      })
    );
  },
  removeError: (entityName: EntityNames, id: string) => {
    set((state) =>
      produce(state, (draft) => {
        const errors = draft.entities.errors[entityName];
        const index = errors.findIndex((err) => err.id === id);
        if (index !== -1) errors.splice(index, 1);
      })
    );
  },
  removeAllErrors: (entityName: EntityNames) => {
    set((state) =>
      produce(state, (draft) => {
        /* eslint-disable no-param-reassign */
        draft.entities.errors[entityName] = [];
      })
    );
    const { setEntityStatus } = get();
    setEntityStatus(entityName, allowedStatuses.IDLE);
  },
});
