import MuiDialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import { colors } from '../../../theme';

export const K2Dialog = styled(MuiDialog)(({ theme }) => ({
  boxShadow: '0 5px 30px 0 rgb(0 0 0 / 50%)',
  padding: theme.spacing(6),
  '&.MuiModal-root': {
    overflowY: 'auto',
    '& .MuiDialog-container': {
      margin: theme.spacing(10),
      '& .MuiPaper-root': {
        '& .MuiDialogContent-root': {
          overflowY: 'hidden',
        },
      },
      '& .MuiDialog-paper': {
        backgroundColor: 'transparent',
        maxHeight: '80vh',
      },
      '& .MuiPaper-rounded': {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
  '& .MuiPaper-rounded': {
    borderRadius: theme.shape.borderRadius,
  },
  '& .MuiDialogTitle-root + .MuiDialogContent-root': {
    padding: theme.spacing(6),
    wordBreak: 'break-word',
  },
  paperWidthXs: {
    minWidth: '368px',
  },
}));

export const K2DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 0,
  fontWeight: 400,
  backgroundColor: colors.silver,
  textTransform: 'uppercase',
  color: colors.carbon,
  height: theme.spacing(10),
  alignItems: 'center',

  '& .MuiTypography-root': {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(0, 6),
  },

  '& .MuiButtonBase-root': {
    height: theme.spacing(10),
    width: theme.spacing(10),
    fontSize: theme.typography.pxToRem(24),
    backgroundColor: colors.silver,
    color: colors.iron,
    borderLeft: `1px solid ${colors.smoke}`,
    borderRadius: '0px',

    '&:hover': {
      backgroundColor: colors.red[500],
      color: colors.white,
    },

    '&:active': {
      backgroundColor: colors.red[600],
      color: colors.white,
    },
  },
}));

export const K2DialogContent = styled(MuiDialogContent)(() => ({
  backgroundColor: colors.smoke,
  color: colors.carbon,
}));

export const K2DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  backgroundColor: colors.smoke,
  padding: theme.spacing(6),
}));
