import React from 'react';
import Link from '@mui/material/Link';
import Fade from '@mui/material/Fade';
import { useEntities } from '../../../state/hooks/useEntities';
import { entityNames } from '../../../state/constants/entityNames';
import { Typography, Box } from '@mui/material';
import { ButtonBase } from '../../atoms/ButtonBase/ButtonBase';
import { Button } from '../../atoms/Button/Button';
import { colors } from '../../../theme';
import { Plan } from '../../../types/pricing';
import { SALES_EMAIL } from '../../../constants/emailAddresses';
import { Icon } from '../../atoms/Icon/Icon';
import { Card } from '../../atoms/Card/Card';
import { CardContent } from '../../atoms/Card/CardContent';
import { IconText } from '../../atoms/Icon/IconText';

export type SelectPlanCardProps = {
  plan: Partial<Plan>;
  shouldAnimatePrice: boolean;
  handleSelectPlan: (plan: Partial<Plan>) => void;
  handleCancelSubscription?: () => void;
};

const PlanPrice = ({
  plan: { is_enterprise_plan, display_price_with_currency },
  shouldAnimatePrice,
}: Partial<SelectPlanCardProps>) => {
  const displayPrice = is_enterprise_plan ? 'Custom' : display_price_with_currency;
  return shouldAnimatePrice ? (
    <Fade in={shouldAnimatePrice}>
      <Box>
        <Typography mt={2} component="span" variant="boldXXL" color={colors.iron}>
          {displayPrice}
        </Typography>
        <Typography component="div" variant="regularL" color={colors.aluminium}>
          per user
        </Typography>
      </Box>
    </Fade>
  ) : (
    <>
      <Typography mt={2} component="span" variant="boldXXL" color={colors.iron}>
        {displayPrice}
      </Typography>
      <Typography component="div" variant="regularL" color={colors.aluminium}>
        per user
      </Typography>
    </>
  );
};

const SelectPlanButton = ({ plan, handleSelectPlan }: Partial<SelectPlanCardProps>) => {
  const {
    entities: { currentPlan },
  } = useEntities(entityNames.CURRENT_PLAN);

  if (plan.current_plan) {
    return null;
  }

  const ContactUsButton = (
    <Link href={`mailto:${SALES_EMAIL}`}>
      <Button size="small" color="secondary" endIcon={<Icon iconName="envelope" />}>
        Contact Us
      </Button>
    </Link>
  );

  const SelectButton = (
    <Button
      id={plan.chargebee_id}
      onClick={() => handleSelectPlan(plan)}
      size="small"
      color="primary"
    >
      Select Plan
    </Button>
  );

  if (plan.is_enterprise_plan) {
    return ContactUsButton;
  }

  if (plan.is_free_plan && currentPlan.is_yearly_plan) {
    return null;
  }

  return SelectButton;
};

export const SelectPlanCard = ({
  plan,
  shouldAnimatePrice,
  handleSelectPlan,
  handleCancelSubscription,
}: SelectPlanCardProps) => {
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: plan.current_plan ? colors.blue[200] : colors.white,
        px: 2,
        maxWidth: 180,
        minWidth: 160,
        minHeight: 200,
        borderRadius: 0,
      }}
    >
      <CardContent
        sx={{
          textAlign: 'center',
          display: 'flex',
          flex: '0 1 auto',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 0,
          py: 2,
        }}
      >
        <Box>
          <Typography component="p" variant="boldXXL">
            {plan.display_name}
          </Typography>
          <PlanPrice plan={plan} shouldAnimatePrice={shouldAnimatePrice} />
          {plan.is_enterprise_plan && (
            <>
              <Box mt={3} display="flex">
                <IconText
                  iconName="star-of-life"
                  fontVariant="boldXXS"
                  color={colors.red[500]}
                  text="GET A TAILORED OFFER"
                />
              </Box>
              <Typography mt={2} variant="regularXXS" color={colors.iron}>
                Annual only
              </Typography>
            </>
          )}
          {!plan.is_free_plan && !plan.is_enterprise_plan && (
            <Box mt={3}>
              <Typography
                variant="regularXXS"
                display="flex"
                justifyContent="center"
                color={colors.iron}
              >
                {plan.display_billing_message}
              </Typography>
              <Typography
                variant="regularXXS"
                display="flex"
                justifyContent="center"
                color={colors.iron}
              >
                plus VAT if applicable
              </Typography>
            </Box>
          )}
          {plan.current_plan && (
            <Box mt={3}>
              <Box>
                <IconText
                  iconName="check-double"
                  fontVariant="boldXXS"
                  color={colors.green[500]}
                  text="YOUR CURRENT PLAN"
                />
              </Box>
              <Box mt={1}>
                <ButtonBase onClick={handleCancelSubscription}>
                  <Typography component="p" variant="regularXXS" color={colors.iron}>
                    Cancel subscription
                  </Typography>
                </ButtonBase>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          <SelectPlanButton plan={plan} handleSelectPlan={handleSelectPlan} />
        </Box>
      </CardContent>
    </Card>
  );
};
