import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { Icon } from '../../atoms/Icon/Icon';
import { Link } from '../../atoms/Link/Link';

export const HelpDeskLink = () => {
  return (
    <Link
      sx={{ display: 'flex', alignItems: 'center' }}
      underline="none"
      target="_blank"
      href="https://helpdesk.smaply.com/"
    >
      <SvgIcon color="secondary" fontSize="inherit" sx={{ mr: 1 }}>
        <Icon iconName="question-circle" />
      </SvgIcon>
      Learn more
    </Link>
  );
};
