import isString from 'lodash/isString';
import { plans } from '../../mocks/plans';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { Plan } from '../../types/pricing';
import { PlansApi } from './plansApi';

export const getPlans = async (
  organizationId: string,
  abortSignal?: AbortSignal
): Promise<Plan[]> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (shouldMockSmaplyAPI) {
    return plans;
  }

  try {
    const result: Plan[] = await PlansApi.getPlans(organizationId, {
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
