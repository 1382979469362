import { billingFrequencies } from '../constants/billingFrequencies';
import { billingMessagesByPeriod } from '../constants/billingMessagesByPeriod';
import { currencyTranslations } from '../constants/currencyTranslations';
import {
  SmaplyEnterpriseMonthlyEur,
  SmaplyEnterpriseYearlyEur,
  SmaplyEnterpriseMonthlyUsd,
  SmaplyEnterpriseYearlyUsd,
} from '../constants/enterprisePlans';
import { planDisplayNames } from '../constants/planDisplayNames';
import { plansOrder } from '../constants/plansOrder';
import { ChargebeePlanId, CurrentPlan, Plan } from '../types/pricing';
import { Currency } from '../types/subscription';

const canChangePlan = (chargebee_id: ChargebeePlanId) => {
  const results = [
    '-enterprise-',
    '-whitelabel-',
    '-scale-up-',
    '-performance-',
    '-corporate-',
  ].map((part) => chargebee_id.includes(part) === true);

  return results.filter((res) => res === true).length === 0;
};

export const planFactory = (plan: Plan | CurrentPlan) => {
  const displayPrice = (plan.price / 100).toLocaleString(undefined, {
    currency: plan.currency_code,
  });
  return {
    ...plan,
    display_name: planDisplayNames[plan.chargebee_id],
    display_price: displayPrice,
    display_billing_message: billingMessagesByPeriod[plan.period_unit],
    is_enterprise_plan: plan.chargebee_id.includes('smaply-enterprise-'),
    is_free_plan: plan.chargebee_id.includes('smaply-free-'),
    display_price_with_currency: `${displayPrice} ${currencyTranslations[plan.currency_code]}`,
    is_monthly_plan: plan.period_unit === billingFrequencies.month,
    is_yearly_plan: plan.period_unit === billingFrequencies.year,
    can_change_plan: canChangePlan(plan.chargebee_id),
  };
};

export const plansFactory = (plans: Plan[], currency_code: Currency): Plan[] | CurrentPlan[] => {
  const plansByCurrency = [
    ...plans,
    ...[
      SmaplyEnterpriseMonthlyEur,
      SmaplyEnterpriseYearlyEur,
      SmaplyEnterpriseMonthlyUsd,
      SmaplyEnterpriseYearlyUsd,
    ],
  ].filter((plan) => plan.currency_code === currency_code);

  return plansByCurrency.map(planFactory);
};

export const orderPlans = (plans: Plan[]): Plan[] | CurrentPlan[] => {
  const orderedPlans: Plan[] = [];

  plansOrder.map((o) => {
    const plan = plans.find((p) => p.chargebee_id === o);
    if (plan) orderedPlans.push(plan);
  });

  return orderedPlans;
};
