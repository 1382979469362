import MuiPopper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { colors } from '../../../theme';

export const Popper = styled(MuiPopper)(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: colors.blue[200],
  },
  [`& .${autocompleteClasses.paper}`]: {
    margin: theme.spacing(1, 0),
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    overflow: 'initial',
    borderRadius: theme.shape.borderRadius,
  },
  [`& .${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
    display: 'flex',
    padding: theme.spacing(2, 3),
    justifyContent: 'space-between',

    '& *': {
      margin: 0,
      padding: 0,
    },
    '&, & .MuiTypography-body1': {
      fontSize: theme.typography.regularXXS.fontSize,
      color: colors.carbon,
    },
    '& .MuiListItem-root': {
      '&, &:hover': {
        background: 'transparent',
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      right: 4,
    },
    '& .MuiIconButton-root': {
      display: 'none',
      color: colors.iron,
      fontSize: theme.typography.regularM.fontSize,

      '&:not([class$="edgeEnd"])': {
        marginRight: 8,
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',

      '& .MuiIconButton-root': {
        display: 'inline-flex',
      },
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'rgba(235, 235, 235, 0.5)',

      '&, & .MuiTypography-body1': {
        fontWeight: 600, // 600 is not defined in Figma.
        color: colors.onyx,
      },
    },
    '& .MuiIconContainer': {
      display: 'flex',
      marginLeft: 5,
    },
  },
  '& .MuiAutocomplete-loading': {
    backgroundColor: colors.blue[200],
    color: colors.iron,
    fontSize: theme.typography.regularXXS.fontSize,
  },
}));
