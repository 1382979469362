import React, { forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import { MediaCard } from '../Card/MediaCard';
import { Button } from '../../atoms/Button/Button';
import { nanoid } from 'nanoid';
import { useEntities } from '../../../state/hooks/useEntities';
import { entityNames } from '../../../state/constants/entityNames';
import { getStaticState } from '../../../state/helpers/getStaticState';
import { allowedStatuses } from '../../../state/constants/allowedStatuses';
import Box from '@mui/material/Box';
import { headerHeight } from '../../../constants/variables';
import { useOrganizationLimits } from '../../../state/hooks/useOrganizationLimits';
import { BlockedFeatureAlert } from '../BlockedFeatures/BlockedFeatureAlert/BlockedFeatureAlert';
import { useStatuses } from '../../../state/hooks/useStatuses';
import { BlockedFeatureTooltip } from '../BlockedFeatures/BlockedFeatureTooltip/BlockedFeatureTooltip';

// eslint-disable-next-line react/display-name
export const CreateJourneyMapTemplates = forwardRef((props, ref) => {
  const {
    entities: { journeyMaps },
  } = useEntities(entityNames.JOURNEY_MAPS, entityNames.ORGANIZATION);
  const { limitsReached } = useOrganizationLimits({
    journey_maps: journeyMaps.length,
  });

  const { getJourneyMapTemplates, createJourneyMapFromTemplate, createJourneyMap } =
    getStaticState();
  const templates = getJourneyMapTemplates();

  const onTemplateClick = (templateId: number) => {
    if (templateId === 0) {
      createJourneyMap();
    } else {
      createJourneyMapFromTemplate(templateId);
    }
  };

  const status = useStatuses(entityNames.JOURNEY_MAP);

  return (
    <Box sx={{ scrollMarginTop: headerHeight }} {...props} ref={ref}>
      <Typography variant="regularXXL" sx={{ mt: 3 }} id="templates">
        Templates
      </Typography>
      {limitsReached?.journey_maps ? (
        <Box sx={{ mt: 4 }}>
          <BlockedFeatureAlert planChip={false}>
            You have reached the limit of journey maps you can create with your current plan.
          </BlockedFeatureAlert>
        </Box>
      ) : null}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
        {templates.map(({ title, description, image, id }) => (
          <MediaCard
            disabled={limitsReached?.journey_maps}
            key={nanoid()}
            title={title}
            description={description}
            onClick={
              status !== allowedStatuses.PERSISTING
                ? () => {
                    onTemplateClick(id);
                  }
                : null
            }
            image={image}
            actions={
              <BlockedFeatureTooltip
                sx={{ maxWidth: 325 }}
                title="Plan limit reached"
                disableHoverListener={!limitsReached?.journey_maps}
              >
                <Button
                  sx={{ m: '6px' }}
                  variant="text"
                  size="small"
                  disabled={limitsReached?.journey_maps || status === allowedStatuses.PERSISTING}
                >
                  Create
                </Button>
              </BlockedFeatureTooltip>
            }
          />
        ))}
      </Box>
    </Box>
  );
});
