import { Subscription } from '../types/pricing';
import { currentPlan } from './plans';

export const subscription: Subscription = {
  activated_at: 1634767200,
  cancelled_at: null,
  changes_scheduled_at: null,
  chargebee_id: '16BjkqSmSUihVCqy',
  currency_code: currentPlan.currency_code,
  current_term_end: 1637449199,
  customer_id: 'AzZlzCSmSUiXAIYc',
  deleted: false,
  has_scheduled_changes: false,
  id: 83,
  next_billing_at: 1637449200,
  organization_subscription_status: 'active',
  plan_amount: 0,
  plan_id: currentPlan.chargebee_id,
  plan_quantity: 1,
  resource_version: 1634802031519,
  start_date: null,
  started_at: 1634767200,
  status: 'active',
  trial_end: null,
  trial_start: null,
  updated_at: 1634802031,
};
