import { useState } from 'react';

export type UseCancelSubscriptionFlow = {
  smaplyCancelCallback: () => Promise<void>;
  setIsPersisting: (isPersisting: boolean) => void;
};

export const useCancelPlanFlow = ({
  smaplyCancelCallback,
  setIsPersisting,
}: UseCancelSubscriptionFlow) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  async function onCancelPlan() {
    setIsPersisting(true);
    await smaplyCancelCallback();
    setIsPersisting(false);
    setModalVisible(false);
  }

  return {
    openModal: () => setModalVisible(true),
    closeModal: () => setModalVisible(false),
    onCancelPlan,
    modalVisible,
  };
};
