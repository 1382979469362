import { EntityNames } from '../../types/state';
import { useStore } from '../config/store';
import { AppStateInterface } from '../config/storeTypes';
import { statusByEntity } from '../slices/statuses/statusesSelector';

export const useStatuses = (entityName: EntityNames, entityId?: string | number) => {
  const statuses = useStore((state: AppStateInterface) =>
    statusByEntity(state, entityName, entityId)
  );
  return statuses;
};
