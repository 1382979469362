import isString from 'lodash/isString';
import { mockedJiraSearch } from '../../mocks/jira';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { JiraIssue, JiraSearchResults } from '../../types/jira';
import { delayPromise } from '../../utils/storybook';
import { JiraApi } from './jiraApi';

export const searchJiraIssues = async (
  organizationSlug: string,
  query: string,
  abortSignal: AbortSignal
): Promise<JiraIssue[]> => {
  if (!isString(organizationSlug)) {
    const error = `smaply-ui: missing organization slug; ${organizationSlug}`;
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: JiraSearchResults = shouldMockSmaplyAPI
      ? await delayPromise(() => {
          return mockedJiraSearch;
        })
      : await JiraApi.searchJiraIssues(organizationSlug, query, {
          signal: abortSignal,
        }).json();

    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
