import { EntityNames } from '../../../types/state';
import { SetState } from 'zustand/vanilla';
import { DENORMALIZED_DATA_KEY } from '../../config/helpers';
import { AppStateInterface, Entities } from '../../config/storeTypes';

export const denormalizedDataSlice = (set: SetState<AppStateInterface>) => ({
  setDenormalizedData: (data: Entities | Entities[], entityName: EntityNames) => {
    set({ [DENORMALIZED_DATA_KEY]: { data, entityName } });
  },
});
