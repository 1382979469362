import React from 'react';
import { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { AutoComplete } from '../../AutoComplete/AutoComplete';
import { Popper } from '../../Popper/Popper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface MultiSelectInputProps<T extends { [key: string]: any }>
  extends Omit<AutocompleteProps<T, true, true, true, 'div'>, 'options'> {
  labelKey: keyof T;
  idKey: keyof T;
  keepCreatedOptionsState?: boolean;
  availableOptions: T[];
  selectedOptions: T[];
  itemNotFoundTip?: string | ((inputTextValue: string) => string);
  renderTag?: (
    option: T,
    index: number,
    onDelete: (event: React.SyntheticEvent) => void
  ) => React.ReactNode;
  onOptionSelect?: (option: T) => void;
  onOptionDeSelect?: (option: T) => void;
  onOptionCreate?: (option: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MultiSelectInput<T extends { [key: string]: any }>({
  labelKey,
  idKey,
  availableOptions,
  selectedOptions,
  keepCreatedOptionsState = true,
  itemNotFoundTip,
  renderOption,
  renderTag,
  onOptionSelect,
  onOptionDeSelect,
  onOptionCreate,
  ...otherProps
}: MultiSelectInputProps<T>) {
  const sanitizedOptions = availableOptions.map((option) => ({
    ...option,
    [labelKey]: option[labelKey] || '',
  }));

  const sanitizedValue = selectedOptions.map((option) => ({
    ...option,
    [labelKey]: option[labelKey] || '',
  }));

  const handleOptionSelect = (option: any) => {
    const optionIsSelected = selectedOptions.find((opt: T) => opt[idKey] === option[idKey]);
    if (!optionIsSelected) {
      onOptionSelect(option);
    }
  };

  const handleOptionCreate = (option: any) => {
    if (!onOptionCreate) {
      return;
    }

    if (!keepCreatedOptionsState) {
      onOptionCreate(option);
      return;
    }
    onOptionCreate(option);
  };

  const handleOptionDeselect = (option: any) => {
    onOptionDeSelect(option);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option[labelKey] as string,
  });
  return (
    <AutoComplete
      options={sanitizedOptions}
      value={sanitizedValue}
      autoSelect
      multiple
      disableCloseOnSelect
      freeSolo
      autoComplete
      disableClearable
      handleHomeEndKeys
      ChipProps={{ size: 'small' }}
      PopperComponent={Popper}
      className="default visibleLabel"
      // eslint-disable-next-line
      onChange={(event, currentValue: T[], reason, details: any) => {
        // https://github.com/mui-org/material-ui/issues/26643
        if (event.type === 'blur') {
          return;
        }

        if (reason === 'createOption' || details.option.newTag) {
          // create-option is only triggered by enter
          // newTag flag is pushed during filtering stage to distinguish for mouselick.

          // for selecting/deselecting already existing tags on enter
          const availableOption = availableOptions.find(
            (option: T) => details.option === option[labelKey]
          );

          if (availableOption) {
            const selectedOption = currentValue.find(
              (option: T) => option[idKey] === availableOption[idKey]
            );

            // eslint-disable-next-line
            selectedOption
              ? handleOptionDeselect(selectedOption)
              : handleOptionSelect(availableOption);
          } else {
            const tagName =
              typeof details.option === 'string' ? details.option : details.option.name;
            handleOptionCreate(tagName);
          }
        } else if (reason === 'removeOption') {
          handleOptionDeselect(details.option);
        } else if (reason === 'selectOption') {
          handleOptionSelect(details.option);
        }
      }}
      renderOption={(props, option: T, state) => (
        <React.Fragment key={String(option[idKey])}>
          <Typography variant="regularXXS">{renderOption(props, option, state)}</Typography>
        </React.Fragment>
      )}
      renderTags={(currentValue, getTagProps) => {
        return currentValue.map((option: T, index) => {
          const { onDelete } = getTagProps({ index });
          return (
            <React.Fragment key={String(option[idKey])}>
              {renderTag(option, index, onDelete)}
            </React.Fragment>
          );
        });
      }}
      getOptionLabel={(option: T) => {
        if (typeof option === 'string') {
          return option;
        }
        return option[labelKey] || '';
      }}
      filterOptions={(options, state) => {
        const filtered = filterOptions(options, state);
        const exists = filtered.find(
          (option: T) => state.getOptionLabel(option) === state.inputValue
        );

        // push fake option to suggest creation
        if (state.inputValue !== '' && !exists && itemNotFoundTip) {
          const itemText =
            typeof itemNotFoundTip === 'function'
              ? itemNotFoundTip(state.inputValue)
              : itemNotFoundTip;
          filtered.push({
            newTag: true,
            [labelKey]: state.inputValue,
            newTagLabel: itemText,
          });
        }

        return filtered as T[];
      }}
      openOnFocus
      isOptionEqualToValue={(option: T, value: T) => {
        return option[idKey] === value[idKey];
      }}
      {...otherProps}
    />
  );
}
