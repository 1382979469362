import { GetState, SetState } from 'zustand/vanilla';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { entityNames } from '../../constants/entityNames';
import { errorsMethods } from '../errors/errorsSelectors';
import { entityMethods } from '../entitySelectors';
import { statusesMethods } from '../statuses/statusesSelector';
import { AppStateInterface } from '../../config/storeTypes';
import { TagAssociation } from '../../../types/tags';
import { getProjectTagAssociations } from '../../../api/project-tags-association/getProjectTagAssociations';
import { postProjectTagAssociation } from '../../../api/project-tags-association/postProjectTagAssociation';
import { deleteProjectTagAssociation } from '../../../api/project-tags-association/deleteProjectTagAssociation';

export const projectTagAssociationsSlice = (
  set: SetState<AppStateInterface>,
  get: GetState<AppStateInterface>
) => ({
  getProjectTagAssociations: async (projectId: string, abortSignal: AbortSignal) => {
    const entityName = entityNames.PROJECT_TAG_ASSOCIATIONS;

    const { setEntityStatus } = statusesMethods(get());
    const { addError } = errorsMethods(get());
    const {
      setDenormalizedData,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const tagAssociations: TagAssociation[] = await getProjectTagAssociations(
        organization.slug,
        projectId,
        abortSignal
      );
      setDenormalizedData(tagAssociations, entityName);
      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      setEntityStatus(entityName, allowedStatuses.ERROR);
      addError(error, entityName);
    }
  },
  createProjectTagAssociation: async (projectId: string, tagId: number) => {
    const entityName = entityNames.PROJECT_TAG_ASSOCIATIONS;

    const { setEntityStatus } = statusesMethods(get());
    const { addError } = errorsMethods(get());
    const {
      setDenormalizedData,
      entities: { organization },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.PERSISTING);
      const tagAssociation: TagAssociation = await postProjectTagAssociation(
        organization.slug,
        projectId,
        tagId
      );
      setDenormalizedData(tagAssociation, entityName);
      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  deleteProjectTagAssociation: async (projectId: string, tagAssociationId: string) => {
    const entityName = entityNames.PROJECT_TAG_ASSOCIATIONS;

    const { setEntityStatus } = statusesMethods(get());
    const { addError } = errorsMethods(get());
    const { removeEntity } = entityMethods(get());
    const {
      entities: { organization },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.LOADING, tagAssociationId);
      await deleteProjectTagAssociation(organization.slug, projectId, tagAssociationId);
      removeEntity(entityName, tagAssociationId);
      setEntityStatus(entityName, allowedStatuses.IDLE, tagAssociationId);
    } catch (error) {
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR, tagAssociationId);
    }
  },
});
