import React from 'react';
import MuiMenu, { MenuProps as MuiMenuProps, menuClasses } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import { colors } from '../../../theme';

export type MenuProps = {
  mode?: 'light' | 'dark';
} & MuiMenuProps;

const StyledMenu = styled(({ mode = 'light', className, ...rest }: MenuProps) => {
  return <MuiMenu className={`${className || ''} ${menuClasses.root}-${mode}`} {...rest} />;
})(({ theme }) => ({
  '& .MuiMenuItem-root:hover': {
    backgroundColor: colors.blue[200],
    '& .MuiTypography-root': {
      color: colors.blue[500],
    },
    '& .MuiSvgIcon-root': {
      color: colors.blue[500],
    },
  },
  '& .MuiTypography-root, & .MuiSvgIcon-root': {
    color: colors.iron,
  },
  '&.MuiMenu-root-dark': {
    '& .MuiPaper-root': {
      backgroundColor: colors.onyx,
    },
    '& .MuiMenuItem-root:hover, & .MuiMenuItem-root:focus-visible': {
      backgroundColor: colors.iron,

      '& .MuiTypography-root, & .MuiSvgIcon-root': {
        color: colors.white,
      },
    },
    '& .MuiMenuItem-root:active': {
      backgroundColor: colors.smoke,

      '& .MuiTypography-root, & .MuiSvgIcon-root': {
        color: colors.carbon,
      },
    },
    '& .MuiDivider-root.MuiDivider-fullWidth': {
      backgroundColor: colors.white,
    },
    '& .MuiTypography-root, & .MuiSvgIcon-root': {
      color: colors.white,
    },
  },
  '& .MuiDivider-root.MuiDivider-fullWidth': {
    margin: theme.spacing(3, 0),
  },
}));

const StyledMenuWithArrow = styled((props: MenuProps) => {
  return <StyledMenu elevation={0} {...props} />;
})(({ theme, anchorOrigin }) => ({
  '& .MuiPaper-root.MuiMenu-paper': {
    overflow: 'visible',
    marginTop: theme.spacing(4),
    filter: 'drop-shadow(0px 3px 6px rgba(0,0,0,0.4))',
    maxWidth: '100%',
    padding: theme.spacing(3, 0),
    borderRadius: 4,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      ...(anchorOrigin?.horizontal === 'left' && {
        [anchorOrigin.horizontal]: 14,
      }),
      ...(anchorOrigin?.horizontal === 'right' && {
        [anchorOrigin.horizontal]: 14,
      }),
      width: 10,
      height: 10,
      backgroundColor: colors.white,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  '&.MuiMenu-root-dark': {
    '& .MuiPaper-root': {
      '&:before': {
        backgroundColor: colors.onyx,
      },
    },
  },
}));

export const Menu = (props: MenuProps) => {
  return <StyledMenu {...props} />;
};

export const MenuWithArrow = (props: MenuProps) => {
  return <StyledMenuWithArrow {...props} />;
};
