import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import { colors } from '../../../theme';

export const K2Button = styled(MuiButton)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'stretch',
  borderRadius: theme.shape.borderRadius,
  color: colors.white,
  padding: 0,
  textTransform: 'none',
  '&.MuiButton-root': {
    backgroundImage: 'linear-gradient(180deg, transparent 0%, rgba(0,0,0,0.1) 100%)',
    '&.Mui-disabled': {
      color: colors.onyx,
      backgroundColor: colors.silver,
      backgroundImage: 'none',
      opacity: 0.75,
    },
    '&:disabled': {
      color: colors.onyx,
      backgroundColor: colors.silver,
      backgroundImage: 'none',
      opacity: 0.75,
    },
  },
  '&.MuiButton-contained': {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, .3)',
    },
    '&:active': {
      boxShadow: '1px 1px 3px 0 rgba(0, 0, 0, .3)',
    },
  },
  '&.MuiButton-containedPrimary': {
    '&:hover': {
      backgroundColor: colors.blue[600],
    },
    '&:active': {
      backgroundColor: colors.blue[700],
    },
  },
  '&.MuiButton-containedSecondary': {
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.iron,
    },
    '&:active': {
      backgroundColor: colors.carbon,
    },
  },
  '&.MuiButton-sizeSmall': {
    ...theme.typography.regularXS,
    '& .MuiButtonText': {
      padding: theme.spacing(1, 4),
    },
  },
  '&.MuiButton-sizeLarge': {
    ...theme.typography.regularL,
    '& .MuiButtonText': {
      padding: theme.spacing(3, 4),
    },
  },
  '& .MuiButton-iconSizeSmall, & .MuiButton-iconSizeLarge, & .MuiButton-iconSizeMedium': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    margin: 0,
  },
  '& .MuiButton-iconSizeSmall': {
    minWidth: '26px', // Not a multiple of 4
    '& > *:first-of-type': {
      fontSize: theme.typography.pxToRem(10),
    },
  },
  '& .MuiButton-iconSizeMedium': {
    minWidth: theme.spacing(9),
    '& > *:first-of-type': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  '& .MuiButton-iconSizeLarge': {
    minWidth: theme.spacing(24),
    '& > *:first-of-type': {
      fontSize: theme.typography.regularXL.fontSize,
    },
  },
  '& .MuiButtonText': {
    padding: theme.spacing(2, 4),
    minWidth: theme.spacing(8),
    minHeight: 18, // Not a multiple of 4
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&.MuiButton-textPrimary': {
    color: colors.aluminium,
    textTransform: 'uppercase',
    fontWeight: 600,
    backgroundImage: 'none',

    '& .MuiButtonText': {
      fontSize: theme.typography.pxToRem(11),
      padding: 9,
    },

    '&:hover': {
      color: colors.white,
      backgroundColor: colors.blue[500],
    },
  },
}));
