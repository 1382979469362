import React from 'react';
import { Typography } from '@mui/material';
import { colors } from '../../../theme';
import { alpha, useTheme } from '@mui/material/styles';
import { Card, CardMedia, CardContent, CardActions } from '../../atoms/Card/Card.styled';
import { StripedOverlay } from '../Overlay/StripedOverlay';

export type MediaCardProps = {
  title?: string;
  description?: string;
  image: string;
  actions?: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
};

export const MediaCard = ({
  title,
  description,
  image,
  actions,
  disabled,
  onClick,
}: MediaCardProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        cursor: 'pointer',
        '&:hover': {
          boxShadow: theme.shadows[4],
        },
        minWidth: 260,
        maxWidth: 260,
        mt: 6,
        mr: 4,
      }}
      onClick={disabled ? null : onClick}
    >
      <CardMedia sx={{ position: 'relative', height: 150 }} image={image}>
        {disabled ? <StripedOverlay sx={{ backgroundColor: alpha(colors.black, 0.3) }} /> : null}
      </CardMedia>
      {(title || description) && (
        <CardContent
          sx={{
            backgroundColor: disabled ? colors.smoke : 'initial',
          }}
        >
          {title && (
            <Typography
              sx={{ display: 'block', textTransform: 'uppercase' }}
              color="text.secondary"
              gutterBottom
              variant="boldXXS"
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography color="text.secondary" variant="regularXXS">
              {description}
            </Typography>
          )}
        </CardContent>
      )}
      <CardActions
        sx={{
          backgroundColor: disabled ? colors.smoke : 'initial',
        }}
      >
        {actions}
      </CardActions>
    </Card>
  );
};
