import { SetState } from 'zustand';
import { AllowedStatuses, EntityNames } from '../../../types/state';
import { default as produce } from 'immer';
import { getStatusKey } from './helpers';
import { AppStateInterface } from '../../config/storeTypes';

export const statusesSlice = (set: SetState<AppStateInterface>) => ({
  setEntityStatus: (
    entityName: EntityNames,
    status: AllowedStatuses,
    entityId?: string | number
  ) => {
    const key = getStatusKey(entityName, entityId);
    set((state) =>
      produce(state, (draft) => {
        /* eslint-disable no-param-reassign */
        // @ts-ignore
        draft.entities.statuses[key] = status;
      })
    );
  },
});
