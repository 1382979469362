import React from 'react';
import MUiInputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { colors } from '../../../../theme';

export type { InputLabelProps };

const StyledInputLabel = styled(MUiInputLabel)(({ theme }) => ({
  '&': {
    ...theme.typography.regularXXS,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: colors.iron,
    backgroundColor: 'initial',
    boxSizing: 'border-box',
    padding: theme.spacing(2, 3, 0, 3),
    '&.Mui-focused': {
      color: colors.blue[600],
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },
  '&.MuiInputLabel-shrink': {
    transform: 'translate(0px, 0px)',
  },
}));

export const InputLabel = (props: InputLabelProps) => {
  return <StyledInputLabel {...props} />;
};
