import React from 'react';
import { getStaticState } from '../../../state/helpers/getStaticState';
import { TagsEditDialog } from './TagsEditDialog';
import { TagsDeleteDialog } from './TagsDeleteDialog';
import { Tag } from '../../../types/tags';

type TagsDialogsProps = {
  editing: Tag;
  deleting: Tag;
  onEditingChange: (value: string) => void;
  onCloseEditing: () => void;
  onCloseDeleting: () => void;
};

export const TagsDialogs = ({
  editing,
  deleting,
  onEditingChange,
  onCloseEditing,
  onCloseDeleting,
}: TagsDialogsProps) => {
  const { updateTag, deleteTag } = getStaticState();
  return (
    <>
      <TagsEditDialog
        open={Boolean(editing)}
        handleClose={onCloseEditing}
        handleInputChange={(value: string) => onEditingChange(value)}
        handleSubmit={async (event: any) => {
          event.preventDefault();
          await updateTag(editing);
          onCloseEditing();
        }}
        tag={editing}
      />
      <TagsDeleteDialog
        open={Boolean(deleting)}
        handleClose={onCloseDeleting}
        handleSubmit={(event: any) => {
          event.preventDefault();
          deleteTag(deleting.id);
          onCloseDeleting();
        }}
        tag={deleting}
      />
    </>
  );
};
