import { store } from '../../config/store';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { entityNames } from '../../constants/entityNames';
import { getStatusKey } from './helpers';

const initialState = store.getState();
const resetState = () => store.setState(initialState, true);

describe('statusesSLice', () => {
  afterEach(() => {
    resetState();
  });

  describe('setEntityStatus', () => {
    it('should set the status of an entity', () => {
      const { setEntityStatus } = store.getState();
      setEntityStatus(entityNames.PLANS, allowedStatuses.PERSISTED);

      const state = store.getState();

      expect(state.entities.statuses[entityNames.PLANS]).toBe(allowedStatuses.PERSISTED);
    });

    it('should set the status of an entity with entity id', () => {
      const planId = '1';
      const { setEntityStatus } = store.getState();
      setEntityStatus(entityNames.PLANS, allowedStatuses.PERSISTED, planId);

      const state = store.getState();
      const key = getStatusKey(entityNames.PLANS, planId);

      // @ts-ignore
      expect(state.entities.statuses[key]).toBe(allowedStatuses.PERSISTED);
    });
  });
});
