import { GetState, SetState } from 'zustand/vanilla';
import { entityNames } from '../../constants/entityNames';
import { AppStateInterface } from '../../config/storeTypes';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { JourneyMapUserAssociationType } from '../../../types/user';
import { getJourneyMapUserAssociations } from '../../../api/journey-map-users-association/getJourneyMapUserAssociations';
import { createJourneyMapUserAssociation } from '../../../api/journey-map-users-association/createJourneyMapUserAssociation';
import { deleteJourneyMapUserAssociation } from '../../../api/journey-map-users-association/deleteJourneyMapUserAssociation';

export const journeyMapUserAssociationsSlice = (
  set: SetState<AppStateInterface>,
  get: GetState<AppStateInterface>
) => ({
  getJourneyMapUserAssociations: async (projectId: string, journeyMapId: string) => {
    const {
      setEntityStatus,
      addError,
      setDenormalizedData,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.LOADING);
      const journeyMapUserAssociations: JourneyMapUserAssociationType[] =
        await getJourneyMapUserAssociations(organization.slug, projectId, journeyMapId);
      setDenormalizedData(journeyMapUserAssociations, entityNames.JOURNEY_MAP_USER_ASSOCIATIONS);
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.IDLE);
    } catch (error) {
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.ERROR);
      addError(error, entityNames.JOURNEY_MAP_USER_ASSOCIATIONS);
    }
  },
  createJourneyMapUserAssociation: async (
    projectId: string,
    journeyMapId: string,
    userId: string
  ) => {
    const {
      setEntityStatus,
      addError,
      setDenormalizedData,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.PERSISTING);
      const userAssociation: JourneyMapUserAssociationType = await createJourneyMapUserAssociation(
        organization.slug,
        projectId,
        journeyMapId,
        userId
      );
      setDenormalizedData(userAssociation, entityNames.JOURNEY_MAP_USER_ASSOCIATIONS);
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.PERSISTED);
    } catch (error) {
      addError(error, entityNames.JOURNEY_MAP_USER_ASSOCIATIONS);
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.ERROR);
    }
  },
  deleteJourneyMapUserAssociation: async (projectId: string, journeyMapId: string, id: string) => {
    const {
      setEntityStatus,
      addError,
      removeEntity,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.LOADING, id);
      await deleteJourneyMapUserAssociation(organization.slug, projectId, journeyMapId, id);
      removeEntity(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, id);
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.IDLE);
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.IDLE, id);
    } catch (error) {
      addError(error, entityNames.JOURNEY_MAP_USER_ASSOCIATIONS);
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.ERROR);
      setEntityStatus(entityNames.JOURNEY_MAP_USER_ASSOCIATIONS, allowedStatuses.ERROR, id);
    }
  },
});
