import React from 'react';
import MuiSwitch, { SwitchProps as MuiSwitchProps, switchClasses } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { colors } from '../../../theme';

export type SwitchProps = MuiSwitchProps;

export const StyledSwitch = styled(MuiSwitch)(() => ({
  [`& .${switchClasses.switchBase}`]: {
    color: colors.smoke,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  [`& .${switchClasses.switchBase} + .${switchClasses.track}`]: {
    backgroundColor: colors.onyx,
  },

  [`& .${switchClasses.switchBase}.${switchClasses.checked}`]: {
    color: colors.blue[500],
  },
  [`& .${switchClasses.switchBase}.${switchClasses.checked} + .${switchClasses.track}`]: {
    backgroundColor: colors.blue[500],
  },
}));

export const Switch = (props: SwitchProps) => {
  return <StyledSwitch {...props} />;
};
