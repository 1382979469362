import React from 'react';
import {
  LearnMenuHeader as SUILearnMenuHeader,
  LearnMenuHeaderBare as SUILearnMenuHeaderBare,
} from './src/components/molecules/LearnMenu/LearnMenu';
import { K2Theme } from './src/theme/material/k2';
import { ThemeProvider } from '@mui/material';

export function LearnMenuHeader() {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUILearnMenuHeader
        MenuProps={{
          transformOrigin: { horizontal: 'right', vertical: 'top' },
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        }}
      />
    </ThemeProvider>
  );
}

export function LearnMenuHeaderBare() {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUILearnMenuHeaderBare
        MenuProps={{
          transformOrigin: { horizontal: 'right', vertical: 'top' },
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        }}
      />
    </ThemeProvider>
  );
}
