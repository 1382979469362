import { createTheme } from '@mui/material/styles';
import { fontFamily, fontVariant, fontVariants } from '../typography/typography';
import { colors } from '../colors/colors';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    danger: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    regularXXL: React.CSSProperties;
    regularXL: React.CSSProperties;
    regularL: React.CSSProperties;
    regularM: React.CSSProperties;
    regularS: React.CSSProperties;
    regularXS: React.CSSProperties;
    regularXXS: React.CSSProperties;
    boldXXL: React.CSSProperties;
    boldXL: React.CSSProperties;
    boldL: React.CSSProperties;
    boldM: React.CSSProperties;
    boldS: React.CSSProperties;
    boldXS: React.CSSProperties;
    boldXXS: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    regularXXL?: React.CSSProperties;
    regularXL?: React.CSSProperties;
    regularL?: React.CSSProperties;
    regularM?: React.CSSProperties;
    regularS?: React.CSSProperties;
    regularXS?: React.CSSProperties;
    regularXXS?: React.CSSProperties;
    boldXXL?: React.CSSProperties;
    boldXL?: React.CSSProperties;
    boldL?: React.CSSProperties;
    boldM?: React.CSSProperties;
    boldS?: React.CSSProperties;
    boldXS?: React.CSSProperties;
    boldXXS?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    regularXXL: true;
    regularXL: true;
    regularL: true;
    regularM: true;
    regularS: true;
    regularXS: true;
    regularXXS: true;
    boldXXL: true;
    boldXL: true;
    boldL: true;
    boldM: true;
    boldS: true;
    boldXS: true;
    boldXXS: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    danger: true;
  }
}

type ColorType = keyof typeof colors;

type ColorPalette = {
  primary: ColorType;
  info: ColorType;
  success: ColorType;
  warning: ColorType;
  error: ColorType;
  danger: ColorType;
};

export const palette: ColorPalette = {
  primary: 'blue',
  info: 'blue',
  success: 'green',
  warning: 'yellow',
  error: 'red',
  danger: 'red',
};

export const K2Theme = createTheme({
  zIndex: {
    modal: 1900,
  },
  spacing: 4,
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontSize: 14, // default font size
    htmlFontSize: 10, // base size adjusts to our 62.5%
    fontFamily,
    ...fontVariants,
    button: {
      ...fontVariant(400, 14, 22),
    },
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    caption: undefined,
    overline: undefined,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'regularS',
        color: colors.onyx,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          transition: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: 'none',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          fontSize: fontVariants.regularXXS.fontSize,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          marginBottom: 2,
          border: '1px solid transparent',
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: 1,
          minWidth: 4,
          justifyContent: 'center',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: colors.white,
          backgroundColor: colors.onyx,
          padding: 12,
          maxWidth: 325,
          boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, .3)',
        },
        arrow: {
          color: colors.onyx,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          color: colors.onyx,
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      light: colors[palette.primary][400],
      main: colors[palette.primary][500],
      dark: colors[palette.primary][700],
      contrastText: colors.white,
    },
    secondary: {
      light: colors.smoke,
      main: colors.aluminium,
      dark: colors.iron,
      contrastText: colors.smoke,
    },
    info: {
      light: colors[palette.info][200],
      main: colors[palette.info][500],
      dark: colors[palette.info][700],
    },
    success: {
      light: colors[palette.success][200],
      main: colors[palette.success][500],
      dark: colors[palette.success][700],
    },
    warning: {
      light: colors[palette.warning][200],
      main: colors[palette.warning][500],
      dark: colors[palette.warning][700],
    },
    error: {
      light: colors[palette.error][300],
      main: colors[palette.error][600],
      dark: colors[palette.error][700],
    },
    danger: {
      light: colors[palette.danger][300],
      main: colors[palette.danger][500],
      dark: colors[palette.danger][700],
    },
  },
});
