import { nanoid } from 'nanoid';
import { Plan } from '../types/pricing';

// Jesse: the enterprise plans are added manually because they are not part of the /plans endpoint.
export const SmaplyEnterpriseMonthlyEur: Plan = {
  addon_applicability: 'restricted',
  cf_public_plan: 'True',
  cf_student_plan: 'False',
  charge_model: 'per_unit',
  chargebee_id: 'smaply-enterprise-eur-m',
  currency_code: 'EUR',
  current_plan: false,
  description: '',
  enabled_in_hosted_pages: false,
  enabled_in_portal: false,
  free_quantity: 0,
  giftable: false,
  id: nanoid(),
  invoice_name: 'Enterprise Smaply User Seats',
  is_shippable: false,
  meta_data: {
    allow_invitations: true,
    // auto_purchase_addons: {},
    features: [],
    limits: {},
  },
  name: 'Enterprise EUR Monthly',
  object: 'plan',
  period_unit: 'month',
  period: 1,
  price: 0,
  pricing_model: 'per_unit',
  resource_version: 1632916246,
  show_description_in_invoices: false,
  show_description_in_quotes: false,
  status: 'active',
  taxable: true,
  trial_period_unit: null,
  trial_period: null,
  updated_at: 1632916246,
};

export const SmaplyEnterpriseYearlyEur: Plan = {
  addon_applicability: 'restricted',
  cf_public_plan: 'True',
  cf_student_plan: 'False',
  charge_model: 'per_unit',
  chargebee_id: 'smaply-enterprise-eur-a',
  currency_code: 'EUR',
  current_plan: false,
  description: '',
  enabled_in_hosted_pages: false,
  enabled_in_portal: false,
  free_quantity: 0,
  giftable: false,
  id: nanoid(),
  invoice_name: 'Enterprise Smaply User Seats',
  is_shippable: false,
  meta_data: {
    allow_invitations: true,
    // auto_purchase_addons: {},
    features: [],
    limits: {},
  },
  name: 'Enterprise EUR Yearly',
  object: 'plan',
  period_unit: 'year',
  period: 1,
  price: 0,
  pricing_model: 'per_unit',
  resource_version: 1632916246,
  show_description_in_invoices: false,
  show_description_in_quotes: false,
  status: 'active',
  taxable: true,
  trial_period_unit: null,
  trial_period: null,
  updated_at: 1632916246,
};

export const SmaplyEnterpriseMonthlyUsd: Plan = {
  ...SmaplyEnterpriseMonthlyEur,
  ...{
    chargebee_id: 'smaply-enterprise-usd-m',
    currency_code: 'USD',
    name: 'Enterprise USD Monthly',
  },
};

export const SmaplyEnterpriseYearlyUsd: Plan = {
  ...SmaplyEnterpriseYearlyEur,
  ...{
    chargebee_id: 'smaply-enterprise-usd-a',
    currency_code: 'USD',
    name: 'Enterprise USD Yearly',
  },
};
