import { entityNames } from '../../state/constants/entityNames';
import { useEntities } from '../../state/hooks/useEntities';
import { AdditionalFeatureType } from '../../types/subscription';

export type FeatureFlagProps = {
  children: JSX.Element;
  featureId: AdditionalFeatureType;
  fallBackUI?: JSX.Element;
};

export const FeatureFlag = ({ children, featureId, fallBackUI }: FeatureFlagProps) => {
  const {
    entities: {
      // eslint-disable-next-line
      organization: { additional_features },
    },
  } = useEntities(entityNames.ORGANIZATION);
  const hasFeature = Boolean(additional_features.find((feature: any) => feature === featureId));
  if (hasFeature) {
    return children;
  }

  return fallBackUI || null;
};
