import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SidebarLayout } from '../Sidebar';
import { Skeleton } from '../../atoms/Skeleton/Skeleton';

export const CreateJourneyMapSkeleton = () => {
  return (
    <SidebarLayout
      drawerContent={
        <Box sx={{ p: 8 }}>
          <Box display="flex">
            <Skeleton height={34} variant="text">
              <Typography variant="boldM">New journey map</Typography>
            </Skeleton>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Skeleton height={30} variant="text">
              <Typography variant="regularS">Templates</Typography>
            </Skeleton>
            <Skeleton height={30} variant="text">
              <Typography variant="regularS">Imports</Typography>
            </Skeleton>
            <Skeleton height={30} variant="text">
              <Typography variant="regularS">Examples</Typography>
            </Skeleton>
          </Box>
        </Box>
      }
      mainContent={
        <Box sx={{ pl: 3, py: 8 }}>
          <Box>
            <Box>
              <Skeleton height={40} width={120} variant="text">
                <Typography variant="boldM">Templates</Typography>
              </Skeleton>
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <Skeleton variant="rectangular" sx={{ mt: 6, width: 260, height: 300 }} />
              <Skeleton variant="rectangular" sx={{ mt: 6, width: 260, height: 300 }} />
              <Skeleton variant="rectangular" sx={{ mt: 6, width: 260, height: 300 }} />
              <Skeleton variant="rectangular" sx={{ mt: 6, width: 260, height: 300 }} />
              <Skeleton variant="rectangular" sx={{ mt: 6, width: 260, height: 300 }} />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rectangular" sx={{ my: 8, width: '100%' }} />
          </Box>
          <Box>
            <Box>
              <Skeleton height={40} width={120} variant="text">
                <Typography variant="boldM">Import</Typography>
              </Skeleton>
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <Skeleton variant="rectangular" sx={{ mt: 6, width: 260, height: 300 }} />
              <Skeleton variant="rectangular" sx={{ mt: 6, width: 260, height: 300 }} />
              <Skeleton variant="rectangular" sx={{ mt: 6, width: 260, height: 300 }} />
            </Box>
          </Box>
        </Box>
      }
    />
  );
};
