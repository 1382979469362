import React from 'react';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import { Alert } from '../../../atoms/Alert/Alert.styled';
import { Box, Typography } from '@mui/material';
import { colors } from '../../../../theme';
import { Button } from '../../../atoms/Button/Button';
import { PlanChipPro } from '../../Plan/Chip/PlanChip';

type BlockedFeatureAlertProps = {
  children?: React.ReactNode;
  planChip?: boolean;
};

export const BlockedFeatureAlert = ({ children, planChip = true }: BlockedFeatureAlertProps) => {
  const {
    entities: { organization },
  } = useEntities(entityNames.ORGANIZATION);

  return (
    <Alert icon={false} sx={{ display: 'flex', backgroundColor: colors.smoke }}>
      <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" maxWidth={665}>
          {planChip ? (
            <Box
              sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: colors.white,
                width: 48,
                height: 48,
                // temp until we resolve material imports
                mr: '16px',
                p: '8px',
              }}
            >
              <PlanChipPro />
            </Box>
          ) : null}
          <Typography color={colors.carbon} variant="regularM">
            {children}
          </Typography>
        </Box>
        <Box>
          <Button href={`/#${organization.slug}/plan`} fullWidth color="primary">
            Upgrade plan
          </Button>
        </Box>
      </Box>
    </Alert>
  );
};
