import React from 'react';
import {
  createFilterOptions,
  AutocompleteRenderOptionState,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteRenderGetTagProps,
} from '@mui/material/Autocomplete';
import { AutoComplete } from '../../../atoms/AutoComplete/AutoComplete';
import { Popper } from '../../../atoms/Popper/Popper';
import { Tag } from '../../../../types/tags';
import { Input } from '../../../atoms/Inputs/Input/Input';

export type TagInputProps<T> = T & {
  newTag?: boolean;
  newTagLabel?: string;
};

export interface ProjectTagsInputProps {
  loading: boolean;
  availableTags: Tag[];
  selectedTags: Tag[];
  limitTags: number;
  variant: 'default' | 'border';
  inputLabel: string;
  inputPlaceholder: string;
  renderTags: (
    value: TagInputProps<Tag>[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => React.ReactNode;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TagInputProps<Tag>,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  handleChange?: (
    event: React.ChangeEvent<unknown>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TagInputProps<Tag | string>>
  ) => void;
}

export const ProjectTagsInput = ({
  loading,
  availableTags,
  selectedTags,
  limitTags,
  variant,
  inputLabel,
  inputPlaceholder,
  renderTags,
  renderOption,
  handleChange,
}: ProjectTagsInputProps) => {
  const tagsLabelKey = 'name';
  const filter = createFilterOptions();
  return (
    <AutoComplete
      loading={loading}
      loadingText="Loading tags..."
      options={availableTags}
      value={selectedTags}
      autoSelect
      limitTags={limitTags}
      multiple
      disableCloseOnSelect
      freeSolo
      autoComplete
      disableClearable
      handleHomeEndKeys
      className={`${variant} ${inputLabel ? 'visibleLabel' : 'hiddenLabel'}`}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        return option[tagsLabelKey] as unknown as string;
      }}
      filterOptions={(options, state) => {
        const filtered = filter(options, state);
        const exists = filtered.find(
          (option: Tag) => state.getOptionLabel(option) === state.inputValue
        );
        // push fake option to suggest tag creation
        if (state.inputValue !== '' && !exists) {
          filtered.push({
            newTag: true,
            newTagLabel: `No tag was found. Press enter to create new tag "${state.inputValue}"`,
            [tagsLabelKey]: state.inputValue,
          });
        }

        return filtered as TagInputProps<Tag>[];
      }}
      PopperComponent={Popper}
      renderInput={({ InputProps, InputLabelProps, ...params }) => (
        <Input
          id="tags-input"
          onKeyDown={(event) => {
            if (event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
          placeholder={inputPlaceholder}
          {...params}
          InputLabelProps={{ ...InputLabelProps, shrink: true }}
          InputProps={{ ...InputProps, disableUnderline: true }}
          label={inputLabel}
        />
      )}
      isOptionEqualToValue={(option: Tag, value: Tag) => {
        return option.id === value.id;
      }}
      renderTags={renderTags}
      renderOption={renderOption}
      onChange={handleChange}
    />
  );
};
