import { useRef, useEffect } from 'react';

// this will observe the DOM an notify when a particular DOM element is rendered.
// docs for MutationObserver: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
const setupMutationObserver = (el: HTMLElement, onElementRendered: (el: HTMLElement) => void) => {
  const observer = new MutationObserver(() => {
    if (document.contains(el)) {
      onElementRendered(el);
      observer.disconnect();
    }
  });

  observer.observe(document, {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: true,
  });
};

export const useMutationObserver = (onElementRendered: (el: HTMLElement) => void) => {
  const ref = useRef(null);
  useEffect(() => {
    setupMutationObserver(ref.current, onElementRendered);
    // eslint-disable-next-line
  }, []);
  return ref;
};
