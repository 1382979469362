import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Skeleton } from '../../../atoms/Skeleton/Skeleton';

export const PlansTableSkeleton = () => {
  return (
    <>
      <Box mt={9} display="flex" justifyContent="space-between">
        <Skeleton variant="rectangular" width={140} height={214} />
        <Skeleton variant="rectangular" width={140} height={214} />
        <Skeleton variant="rectangular" width={140} height={214} />
        <Skeleton variant="rectangular" width={140} height={214} />
      </Box>
      <Stack mt={6} spacing={2}>
        <Skeleton variant="rectangular" height={22} />
        <Skeleton variant="rectangular" height={22} />
        <Skeleton variant="rectangular" height={22} />
        <Skeleton variant="rectangular" height={22} />
        <Skeleton variant="rectangular" height={22} />
        <Skeleton variant="rectangular" height={22} />
      </Stack>
    </>
  );
};
