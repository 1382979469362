import { organization } from './organization';
import { generateName } from './generateName';
import { Gon } from '../types/gon';
import { environment } from '../utils/environment';

const fullName = generateName();
const firstName = fullName.split(' ')[0];
const lastName = fullName.split(' ')[1];

export const gon: Partial<Gon> = {
  environment: environment,
  current_user: {
    color: 'default',
    confirmed_at: '2021-07-07T11:15:48.000+02:00',
    created_at: '2018-01-17T17:56:34.000+01:00',
    destroyable: true,
    dpa_accepted_at: '2018-05-08T16:49:23.000+02:00',
    email: 'mail@smaply.com',
    first_name: firstName,
    full_name: fullName,
    id: Math.round(Math.random() * 400),
    initials: null,
    last_name: lastName,
    locale: null,
    notifications_last_closed_at: '2022-03-02T13:53:55.000+01:00',
    provider: null,
    receive_trial_emails: true,
    organizations: [organization],
  },
};
