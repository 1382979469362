import React from 'react';
import {
  PlanTemplate as SUIPlanTemplate,
  PlanTemplateProps as SUIPlanTemplateProps,
} from './src/components/templates/Settings/Plan/PlanTemplate';
import {
  PlanMessagePropsWithAdmin as SUIPlanMessagePropsWithAdmin,
  PlanMessagePropsWithDate as SUIPlanMessagePropsWithDate,
  TrialEnded as SUITrialEnded,
  Locked as SUILocked,
} from './src/components/molecules/Plan/PlanAlerts';
import { K2Theme } from './src/theme/material/k2';
import { ThemeProvider } from '@mui/material';

export function PlanTemplate(props: SUIPlanTemplateProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUIPlanTemplate {...props} />
    </ThemeProvider>
  );
}

export function PlanAlertTrialEnded(props: SUIPlanMessagePropsWithAdmin) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUITrialEnded {...props} />
    </ThemeProvider>
  );
}

export function PlanAlertLocked(props: SUIPlanMessagePropsWithDate) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUILocked {...props} />
    </ThemeProvider>
  );
}
