import React from 'react';
import { Box, Typography } from '@mui/material';
import { loremIpsum } from 'lorem-ipsum';
import { colors } from '../../../theme';

export const AccordionHeader = () => {
  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <Typography component="div" variant="boldXS" color={colors.iron}>
        DEMO ACCORDION
      </Typography>
      <Typography component="div" variant="regularXXS" color={colors.aluminium}>
        Some text in the right
      </Typography>
    </Box>
  );
};
export const AccordionContent = () => (
  <Typography variant="regularXS" color={colors.iron}>
    Example content for Accordion: {loremIpsum({ count: 2, units: 'paragraphs' })}
  </Typography>
);
