import { chargebeeSubscriptionStatus, subscriptionStatus } from '../constants/subscriptionStatus';
import { Subscription } from '../types/pricing';

export const subscriptionIsInTrial = ({ status }: Subscription) =>
  status === chargebeeSubscriptionStatus.IN_TRIAL;

export const subscriptionIsInTrialEnded = ({ organization_subscription_status }: Subscription) =>
  organization_subscription_status === subscriptionStatus.TRIAL_ENDED;

export const subscriptionLocked = ({ organization_subscription_status }: Subscription) =>
  organization_subscription_status === subscriptionStatus.LOCKED;
