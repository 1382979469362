import React from 'react';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import { MetadataField } from './metadataFields';
import { JourneyMap } from '../../../../types/journeyMap';
import { AllowedStatuses } from '../../../../types/state';
import { InputCharCount } from '../../../atoms/Inputs/InputCharCount/InputCharCount';

export type MetadataTitleFieldProps = {
  saveJourneyMap: (field: MetadataField, value: string, journeyMap: JourneyMap) => void;
  getFieldStatus: (field: MetadataField, status: AllowedStatuses) => AllowedStatuses;
};

export const MetadataTitleField = ({ saveJourneyMap, getFieldStatus }: MetadataTitleFieldProps) => {
  const {
    entities: { journeyMap },
    statuses,
  } = useEntities(entityNames.JOURNEY_MAP);
  const { title } = journeyMap;
  return (
    <InputCharCount
      onBlur={(event: any) => saveJourneyMap('title', event.target.value, journeyMap)}
      status={getFieldStatus('title', statuses.journeyMap)}
      defaultValue={title}
      label="title"
      multiline
      fullHeight
      maxLength={255}
    />
  );
};
