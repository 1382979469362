export const planDisplayNames = {
  'business-eur-3-month': 'Business',
  'business-eur-6-months': 'Business',
  'smaply-basic-eur-a': 'Basic',
  'smaply-basic-eur-m': 'Basic',
  'smaply-basic-usd-a': 'Basic',
  'smaply-basic-usd-m': 'Basic',
  'smaply-business-eur-a': 'Business',
  'smaply-business-eur-m': 'Business',
  'smaply-business-usd-a': 'Business',
  'smaply-business-usd-m': 'Business',
  'smaply-corporate-eur-a': 'Corporate',
  'smaply-corporate-usd-a': 'Corporate',
  'smaply-enterprise-eur-a': 'Enterprise',
  'smaply-enterprise-eur-m': 'Enterprise',
  'smaply-enterprise-usd-a': 'Enterprise',
  'smaply-enterprise-usd-m': 'Enterprise',
  'smaply-free-eur-m': 'Free',
  'smaply-free-usd-m': 'Free',
  'smaply-internal-eur-a': 'Internal',
  'smaply-internal-eur-m': 'Internal',
  'smaply-performance-eur-a': 'Performance',
  'smaply-performance-usd-a': 'Performance',
  'smaply-pro-eur-a': 'Pro',
  'smaply-pro-eur-m': 'Pro',
  'smaply-pro-usd-a': 'Pro',
  'smaply-pro-usd-m': 'Pro',
  'smaply-regular-eur-a': 'Regular',
  'smaply-regular-eur-m': 'Regular',
  'smaply-regular-usd-a': 'Regular',
  'smaply-regular-usd-m': 'Regular',
  'smaply-scale-up-eur-a': 'Scale Up',
  'smaply-scale-up-usd-a': 'Scale Up',
  'smaply-starter-eur-a': 'Starter',
  'smaply-starter-eur-m': 'Starter',
  'smaply-starter-usd-a': 'Starter',
  'smaply-starter-usd-m': 'Starter',
  'smaply-student-eur-a': 'Student',
  'smaply-student-eur-m': 'Student',
  'smaply-student-usd-a': 'Student',
  'smaply-student-usd-m': 'Student',
  'smaply-whitelabel-eur-a': 'Whitelabel',
  'smaply-whitelabel-usd-a': 'Whitelabel',
} as const;
