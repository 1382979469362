import isString from 'lodash/isString';
import { billingInformation } from '../../mocks/billingInformation';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { BillingInformation } from '../../types/pricing';
import { PlansApi } from './plansApi';

export const getBillingInformation = async (
  organizationId: string,
  abortSignal?: AbortSignal
): Promise<BillingInformation> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (shouldMockSmaplyAPI) {
    return billingInformation;
  }

  try {
    const result: BillingInformation = await PlansApi.getBillingInformation(organizationId, {
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
