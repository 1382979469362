import { GetState, SetState } from 'zustand/vanilla';
import { entityNames } from '../../constants/entityNames';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { default as produce } from 'immer';
import { errorsMethods } from '../errors/errorsSelectors';
import { statusesMethods } from '../statuses/statusesSelector';
import { AppStateInterface } from '../../config/storeTypes';
import {
  BillingInformation,
  ChargebeePlanId,
  CurrentPlan,
  Plan,
  PlanValidation,
  Subscription,
} from '../../../types/pricing';
import { planFactory, plansFactory } from '../../../business-logic/plansFactory';
import { getPlanValidation } from '../../../api/plans/getPlanValidation';
import { getPlans } from '../../../api/plans/getPlans';
import { getBillingInformation } from '../../../api/plans/getBillingInformation';
import { getCurrentPlan } from '../../../api/plans/getCurrentPlan';
import { getSubscription } from '../../../api/plans/getSubscription';

export const plansSlice = (set: SetState<AppStateInterface>, get: GetState<AppStateInterface>) => ({
  getPlans: async (abortSignal?: AbortSignal) => {
    const entityName = entityNames.PLANS;
    const {
      setDenormalizedData,
      entities: { organization },
    } = get();
    const { setEntityStatus } = statusesMethods(get());

    try {
      const currentPlan: CurrentPlan = await getCurrentPlan(organization.slug, abortSignal);

      setEntityStatus(entityName, allowedStatuses.LOADING);
      const response: Plan[] = await getPlans(organization.slug, abortSignal);
      const plans = plansFactory(response, currentPlan.currency_code);
      setDenormalizedData(plans, entityName);
      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  getPlanValidation: async (planId: ChargebeePlanId, abortSignal?: AbortSignal) => {
    const entityName = entityNames.PLAN_VALIDATION;
    const {
      setDenormalizedData,
      entities: { organization },
    } = get();
    const { setEntityStatus } = statusesMethods(get());
    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const response: PlanValidation = await getPlanValidation(
        organization.slug,
        planId,
        abortSignal
      );

      setDenormalizedData(response, entityName);
      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  getBillingInformation: async (abortSignal?: AbortSignal) => {
    const entityName = entityNames.BILLING_INFORMATION;
    const { setEntityStatus } = statusesMethods(get());
    const {
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const response: BillingInformation = await getBillingInformation(
        organization.slug,
        abortSignal
      );

      set((state) =>
        produce(state, (draft) => {
          /* eslint-disable no-param-reassign */
          draft.entities[entityName] = response;
        })
      );

      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  getCurrentPlan: async (abortSignal?: AbortSignal) => {
    const entityName = entityNames.CURRENT_PLAN;
    const { setEntityStatus } = statusesMethods(get());
    const {
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const response: CurrentPlan = await getCurrentPlan(organization.slug, abortSignal);

      set((state) =>
        produce(state, (draft) => {
          /* eslint-disable no-param-reassign */
          const currentPlan = planFactory(response);
          draft.entities[entityName] = currentPlan;
        })
      );

      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  getSubscription: async (abortSignal?: AbortSignal) => {
    const entityName = entityNames.SUBSCRIPTION;
    const { setEntityStatus } = statusesMethods(get());
    const {
      entities: { organization },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const response: Subscription = await getSubscription(organization.slug, abortSignal);

      set((state) =>
        produce(state, (draft) => {
          /* eslint-disable no-param-reassign */
          draft.entities[entityName] = response;
        })
      );

      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
});
