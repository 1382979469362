import React, { useState } from 'react';
import Button, { ButtonProps } from './button';
import Input from './input';
import PasswordIndicator from './password_indicator';

interface IndicatorGroupProps {
  password: any;
  passwordConfirm: any;
  buttonProps: ButtonProps;
  inputGroupClass: string;
}

const PasswordIndicatorGroup = (props: IndicatorGroupProps) => {
  const {
    password,
    passwordConfirm,
    buttonProps,
    inputGroupClass = '',
  } = props;

  const [inputValues, setInputValue] = useState({
    password: '',
    passwordConfirm: '',
  });

  const onPasswordChange = (
    name: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInputValue((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  return (
    <>
      <div className={`${inputGroupClass}`}>
        <Input onChange={(event) => onPasswordChange('password', event)} {...password} />
        <Input onChange={(event) => onPasswordChange('passwordConfirm', event)} {...passwordConfirm} />
      </div>

      <div className="flex justify-between flex-col md:flex-row">
        <div className="max-w-full md:min-w-xxs">
          <PasswordIndicator password={inputValues.password} />
        </div>

        <Button {...buttonProps} />
      </div>
    </>
  );
};

export default PasswordIndicatorGroup;
