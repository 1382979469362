import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { default as diagonalStripes } from '../../../images/patterns/diagonal_stripes.svg';

export const StripedOverlay = ({ sx, ...rest }: BoxProps) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        background: `url(${diagonalStripes})`,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        ...sx,
      }}
      {...rest}
    />
  );
};
