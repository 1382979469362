import { Options } from 'ky';
import { JourneyMap } from '../../types/journeyMap';
import { throwError } from '../../utils/throwError';
import { JourneyMapApi } from './journeyMapApi';

export const postJourneyMapDuplicateExample = async (
  organizationId: string,
  projectId: string,
  exampleId: string,
  options?: Options
): Promise<JourneyMap> => {
  if (!organizationId) {
    throwError('missing organization id');
  }

  if (!projectId) {
    throwError('missing project id');
  }

  if (!exampleId) {
    throwError('missing journey map example id');
  }

  try {
    const result: JourneyMap = await JourneyMapApi.postJourneyMapDuplicateExample(
      organizationId,
      projectId,
      exampleId,
      options
    ).json();
    return result;
  } catch (error) {
    console.error(error);
    if (typeof window.showErrorFlashMessage === 'function') window.showErrorFlashMessage(error);
    throw error;
  }
};
