import React from 'react';
import { Alert } from '../../atoms/Alert/Alert';
import { ButtonBase } from '../../atoms/ButtonBase/ButtonBase';

export interface PlanMessageProps {
  userIsAdmin?: boolean;
  onCTAClick?: () => void;
  onClickOpenChargebeePortal?: () => void;
}

export type PlanMessagePropsWithDate = PlanMessageProps & {
  scheduledDate?: string;
};

export type PlanMessagePropsWithPlanNamesAndDate = PlanMessageProps & {
  planNameOld: string;
  planNameNew: string;
  scheduledDate: string;
};

export type PlanMessagePropsWithAdmin = PlanMessageProps & {
  adminName: string;
  adminEmail: string;
};

export type PlanMessagePropsWithOrgName = PlanMessageProps & {
  orgName: string;
};

export const ScheduledDowngrade = ({
  planNameOld,
  planNameNew,
  scheduledDate,
  onClickOpenChargebeePortal,
}: PlanMessagePropsWithPlanNamesAndDate) => {
  const severity = 'info';
  const action = (
    <ButtonBase onClick={onClickOpenChargebeePortal}>CANCEL SCHEDULED DOWNGRADE</ButtonBase>
  );

  return (
    <Alert severity={severity} action={action}>
      Your account will be downgraded from {planNameOld} to {planNameNew} plan on {scheduledDate}.
    </Alert>
  );
};

export const ScheduledUpgrade = ({
  userIsAdmin,
  planNameOld,
  planNameNew,
  scheduledDate,
  onClickOpenChargebeePortal,
}: PlanMessagePropsWithPlanNamesAndDate & PlanMessageProps) => {
  const severity = 'info';

  const action = userIsAdmin ? (
    <ButtonBase onClick={onClickOpenChargebeePortal}>CANCEL SCHEDULED UPGRADE</ButtonBase>
  ) : undefined;

  return (
    <Alert severity={severity} action={action}>
      Your account will be upgraded from {planNameOld} to {planNameNew} plan on {scheduledDate}
      .&nbsp;
      {userIsAdmin
        ? 'Until then, you can still cancel the scheduled upgrade.'
        : 'In case you want to cancel this upgrade, please contact your admin.'}
    </Alert>
  );
};

export const ScheduledDowngradeLimitsExceed = ({
  userIsAdmin,
  planNameOld,
  planNameNew,
  scheduledDate,
  onClickOpenChargebeePortal,
}: PlanMessagePropsWithPlanNamesAndDate & PlanMessageProps) => {
  const severity = 'warning';
  const action = userIsAdmin ? (
    <ButtonBase onClick={onClickOpenChargebeePortal}>CANCEL SCHEDULED UPGRADE</ButtonBase>
  ) : undefined;

  return (
    <Alert severity={severity} action={action}>
      Your account will be downgraded from {planNameOld} to {planNameNew} plan on{scheduledDate}.In
      case any plan limits are exceeded, you will have to delete personas, journey maps, stakeholder
      maps or projects.
    </Alert>
  );
};

export const ScheduledCancel = ({
  userIsAdmin,
  scheduledDate,
  onClickOpenChargebeePortal,
}: PlanMessagePropsWithDate) => {
  const severity = 'warning';
  const action = userIsAdmin ? (
    <ButtonBase onClick={onClickOpenChargebeePortal}>CANCEL CANCELLATION</ButtonBase>
  ) : null;

  return (
    <Alert severity={severity} action={action}>
      Your subscription will be cancelled on {scheduledDate}. But don&apos;t worry, we will store
      your work for another 6 months in case you change your mind.
    </Alert>
  );
};

export const Locked = ({ userIsAdmin, onCTAClick }: PlanMessagePropsWithDate) => {
  const severity = 'error';
  const action =
    userIsAdmin && onCTAClick ? (
      <ButtonBase onClick={onCTAClick}>REACTIVATE ACCOUNT</ButtonBase>
    ) : null;

  return (
    <Alert severity={severity} action={action}>
      Your account is locked because your subscription was cancelled.&nbsp;
      {userIsAdmin
        ? 'You can reactivate your account until 6 months after your renewal date or your account will be deleted.'
        : 'For further info, contact the administrator of your organization: '}
    </Alert>
  );
};

export const PaymentIssues = ({
  userIsAdmin,
  adminName,
  adminEmail,
}: PlanMessagePropsWithAdmin) => {
  const severity = 'error';

  return (
    <Alert severity={severity}>
      {userIsAdmin
        ? 'Due to payment issues, your account is locked. You can find further information here: [link to braintree issue] or contact our support [link]'
        : `Your account is locked. For further info, contact the administrator of your organization: ${adminName}: ${adminEmail}`}
    </Alert>
  );
};

export const InTrial = ({ userIsAdmin }: PlanMessageProps) => {
  const severity = 'info';
  return (
    <Alert severity={severity}>
      You are currently on a free trial. Your trial period ends on *DD MMMM YYYY*.&nbsp;
      {userIsAdmin ? 'Subscribe to one of our self-service plans.' : ''}
    </Alert>
  );
};

export const TrialEnded = ({
  userIsAdmin,
  adminName,
  adminEmail,
  onCTAClick,
}: PlanMessagePropsWithAdmin) => {
  const severity = 'warning';
  return (
    <Alert
      severity={severity}
      action={
        userIsAdmin && onCTAClick ? <ButtonBase onClick={onCTAClick}>SUBSCRIBE</ButtonBase> : null
      }
    >
      Your free trial period has ended and your account is now locked.&nbsp;
      {userIsAdmin
        ? 'Reactive your account by subscribing to one of our plans.'
        : `For further info, contact the administrator of your organization: ${adminName}: ${adminEmail}`}
    </Alert>
  );
};

export const YouHaveNoOrganizations = ({ orgName }: PlanMessagePropsWithOrgName) => {
  const severity = 'error';

  return (
    <Alert severity={severity} action={<ButtonBase>SUBSCRIBE</ButtonBase>}>
      You have been removed from your organization {orgName} in Smaply. Create your own organization
      and subscribe to one of our self-service plans.
    </Alert>
  );
};
