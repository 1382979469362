import React from 'react';
import Box from '@mui/material/Box';
import { entityNames } from '../../../../state/constants/entityNames';
import { useTheme } from '@mui/material/styles';
import { JourneyMapUsersInput } from '../../Users';
import { JourneyMapsTagInput } from '../../Tags/JourneyMapTags/JourneyMapTagsInput';

const fieldTypes = {
  [entityNames.TAGS]: entityNames.TAGS,
  [entityNames.USERS]: entityNames.USERS,
};

export type MetadataAssignmentFieldProps = {
  projectId: string;
  fieldType: keyof typeof fieldTypes;
};

export const MetadataAssignmentField = ({ projectId, fieldType }: MetadataAssignmentFieldProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '100%',
        '& .MuiAutocomplete-root': {
          height: '100%',
          '& > .MuiBox-root': {
            height: '100%',
          },
          '& .MuiFormControl-root': {
            height: '100%',
          },
          '& .MuiFilledInput-root': {
            height: '100%',
            display: 'unset',
          },
          '& .MuiChip-root': {
            marginTop: theme.spacing(2),
            maxWidth: theme.spacing(58),
          },
          '& .MuiAutocomplete-input.MuiFilledInput-input': {
            width: '100%',
            minWidth: '100%',
          },
        },
      }}
    >
      {fieldType === entityNames.TAGS && <JourneyMapsTagInput projectId={projectId} />}
      {fieldType === entityNames.USERS && <JourneyMapUsersInput projectId={projectId} />}
    </Box>
  );
};
