import React from 'react';
import { buildWallsyncImportUrl } from '../../../business-logic/wallsync';
import { entityNames } from '../../../state/constants/entityNames';
import { useEntities } from '../../../state/hooks/useEntities';
import { Dialog } from '../../atoms/Dialog/Dialog';
import { DialogTitle } from '../../atoms/Dialog/DialogTitle';
import { DialogContent } from '../../atoms/Dialog/DialogContent';

export interface ImportDialogProps {
  open: boolean;
  integration: string;
  handleClose: () => void;
}

export const ImportDialog = ({ open, integration, handleClose }: ImportDialogProps) => {
  const {
    entities: { selectedProject, wallsync },
  } = useEntities(entityNames.SELECTED_PROJECT, entityNames.WALLSYNC);

  const wallsyncEmbedUrl = buildWallsyncImportUrl(integration, selectedProject, wallsync);

  return (
    <Dialog
      open={open}
      fullScreen
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="imports-dialog-title"
      sx={{
        // until we move header to smaply-ui
        zIndex: 10001,
      }}
    >
      <DialogTitle onClose={handleClose}>Smaply Capture</DialogTitle>
      <DialogContent sx={{ p: '0 !important' }}>
        <iframe
          title="Smaply Capture"
          src={wallsyncEmbedUrl}
          height="100%"
          width="100%"
          frameBorder="0"
          allow="fullscreen"
        />
      </DialogContent>
    </Dialog>
  );
};
