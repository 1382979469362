import React from 'react';
import ReactDom from 'react-dom';
import { nanoid } from 'nanoid';
import isFunction from 'lodash/isFunction';
import extend from 'lodash/extend';
import { getStateListenerId, getBackboneListenerId } from './helpers';
import { BackboneViewContext } from './contexts/BackboneViewContext';
import { ComponentNameContext } from './contexts/ComponentNameContext';
import { BackboneViewType, ReactComponentViewOptions, CallBack } from './ReactBackboneTypes';

const renderReactComponent = ({
  el,
  ReactComponent,
  componentProps,
  backboneView,
  componentName,
}: ReactComponentViewOptions & { el: HTMLElement }) => {
  ReactDom.render(
    <BackboneViewContext.Provider value={backboneView}>
      <ComponentNameContext.Provider value={componentName}>
        <ReactComponent {...componentProps} />
      </ComponentNameContext.Provider>
    </BackboneViewContext.Provider>,
    el
  );
};

const destroyReactComponent = (el: HTMLElement) => {
  ReactDom.unmountComponentAtNode(el);
};

export function destroyReactComponentView(viewId: string) {
  // clean up listeners
  this.reactListeners.forEach(
    ({ listenerId, callBack }: { listenerId: string; callBack: CallBack }) => {
      this.off(listenerId, callBack);
    }
  );

  // unmount react component
  if (viewId) this.getChildView(viewId).destroy();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ReactBackbone(BaseView: BackboneViewType<unknown>) {
  const Component = window.Marionette.View.extend({
    initialize(options: ReactComponentViewOptions) {
      const { el } = this;
      this.options = options;
      renderReactComponent({ ...options, el });
    },

    destroy() {
      destroyReactComponent(this.el);
    },
  });

  const ReactComponentView = BaseView.extend({
    constructor(...args: any) {
      this.reactListeners = [];
      BaseView.apply(this, args);
    },

    serializeData() {
      return extend(
        {},
        {
          ReactComponent: this.ReactComponent,
        }
      );
    },

    onReactComponentStateChange(
      componentName: ReactComponentViewOptions['componentName'],
      callBack: CallBack
    ) {
      const listenerId = getStateListenerId(componentName);
      this.reactListeners.push({ listenerId, callBack });
      this.on(listenerId, callBack);
    },

    setReactComponentState(
      componentName: ReactComponentViewOptions['componentName'],
      state: unknown
    ) {
      this.trigger(getBackboneListenerId(componentName), state);
    },

    ReactComponent(
      componentName: ReactComponentViewOptions['componentName'],
      props: ReactComponentViewOptions['componentProps'] = {}
    ) {
      const viewId = props.viewId ? props.viewId : nanoid();

      const ReactComponent = window.ReactComponents[componentName];

      if (!isFunction(ReactComponent)) {
        const error = `Component '${componentName}' could not be found.`;
        console.error(error);
        throw new Error(error);
      }

      /* eslint no-underscore-dangle: 0 */
      const view = this._view;

      const options = {
        ReactComponent,
        componentProps: props,
        backboneView: view,
        componentName,
      };

      const onRender = () => {
        if (view.$(`#${viewId}`).length) {
          const regionOptions = {} as any;
          regionOptions[viewId] = {
            el: view.$(`#${viewId}`),
            regionClass: window.Marionette.ReplaceRegion,
          };
          view.addRegions(regionOptions);
          view[viewId].show(new Component(options));
        }
      };

      const onDestroy = () => {
        view.off('render', onRender);
      };

      view.once('render', onRender);
      view.once('destroy', onDestroy);

      return `<div id="${viewId}"></div>`;
    },

    destroy() {
      const destroyView = destroyReactComponentView.bind(this);
      destroyView();
    },
  });

  return ReactComponentView;
}
