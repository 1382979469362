import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MoreHoriz } from '@mui/icons-material';
import { Link } from '../../atoms/Link/Link';
import { Chip } from '../../atoms/Chip/Chip';
import { colors } from '../../../theme';
import { JiraIssue } from '../../../types/jira';
import { Card } from '../../atoms/Card/Card';
import { CardHeader } from '../../atoms/Card/CardHeader';
import { IconButton } from '../../atoms/Icon/IconButton';
import { IconText } from '../../atoms/Icon/IconText';
import { CardContent } from '../../atoms/Card/CardContent';

export type JiraIssueCardProps = {
  jiraIssue: JiraIssue;
  onRefresh?: (jira_issue_key: string) => void;
  onRemove?: (jira_issue_key: string) => void;
};

export const JiraIssueCard = ({ jiraIssue, onRefresh, onRemove }: JiraIssueCardProps) => {
  const theme = useTheme();

  const [localJiraIssue] = useState(jiraIssue);
  const [jiraCardMenu, setJiraCardMenu] = useState(null);

  const jiraCardMenuOpen = Boolean(jiraCardMenu);
  const onClickJiraCardMenuButton = (event: any) => {
    setJiraCardMenu(event.currentTarget);
  };

  const onClickRefresh = () => {
    setJiraCardMenu(null);
    onRefresh(localJiraIssue.key);
  };

  const onClickRemove = () => {
    setJiraCardMenu(null);
    onRemove(localJiraIssue.key);
  };

  const jiraStatusBackgroundColors = {
    'blue-gray': '#0052cc',
    yellow: '#dfe1e6',
    green: '#36b37e',
  } as { [key: string]: string };

  const jiraStatusTextColors = {
    'blue-gray': '#FFFFFF',
    yellow: '#42526e',
    green: '#FFFFFF',
  } as { [key: string]: string };

  return (
    <Card>
      <CardHeader
        sx={{
          padding: theme.spacing(2),
        }}
        avatar={
          <Box
            sx={{
              display: 'flex',
              alignContent: 'flex-start',
            }}
          >
            <img src={localJiraIssue.issue_type_icon_url} alt="" width={16} height={16} />
            <Link
              sx={{
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: theme.spacing(2),
              }}
              target="_blank"
              href={localJiraIssue.self_link}
            >
              <IconText
                color={colors.blue[500]}
                fontVariant="regularXS"
                alignItems="baseline"
                iconName="external-link-alt"
                iconSide="RIGHT"
                fontStyles={{ marginRight: theme.spacing(2) }}
                text={localJiraIssue.key}
              />
            </Link>
          </Box>
        }
        action={
          <>
            <IconButton
              id="basic-button"
              aria-controls={jiraCardMenuOpen ? 'basic-menu' : undefined}
              aria-expanded={jiraCardMenuOpen ? 'true' : undefined}
              aria-haspopup="true"
              onClick={onClickJiraCardMenuButton}
              sx={{ padding: 0, marginRight: theme.spacing(1) }}
            >
              <MoreHoriz />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={jiraCardMenu}
              open={jiraCardMenuOpen}
              onClose={() => setJiraCardMenu(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => onClickRefresh()}>
                <Box p={theme.spacing(1)} sx={{ width: '100%' }}>
                  <IconText
                    fontVariant="regularXS"
                    iconSide="RIGHT"
                    iconName="sync"
                    text="Refresh data"
                    color={colors.carbon}
                  />
                </Box>
              </MenuItem>
              <MenuItem onClick={() => onClickRemove()}>
                <Box p={theme.spacing(1)} sx={{ width: '100%' }}>
                  <IconText
                    fontVariant="regularXS"
                    iconSide="RIGHT"
                    iconName="trash"
                    text="Remove issue"
                    color={colors.carbon}
                  />
                </Box>
              </MenuItem>
            </Menu>
          </>
        }
      />
      <CardContent
        sx={{
          padding: theme.spacing(0, 2),
          '&:last-child': {
            paddingBottom: theme.spacing(2),
          },
        }}
      >
        <Typography variant="regularS">{localJiraIssue.summary}</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: theme.spacing(6),
            marginTop: theme.spacing(2),
          }}
        >
          <Chip
            sx={{
              background: jiraStatusBackgroundColors[localJiraIssue.status_background_color],
              color: jiraStatusTextColors[localJiraIssue.status_background_color],
              marginRight: theme.spacing(2),
            }}
            label={localJiraIssue.status_name}
          />
          <img width={12} height={12} src={localJiraIssue.priority_icon} alt="" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              height: theme.spacing(4),
              width: theme.spacing(4),
              backgroundColor: colors.smoke,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: localJiraIssue.assignee_avatar
                ? `url('${localJiraIssue.assignee_avatar}')`
                : 'none',
            }}
          />
          <Typography
            marginLeft={theme.spacing(1)}
            variant="regularXXS"
            color={localJiraIssue.assignee_name ? colors.onyx : colors.aluminium}
          >
            {localJiraIssue.assignee_name ? localJiraIssue.assignee_name : 'Unassigned'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
