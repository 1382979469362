import { EntityNames } from '../../../types/state';
import { AppStateInterface, EntitiesState } from '../../config/storeTypes';

/**
 * Transforms an array of entityNames like ['plans', 'organization', ...] in
 * an object like { plans: {...plansState} }
 */
export const reduceEntities = (
  entityNames: EntityNames[],
  state: AppStateInterface,
  stateKey?: keyof EntitiesState
) =>
  entityNames.reduce(
    (prev, current) => ({
      [current as string]: stateKey
        ? // @ts-ignore
          state.entities[stateKey][current as string]
        : // @ts-ignore
          state.entities[current as string],
      ...prev,
    }),
    {}
  );
