import { Organization } from '../../types/organization';
import { OrganizationApi } from './organizationApi';

export const getOrganizations = async (abortSignal?: AbortSignal): Promise<Organization[]> => {
  try {
    const result: Organization[] = await OrganizationApi.getOrganizations({
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
