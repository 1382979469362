import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { allowedStatuses } from '../../../../../state/constants/allowedStatuses';
import { DefaultRichTextInput } from '../Default/DefaultRichTextInput';
import { RichTextInputProps, RichTextInputValue, RichTextEditor } from '../RichTextInputProps';
import { CharCountLabel } from '../../CharCountLabel/CharCountLabel';
import { InputLabel } from '../../InputLabel/InputLabel';
import { InputStateLabel } from '../../InputStateLabel/InputStateLabel';
import { useCharCount } from '../../InputCharCount/useCharCount';

export type CharCountRichTextInputProps = {
  maxLength: number;
  onBlur?: (value: RichTextInputValue, isLengthExceeded?: boolean) => void;
} & Omit<RichTextInputProps, 'onBlur'>;

export const CharCountRichTextInput = ({
  maxLength,
  status = allowedStatuses.IDLE,
  label,
  initialValue,
  onBlur,
  ...otherProps
}: CharCountRichTextInputProps) => {
  const [editor, setEditor] = useState<RichTextEditor>(null);
  const [value, setValue] = useState<RichTextInputValue>(initialValue || { html: '', text: '' });

  const { charCountExceeded, onValueLengthChange } = useCharCount(
    initialValue?.text?.length,
    maxLength
  );

  const isLoading = status === allowedStatuses.LOADING;
  const isSaved = status === allowedStatuses.PERSISTED;
  const isError = status === allowedStatuses.ERROR;

  const onChange = (inputValue: RichTextInputValue) => {
    // quill editor will allways trigger an onChange event once the component is rendered
    setValue({ ...inputValue });
    onValueLengthChange(inputValue?.text?.length);
  };

  const Label = (
    <InputLabel
      sx={{
        position: 'static',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      className={isError && 'Mui-error'}
    >
      {label}
      <Box display="flex">
        <Box mr={(isLoading || isSaved || isError) && 2}>
          <CharCountLabel
            error={isError || charCountExceeded}
            maxLength={maxLength}
            value={editor?.getText() || ''}
          />
        </Box>
        <InputStateLabel status={status} />
      </Box>
    </InputLabel>
  );
  return (
    <DefaultRichTextInput
      {...otherProps}
      onChange={onChange}
      onBlur={(inputValue) => {
        if (onBlur) {
          onBlur(inputValue, charCountExceeded);
        }
      }}
      value={value}
      label={Label}
      status={charCountExceeded ? allowedStatuses.ERROR : status}
      onEditorMount={(quillEditor: RichTextEditor) => setEditor(quillEditor)}
    />
  );
};
