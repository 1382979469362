import { enableFetchMocks } from 'jest-fetch-mock';
import { fetchWithTimeout } from './fetchWithTimeout';

enableFetchMocks();

describe('fetchWithTimeout', () => {
  beforeEach(() => {
    jest.useFakeTimers('legacy'); // Jesse: using legacy fake-timers for now (more info: https://github.com/facebook/jest/issues/11713)
    fetchMock.doMock();
    // jest timers seem to be a bit flaky. so increasing the timeouts for
    // async function only for this test and reverting them back
    jest.setTimeout(10000);
  });

  afterEach(() => {
    jest.useRealTimers();
    fetchMock.resetMocks();
    jest.setTimeout(5000);
  });

  it("throws timeout error when fetch doesn't respond in due time", async () => {
    fetchMock.mockResponse(async () => {
      jest.advanceTimersByTime(1000);
      return '';
    });

    let err: Error;
    try {
      const result = await fetchWithTimeout('https://www.example.com', { timeout: 500 });
      return () => result;
    } catch (e) {
      err = e;
    }
    expect(err.message).toEqual(expect.stringContaining('Request timed out'));
    expect(err.name).toEqual('TimeoutError');
    expect(clearTimeout).toHaveBeenCalledTimes(1);
  });

  it('returns response if within valid timeout', async () => {
    fetchMock.mockResponse(async () => {
      jest.advanceTimersByTime(500);
      return JSON.stringify({});
    });

    expect(await fetchWithTimeout('https://www.example.com', {}, 2000)).toMatchObject({});
    expect(clearTimeout).toHaveBeenCalledTimes(1);
  });
});
