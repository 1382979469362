import { ChargebeePlanId } from '../types/pricing';

export const plansOrder = [
  'smaply-free-eur-m' as ChargebeePlanId,
  'smaply-free-usd-m' as ChargebeePlanId,
  'smaply-basic-eur-m' as ChargebeePlanId,
  'smaply-basic-eur-a' as ChargebeePlanId,
  'smaply-basic-usd-m' as ChargebeePlanId,
  'smaply-basic-usd-a' as ChargebeePlanId,
  'smaply-pro-eur-m' as ChargebeePlanId,
  'smaply-pro-eur-a' as ChargebeePlanId,
  'smaply-pro-usd-m' as ChargebeePlanId,
  'smaply-pro-usd-a' as ChargebeePlanId,
  'smaply-enterprise-eur-m' as ChargebeePlanId,
  'smaply-enterprise-eur-a' as ChargebeePlanId,
  'smaply-enterprise-usd-m' as ChargebeePlanId,
  'smaply-enterprise-usd-a' as ChargebeePlanId,
];
