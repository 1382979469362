import React from 'react';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { Dialog } from '../../atoms/Dialog/Dialog';
import { DialogTitle } from '../../atoms/Dialog/DialogTitle';
import { DialogContent } from '../../atoms/Dialog/DialogContent';
import { DialogActions } from '../../atoms/Dialog/DialogActions';
import { Button } from '../../atoms/Button/Button';
import { Alert } from '../../atoms/Alert/Alert';
import { Icon } from '../../atoms/Icon/Icon';
import { colors } from '../../../theme';

type CancelPlanModalModalProps = {
  handleClose: () => void;
  handleSubmit: (event: React.FormEvent) => void;
  open: boolean;
  billingCycleExpireDate: string;
  isPersisting?: boolean;
};

export const CancelPlanModal = ({
  handleClose,
  handleSubmit,
  open,
  billingCycleExpireDate,
  isPersisting,
}: CancelPlanModalModalProps) => {
  function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    handleSubmit(event);
  }
  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={handleClose}>CANCEL SUBSCRIPTION</DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <Typography variant="boldXXL" color={colors.carbon}>
              Cancel Subscription
            </Typography>
            <Typography variant="regularXS">
              Are you sure you want to cancel your smaply subscription?
            </Typography>
            <Typography variant="boldXS">
              Your account will remain active until the end of your billing cycle
              <br />
              <Typography variant="regularXS">
                ({billingCycleExpireDate}). You can continue using smaply until then.
              </Typography>
            </Typography>
            <Alert severity="info">
              Please download any work prior to cancellation. Afterwards, your account will
              belocked. We’ll keep your data for 6 months in case you want to restart your
              subscription.
            </Alert>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button disabled={isPersisting} onClick={handleClose} color="secondary">
            No, keep subscription
          </Button>
          <Button
            disabled={isPersisting}
            type="submit"
            color="danger"
            endIcon={<Icon iconName="arrow-right" />}
          >
            Yes, cancel subscription
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
