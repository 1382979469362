import { Options } from 'ky';
import { OrganizationId } from '../../types/organization';
import { get } from '../config';

export const OrganizationApi = {
  getGonData: (options: Options) => get('web/v1/gon', options),
  getOrganizations: (options: Options) => get('web/v1/organizations', options),
  getAdditionalFeatures: (organizationId: OrganizationId, options: Options) =>
    get(`web/v1/organizations/${organizationId}/additional_features`, options),
};
