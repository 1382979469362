import React from 'react';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { useTheme, Theme } from '@mui/material/styles';
import { getInitials } from '../../../utils/str/getInitials';
import { colors } from '../../../theme/colors/colors';
import { Tooltip } from '../Tooltip/Tooltip';
import Typography from '@mui/material/Typography';

export type AvatarProps = MuiAvatarProps & {
  name?: string;
  tooltip?: string;
};

export const Avatar = ({ name, tooltip, sx, ...otherProps }: AvatarProps) => {
  const theme: Theme = useTheme();

  if (!name) {
    return <MuiAvatar {...otherProps} />;
  }

  const content = getInitials(name);

  return (
    <MuiAvatar sx={{ '&.MuiAvatar-root': { ...theme.typography.boldXXS }, ...sx }} {...otherProps}>
      <Tooltip
        placement="top"
        title={
          <Typography variant="regularXXS" color={colors.white}>
            {tooltip ? tooltip : content}
          </Typography>
        }
      >
        <span>
          <Typography variant="boldXXS" color={colors.white}>
            {content}
          </Typography>
        </span>
      </Tooltip>
    </MuiAvatar>
  );
};
