import { Options } from 'ky';
import { put } from '../config';

export const journeyMapsApi = {
  put: (organizationId: string, projectId: string, journeyMapId: string, params: Options) =>
    put(
      `web/v1/organizations/${organizationId}/projects/${projectId}/journey_maps/${journeyMapId}`,
      params
    ),
};
