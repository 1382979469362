import { GetState, SetState } from 'zustand/vanilla';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { entityNames } from '../../constants/entityNames';
import { AppStateInterface } from '../../config/storeTypes';
import { getProjectUsers } from '../../../api/users/getUsers';

export const usersSlice = (set: SetState<AppStateInterface>, get: GetState<AppStateInterface>) => ({
  getProjectUsers: async (projectId: string) => {
    const {
      setDenormalizedData,
      setEntityStatus,
      entities: { organization },
    } = get();
    try {
      setEntityStatus(entityNames.USERS, allowedStatuses.LOADING);
      const response = await getProjectUsers(organization.slug, projectId);
      setDenormalizedData(response.users, entityNames.USERS);
      setEntityStatus(entityNames.USERS, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = get();
      addError(error, entityNames.USERS);
      setEntityStatus(entityNames.USERS, allowedStatuses.ERROR);
    }
  },
});
