import { useState } from 'react';

const isCharCountExceeded = (length: number, maxLength: number) => length > maxLength;

export const useCharCount = (defaultValueLength: number, maxLength: number) => {
  const [charCountExceeded, setCharCoundExceeded] = useState<boolean>(
    defaultValueLength ? isCharCountExceeded(defaultValueLength, maxLength) : false
  );

  const onValueLengthChange = (newLength: number) => {
    setCharCoundExceeded(isCharCountExceeded(newLength, maxLength));
  };

  return {
    charCountExceeded,
    onValueLengthChange,
  };
};
