import React from 'react';
import { Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { colors } from '../../../../theme';

export type CharCountLabelProps = {
  error: boolean;
  maxLength: number;
  value: string;
};

export const CharCountLabel = ({ error, maxLength, value }: CharCountLabelProps) => {
  const theme = useTheme<Theme>();
  return (
    <Typography
      variant="regularXXS"
      color={error ? theme.palette.error.main : colors.aluminium}
      sx={{ textTransform: 'initial' }}
    >
      {`Content limited to ${maxLength} characters. ${value.length}/${maxLength}`}
    </Typography>
  );
};
