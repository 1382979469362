import { JourneyMapUserAssociationType } from '../../types/user';
import { UserAssociationApi } from './userAssociationApi';

export const createJourneyMapUserAssociation = async (
  organizationId: string,
  projectId: string,
  journeyMapId: string,
  userId: string
) => {
  if (!organizationId) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }

  if (!journeyMapId) {
    const error = 'smaply-ui: missing journey map id';
    console.error(error);
    throw new Error(error);
  }

  if (!userId) {
    const error = 'smaply-ui: missing user id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: JourneyMapUserAssociationType = await UserAssociationApi.post(
      organizationId,
      projectId,
      journeyMapId,
      userId
    ).json();
    return result;
  } catch (error) {
    console.error(error);
    const serverErrors = await error.json();
    throw serverErrors;
  }
};
