import { default as excel } from '../images/brands/excel.svg';
import { default as lucidspark } from '../images/brands/lucidspark.svg';
import { default as miro } from '../images/brands/miro.svg';
import { default as mural } from '../images/brands/mural.svg';

export const wallsyncIntegrations = [
  {
    name: 'miro',
    title: 'Miro',
    image: miro,
    description: 'Import your journey map from Miro dashboard',
    featureId: 'capture_miro',
  },
  {
    name: 'excel',
    title: 'Excel',
    image: excel,
    description: 'Import your journey map made in XLS file.',
    featureId: 'capture_excel',
  },
  {
    name: 'image',
    title: 'Photo',
    image: '/images/journey_map/journey_map_photo.png',
    description: 'Import your workshop photo, image or screenshot to digitaze your journey map.',
    featureId: 'capture_photo',
  }
];
