import { JourneyMapExample } from '../../types/journeyMap';
import { JourneyMapApi } from './journeyMapApi';

export const getJourneyMapExamples = async (
  organizationId: string,
  projectId: string
): Promise<Array<JourneyMapExample>> => {
  if (!organizationId) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }
  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: JourneyMapExample[] = await JourneyMapApi.getExamples(
      organizationId,
      projectId
    ).json();

    return result || [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};
