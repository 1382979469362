import React, { useEffect, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useTheme, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { getStaticState } from '../../../state/helpers/getStaticState';
import { useEntities } from '../../../state/hooks/useEntities';
import { entityNames } from '../../../state/constants/entityNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '../../atoms/Alert/Alert';
import { ButtonBase } from '../../atoms/ButtonBase/ButtonBase';
import { Dialog } from '../../atoms/Dialog/Dialog';
import { DialogContent } from '../../atoms/Dialog/DialogContent';
import { DialogActions } from '../../atoms/Dialog/DialogActions';
import { DialogTitle } from '../../atoms/Dialog/DialogTitle';
import { Input } from '../../atoms/Inputs/Input/Input';
import { Button } from '../../atoms/Button/Button';
import { Icon } from '../../atoms/Icon/Icon';
import { JiraIssuesList } from './JiraIssuesList';
import { colors } from '../../../theme';
import { useAbortSignal } from '../../../utils/hooks/useAbortSignal';
import { useJiraIssues } from './hooks/useJiraIssues';

export interface JiraIssuesDialogProps {
  open: boolean;
  onSubmit: (jiraSelectedIssueKeys: string[]) => void;
}

export const JiraIssuesDialog = ({ open, onSubmit }: JiraIssuesDialogProps) => {
  const { getJiraIntegration, clearSelectedJiraIssues } = getStaticState();

  const theme: Theme = useTheme();

  const {
    entities: { organization, jiraIntegration, jiraSelectedIssueKeys },
    isLoading,
  } = useEntities(
    entityNames.ORGANIZATION,
    entityNames.JIRA_INTEGRATION,
    entityNames.JIRA_SELECTED_ISSUE_KEYS
  );

  const [searchTerm, setSearchTerm] = useState('');

  const [jiraIssues, isLoadingJiraIssues] = useJiraIssues(organization.slug, searchTerm);

  const [dialogOpen, setDialogOpen] = useState(open);

  const abortSignal = useAbortSignal();
  useEffect(() => {
    setDialogOpen(open);
    getJiraIntegration(abortSignal);
  }, [jiraIssues, open, getJiraIntegration, abortSignal]);

  const onSearchTermChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const debouncedOnSearchTermChange = useMemo(() => debounce(onSearchTermChange, 300), []);

  const closeDialog = () => {
    clearSelectedJiraIssues();
    setDialogOpen(false);
  };

  const onClickSubmit = () => {
    onSubmit(jiraSelectedIssueKeys);
    closeDialog();
  };

  const goToJiraSettings = () => {
    setDialogOpen(false);
    window.open(`/#${organization.slug}/jira`).focus();
  };

  return (
    <Dialog
      open={dialogOpen}
      fullWidth
      maxWidth="sm"
      onClose={() => closeDialog()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" onClose={() => closeDialog()}>
        Jira Lane Manager
      </DialogTitle>
      <DialogContent>
        <Typography variant="boldXL" display="flex" pb={theme.spacing(4)}>
          Link Jira issue(s)
        </Typography>
        {!isLoading && !jiraIntegration.integrated && (
          <Alert
            severity="warning"
            action={
              <ButtonBase onClick={goToJiraSettings}>
                <Typography color={colors.yellow[500]} variant="boldXS">
                  Go to Jira settings
                </Typography>
              </ButtonBase>
            }
          >
            There is no connection with Jira yet. Go to the settings page to connect with Jira.
          </Alert>
        )}
        <Input
          id="search-jira-issues"
          placeholder="Search by key or summary..."
          onChange={debouncedOnSearchTermChange}
          disabled={!jiraIntegration.integrated}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon color="primary" iconPrefix="fal" iconName="search" />
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            marginTop: theme.spacing(6),
            height: theme.spacing(5 * (14 + 0.5)),
            overflow: 'inherit',
          }}
        >
          {searchTerm !== '' && jiraIssues.length === 0 && !isLoadingJiraIssues && (
            <Typography display="flex" sx={{ justifyContent: 'center' }}>
              No issues found
            </Typography>
          )}
          {searchTerm !== '' && jiraIssues.length > 0 && (
            <Box sx={{ maxHeight: theme.spacing(54), overflowY: 'scroll' }}>
              <JiraIssuesList jiraIssues={jiraIssues} />
            </Box>
          )}
          {searchTerm !== '' && isLoadingJiraIssues && (
            <Box sx={{ display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center' }}>
              <FontAwesomeIcon icon="spinner" size="1x" spin />
              <Typography mt={theme.spacing(2)}>
                Search in progress. This might take a few seconds.
              </Typography>
            </Box>
          )}
          {jiraIntegration.integrated && searchTerm === '' && !isLoadingJiraIssues && (
            <Box sx={{ display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center' }}>
              <FontAwesomeIcon icon="info-circle" size="2x" />
              <Typography mt={theme.spacing(2)}>
                Search by key or summary to find Jira issues
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          onClick={onClickSubmit}
          endIcon={<Icon iconName="arrow-right" />}
          disabled={jiraSelectedIssueKeys.length === 0}
        >
          Link issue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
