import { useEffect, useState } from 'react';
import { getProjectTagAssociations } from '../../../../../api/project-tags-association/getProjectTagAssociations';
import { TagAssociation } from '../../../../../types/tags';
import { useAbortSignal } from '../../../../../utils/hooks/useAbortSignal';

export const useGetTagAssociations = (organizationId: string, projectId: string) => {
  const abortSignal: AbortSignal = useAbortSignal();

  const [tagAssociationsList, setTagAssociations]: [
    TagAssociation[],
    React.Dispatch<React.SetStateAction<TagAssociation[]>>
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: Array<TagAssociation> = await getProjectTagAssociations(
          organizationId,
          projectId,
          abortSignal
        );
        setTagAssociations(data);
      } catch (e) {
        setTagAssociations([]);
        setError(e);

        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [organizationId, projectId, abortSignal]);

  return [tagAssociationsList, isLoading, error];
};
