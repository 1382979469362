import { useRef } from 'react';

export const useScrollToElement = () => {
  const ref = useRef(null);

  function scrollToElement() {
    ref.current.scrollIntoView({
      behavior: 'smooth',
    });
  }

  return {
    ref,
    scrollToElement,
  };
};
