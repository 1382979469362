import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  wrapperClass?: string;
  labelClass?: string;
  textClass?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    id = '',
    type = 'text',
    label = 'label',
    wrapperClass = '',
    labelClass = '',
    textClass = '',
    className = '',
    ...other
  } = props;

  return (
    <div className={`input-wrapper ${wrapperClass}`}>
      <label htmlFor={id} className={`input-label ${labelClass}`}>
        <span className={`input-text ${textClass}`}>
          { label }
        </span>
        <input
          type={type}
          ref={ref}
          id={id}
          className={`input ${className}`}
          {...other}
        />
      </label>
    </div>
  );
});

export default Input;
