import React from 'react';
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { colors } from '../../../theme';
import { Icon } from '../Icon/Icon';

const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    backgroundColor: colors.smoke,

    '&.Mui-expanded': {
      minHeight: theme.spacing(9),
    },
  },
  '& .MuiAccordionSummary-root': {
    width: '100%',
    flexDirection: 'row-reverse',
    padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
    minHeight: theme.spacing(9),
    '& .MuiAccordionSummary-content': {
      margin: `0 0 0 ${theme.spacing(2)}`,
      justifyContent: 'space-between',
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: `0 ${theme.spacing(6)} ${theme.spacing(5)} ${theme.spacing(6)} `,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    fontSize: theme.typography.pxToRem(13),
    color: colors.iron,
  },
}));

export type AccordionProps = {
  header: React.ReactNode;
  children: React.ReactNode;
} & Partial<MuiAccordionProps>;

export const Accordion = ({ header, children, ...muiAccordionProps }: AccordionProps) => (
  <StyledAccordion {...muiAccordionProps} square elevation={0}>
    <AccordionSummary
      expandIcon={<Icon fontVariant="boldS" iconName="chevron-down" color="inherit" />}
    >
      {header}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </StyledAccordion>
);
