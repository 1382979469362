import React from 'react';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { colors } from '../../../../theme';
import { billingFrequencies } from '../../../../constants/billingFrequencies';
import { PeriodUnit } from '../../../../types/pricing';
import { Switch } from '../../../atoms/Switch/Switch';

export type PlanBillingFrequencySwitchProps = {
  onSwitch: (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  period: PeriodUnit;
};

export const PlanBillingFrequencySwitch = ({
  onSwitch,
  period,
}: PlanBillingFrequencySwitchProps) => {
  const {
    entities: { currentPlan },
  } = useEntities(entityNames.CURRENT_PLAN);

  if (currentPlan.is_yearly_plan) {
    return null;
  }

  if (!currentPlan.period_unit) {
    return null;
  }

  const checked = period === billingFrequencies.year;

  return (
    <Box maxWidth={200} p={2}>
      <Typography variant="regularXXS" color={colors.green[500]}>
        You save 12% per year if you switch to annual payment
      </Typography>
      <Stack direction="row" sx={{ my: 3 }} spacing={1} alignItems="center">
        <Typography color={checked ? colors.iron : colors.onyx} variant="regularXXS">
          Monthly
        </Typography>
        <Switch
          disableRipple
          onChange={onSwitch}
          inputProps={{ 'aria-label': 'Switch billing period' }}
          size="small"
          checked={checked}
        />
        <Typography color={checked ? colors.onyx : colors.iron} variant="regularXXS">
          Annually
        </Typography>
      </Stack>
    </Box>
  );
};
