import React, { useEffect, useState } from 'react';
import values from 'lodash/values';
import { useEntities } from '../../../../state/hooks/useEntities';
import { getStaticState } from '../../../../state/helpers/getStaticState';
import { allowedStatuses } from '../../../../state/constants/allowedStatuses';
import { entityNames } from '../../../../state/constants/entityNames';
import { combineStatuses } from '../../../../state/helpers/combineStatuses';
import { tagAssociationEntities } from '../../../../state/constants/tagAssociationEntities';
import { sortAlphabetically } from '../../../../utils/sorting/sortingHelper';
import { Chip } from '../../../atoms/Chip/Chip';
import { TagsOption } from '../TagsOption';
import { TagsDialogs } from '../TagsDialogs';
import { Tag } from '../../../../types/tags';
import { Input } from '../../../atoms/Inputs/Input/Input';
import { MultiSelectInput } from '../../../atoms/Inputs/MultiSelectInput/MultiSelectInput';

export type JourneyMapsTagInputProps = {
  projectId: string;
};

export const JourneyMapsTagInput = ({ projectId }: JourneyMapsTagInputProps) => {
  const {
    getJourneyMapTagAssociations,
    createJourneyMapTagAssociation,
    deleteJourneyMapTagAssociation,
    getTags,
    createTag,
    removeAllErrors,
    entities: { journeyMap },
  } = getStaticState();
  const {
    entities: { tags, journeyMapTagAssociations },
    isLoading,
    statuses,
  } = useEntities(entityNames.TAGS, entityNames.JOURNEY_MAP_TAG_ASSOCIATIONS);
  useEffect(() => {
    getTags();
    getJourneyMapTagAssociations(projectId, journeyMap.slug);
    // eslint-disable-next-line
  }, [getJourneyMapTagAssociations, getTags, projectId]);

  const tagsArray = sortAlphabetically(values(tags), 'name') as Tag[];
  const tagAssociationsArray = values(journeyMapTagAssociations);
  const associatedTagIds = tagAssociationsArray.map(({ tag_id }) => tag_id);
  const selectedTags = tagsArray.filter(({ id }) => associatedTagIds.includes(id));

  const [editing, setEditing] = useState<Tag | null>(null);
  const [deleting, setDeleting] = useState<Tag | null>(null);

  if (isLoading) {
    return <Input label="journey map tags" status={allowedStatuses.LOADING} />;
  }

  return (
    <>
      <TagsDialogs
        editing={editing}
        deleting={deleting}
        onEditingChange={(tagName: string) => {
          setEditing((prev) => ({ ...prev, name: tagName }));
        }}
        onCloseEditing={() => setEditing(null)}
        onCloseDeleting={() => setDeleting(null)}
      />
      <MultiSelectInput
        onOptionSelect={(option: Tag) => {
          if (statuses.tags === allowedStatuses.ERROR) {
            removeAllErrors(entityNames.TAGS);
          }
          createJourneyMapTagAssociation(projectId, journeyMap.slug, String(option.id));
        }}
        onOptionDeSelect={(option: Tag) => {
          if (statuses.tags === allowedStatuses.ERROR) {
            removeAllErrors(entityNames.TAGS);
          }
          const tagAssociation = tagAssociationsArray.find(({ tag_id }) => tag_id === option.id);
          deleteJourneyMapTagAssociation(projectId, journeyMap.slug, String(tagAssociation.id));
        }}
        onOptionCreate={(tagName: string) => {
          createTag(tagName, tagAssociationEntities.journeyMap, projectId, journeyMap.slug);
        }}
        availableOptions={tagsArray}
        selectedOptions={selectedTags}
        labelKey="name"
        idKey="id"
        renderInput={({ InputProps, InputLabelProps, ...params }) => (
          <Input
            status={combineStatuses(statuses)}
            onKeyDown={(event) => {
              if (event.key === 'Backspace') {
                event.stopPropagation();
              }
            }}
            {...params}
            InputLabelProps={{ ...InputLabelProps, shrink: true }}
            InputProps={{ ...InputProps, disableUnderline: true }}
            label="journey map tags"
          />
        )}
        renderOption={(props, option) => {
          return (
            <TagsOption
              {...props}
              option={option}
              handleDeleteClick={() => {
                setDeleting(option);
              }}
              handleEditClick={() => {
                setEditing(option);
              }}
            />
          );
        }}
        renderTag={(option, index, onDelete) => (
          <Chip style={{ marginRight: 4 }} label={option.name} onDelete={onDelete} />
        )}
        itemNotFoundTip={(inputValue: string) =>
          `No tag was found. Press enter to create new tag "${inputValue}"`
        }
        onKeyDown={() => {
          if (statuses.tags === allowedStatuses.ERROR) {
            removeAllErrors(entityNames.TAGS);
          }
        }}
      />
    </>
  );
};
