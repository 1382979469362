import { Options } from 'ky';
import { environment } from '../../utils/environment';
import { get, SMAPLY_API_URLS } from '../config';

export const UsersApi = {
  getUser: (options: Options) => get('web/v1/user', options),
  getJWT: () => get(SMAPLY_API_URLS[environment] == null ? '/users/jwt' : 'users/jwt'),
  getOrganizationUsers: (options: Options) => get('web/v1/organizations_users', options),
  getProjectUsers: (organizationId: string, projectId: string, options?: Options) =>
    get(`web/v1/organizations/${organizationId}/projects/${projectId}/users`, options),
  getUserRoles: (options: Options) => get('web/v1/roles', options),
};
