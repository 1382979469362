import React from 'react';
import {
  CreateJourneyMap as SUICreateJourneyMap,
  CreateJourneyMapProps
} from  './src/components/templates/CreateJourneyMap/CreateJourneyMap';
import { K2Theme } from './src/theme/material/k2';
import { ThemeProvider } from '@mui/material';

export function CreateJourneyMap(props: CreateJourneyMapProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUICreateJourneyMap {...props} />
    </ThemeProvider>
  );
}
