import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, Theme } from '@mui/material/styles';
import {
  billingHasPlanPrice,
  isBillingScheduledForCancelation,
} from '../../../../business-logic/billingInformation';
import { colors } from '../../../../theme';
import { PlanCard } from '../../../molecules/Plan/PlanCard';
import { PlansTable } from '../../../molecules/Plan/PlansTable/PlansTable';
import { PlanTable } from '../../../molecules/Plan/PlanTable';
import { TrialEnded, InTrial, ScheduledCancel, Locked } from '../../../molecules/Plan/PlanAlerts';
import { getStaticState } from '../../../../state/helpers/getStaticState';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import { useAbortSignal } from '../../../../utils/hooks/useAbortSignal';
import { Plan } from '../../../../types/pricing';
import { userRoles } from '../../../../constants/userRoles';
import { getPlanValidation } from '../../../../api/plans/getPlanValidation';
import {
  subscriptionIsInTrial,
  subscriptionIsInTrialEnded,
  subscriptionLocked,
} from '../../../../business-logic/subscription';
import { LinearProgress } from '../../../atoms/LinearProgress/LinearProgress';
import { formatUnixDate } from '../../../../utils/dates';

export type PlanTemplateProps = {
  adminName: string;
  adminEmail: string;
  handleSelectPlan: (plan: Partial<Plan>, reasons: any) => void;
  handleOnClickOpenChargebeePortal: () => Promise<void>;
  handleRedirectToInvoices: () => Promise<void>;
};

export const PlanTemplate = ({
  adminName,
  adminEmail,
  handleSelectPlan,
  handleOnClickOpenChargebeePortal,
  handleRedirectToInvoices,
}: PlanTemplateProps) => {
  const { getPlans, getBillingInformation, getCurrentPlan, getSubscription } = getStaticState();
  const {
    entities: { billingInformation, subscription, organization },
  } = useEntities(
    entityNames.BILLING_INFORMATION,
    entityNames.SUBSCRIPTION,
    entityNames.ORGANIZATION
  );

  const theme: Theme = useTheme();
  const [isPersisting, setIsPersisting] = useState<boolean>(false);

  const userIsAdmin = Boolean(organization.role === userRoles.ADMIN);

  const abortSignal = useAbortSignal();

  useEffect(() => {
    getCurrentPlan(abortSignal);
    getBillingInformation(abortSignal);
    getPlans(abortSignal);
    getSubscription(abortSignal);
  }, [getPlans, getBillingInformation, getCurrentPlan, getSubscription, abortSignal]);

  async function onClickSelectPlan(plan: Plan) {
    setIsPersisting(true);
    try {
      const response = await getPlanValidation(organization.slug, plan.chargebee_id);
      handleSelectPlan(plan, response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsPersisting(false);
    }
  }

  async function handleSmaplyCallback(smaplyCallback: () => Promise<void>) {
    setIsPersisting(true);
    try {
      await smaplyCallback();
    } catch (error) {
      console.error(error);
    } finally {
      setIsPersisting(false);
    }
  }

  return (
    <Box position="relative">
      {isPersisting && (
        <Box position="absolute" top={0} left={0} width="100%" zIndex={theme.zIndex.snackbar}>
          <LinearProgress />
        </Box>
      )}
      <Box padding={theme.spacing(10)}>
        <Box maxWidth={1000}>
          <Box mb={9}>
            <Typography color={colors.iron} variant="boldM" mr={2}>
              MANAGE SUBSCRIPTION FOR ORGANIZATION
            </Typography>
            <Typography color={colors.onyx} variant="regularM">
              {organization.name}
            </Typography>
          </Box>

          {subscriptionIsInTrial(subscription) && <InTrial userIsAdmin={userIsAdmin} />}
          {subscriptionIsInTrialEnded(subscription) && (
            <TrialEnded userIsAdmin={userIsAdmin} adminName={adminName} adminEmail={adminEmail} />
          )}
          {subscriptionLocked(subscription) && (
            <Locked
              userIsAdmin={userIsAdmin}
              scheduledDate={formatUnixDate(subscription.changes_scheduled_at)}
              onCTAClick={() => handleSmaplyCallback(handleOnClickOpenChargebeePortal)}
            />
          )}

          {isBillingScheduledForCancelation(billingInformation) && (
            <ScheduledCancel
              userIsAdmin={userIsAdmin}
              scheduledDate={formatUnixDate(subscription.cancelled_at)}
              onClickOpenChargebeePortal={() =>
                handleSmaplyCallback(handleOnClickOpenChargebeePortal)
              }
            />
          )}
          <Box display="flex" alignContent="stretch" mt={9}>
            <Box minWidth={354}>
              <PlanCard
                actions={[
                  {
                    name: 'UPDATE ACCOUNT AND PAYMENT INFO',
                    onClick: () => handleSmaplyCallback(handleOnClickOpenChargebeePortal),
                  },
                  {
                    name: 'CANCEL SUBSCRIPTION',
                    onClick: () => handleSmaplyCallback(handleOnClickOpenChargebeePortal),
                  },
                ]}
              />
            </Box>
            {billingHasPlanPrice(billingInformation) &&
              userIsAdmin &&
              !subscriptionIsInTrial(subscription) && (
                <Box flex={1} ml={6}>
                  <PlanTable handleRedirectToInvoices={handleRedirectToInvoices} />
                </Box>
              )}
          </Box>
          <Box mt={10}>
            <PlansTable
              handleCancelSubscription={() =>
                handleSmaplyCallback(handleOnClickOpenChargebeePortal)
              }
              // eslint-disable-next-line react/jsx-no-bind
              handleSelectPlan={onClickSelectPlan}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
