import { AdditionalFeatureType } from '../../types/subscription';
import { OrganizationApi } from './organizationApi';

export const getAdditionalFeatures = async (
  organizationId: string,
  abortSignal?: AbortSignal
): Promise<AdditionalFeatureType[]> => {
  try {
    const result: AdditionalFeatureType[] = await OrganizationApi.getAdditionalFeatures(
      organizationId,
      {
        signal: abortSignal,
      }
    ).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
