import React from 'react';
import MuiButtonBase, { ButtonBaseProps as MuiButtonBaseProps } from '@mui/material/ButtonBase';

export interface ButtonBaseProps extends MuiButtonBaseProps {
  href?: string;
}

export const ButtonBase = (props: ButtonBaseProps) => {
  return <MuiButtonBase {...props} />;
};
