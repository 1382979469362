import { useContext, useEffect } from 'react';
import { BackboneViewContext } from '../contexts/BackboneViewContext';
import { BackboneViewType } from '../ReactBackboneTypes';

export const useBackboneView = (backboneView: BackboneViewType<unknown>, domSelector: string) => {
  const parentBackboneView = useContext(BackboneViewContext);
  const regionName = `react-component-${domSelector}`;

  useEffect(() => {
    parentBackboneView.addRegion(regionName, domSelector);
    parentBackboneView.getRegion(regionName).show(backboneView);

    return function cleanup() {
      backboneView.destroy();
    };
  }, [backboneView, domSelector, parentBackboneView, regionName]);
};
