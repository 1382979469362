import React from 'react';
import Box from '@mui/material/Box';
import MuiBreadcrumbs, { BreadcrumbsProps as MuiBreadcrumbsProps } from '@mui/material/Breadcrumbs';

export type BreadcrumbsProps = MuiBreadcrumbsProps;

export const Breadcrumbs = ({ children, ...rest }: BreadcrumbsProps) => {
  return (
    <Box role="presentation">
      <MuiBreadcrumbs aria-label="breadcrumb" {...rest}>
        {children}
      </MuiBreadcrumbs>
    </Box>
  );
};
