import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEntities } from '../../../state/hooks/useEntities';
import { entityNames } from '../../../state/constants/entityNames';
import { colors } from '../../../theme';
import { Accordion } from '../../atoms/Accordion/Accordion';
import { MetadataBarContent, MetadataBarContentProps } from './MetadataBarContent';
import { formatStringDate } from '../../../utils/dates';

const MetadataBarUpdatedAt = () => {
  const {
    entities: { journeyMap },
  } = useEntities(entityNames.JOURNEY_MAP);
  // eslint-disable-next-line
  const { updated_at } = journeyMap;
  return (
    <Typography variant="regularXXS" ml={1} color={colors.aluminium}>
      {formatStringDate(updated_at)}
    </Typography>
  );
};

export const MetadataBar = ({ projectId }: MetadataBarContentProps) => {
  return (
    <Accordion
      header={
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography lineHeight="unset" component="div" variant="boldXS" color={colors.iron}>
            JOURNEY INFO
          </Typography>
          <Box>
            <Typography variant="boldXXS" color={colors.aluminium}>
              JOURNEY MAP UPDATED ON
            </Typography>
            <MetadataBarUpdatedAt />
          </Box>
        </Box>
      }
    >
      <MetadataBarContent projectId={projectId} />
    </Accordion>
  );
};
