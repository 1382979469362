import React, { useState } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { contentIfBlocked } from '../../../constants/additionalFeatures';
import isEmpty from 'lodash/isEmpty';
import { colors } from '../../../theme';
import { Button } from '../../atoms/Button/Button';
import { List } from '../../atoms/List/List';
import { CardMedia } from '../../atoms/Card/CardMedia';
import { Dialog } from '../../atoms/Dialog/Dialog';
import { DialogTitle } from '../../atoms/Dialog/DialogTitle';
import { DialogContent } from '../../atoms/Dialog/DialogContent';
import { BaseBlockedFeatureBoxProps } from './BlockedFeatureBox';

const BaseBlockedFeatureDialog = ({
  title,
  description,
  listItems,
  image,
  onCTAClick,
}: BaseBlockedFeatureBoxProps) => {
  const theme: Theme = useTheme();

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
      <DialogTitle onClose={handleClose}>LIMIT REACHED</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Box maxWidth={350}>
          {!isEmpty(title) && (
            <Typography display="flex" variant="regularM" color={colors.black}>
              {title}
            </Typography>
          )}
          {!isEmpty(description) && (
            <Typography
              display="flex"
              variant="regularXS"
              color={colors.carbon}
              mt={theme.spacing(4)}
            >
              {description}
            </Typography>
          )}
          {listItems && listItems.length > 0 && (
            <Box mt={theme.spacing(4)}>
              <List items={listItems} />
            </Box>
          )}
          <Box mt={theme.spacing(4)}>
            <Button fullWidth color="primary" onClick={onCTAClick}>
              Upgrade plan
            </Button>
          </Box>
        </Box>
        {image && (
          <Box ml={theme.spacing(6)} width="50%">
            <CardMedia component="img" image={image} alt={title} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export type BlockedFeatureDialogProps = {
  feature: keyof typeof contentIfBlocked;
  onCTAClick?: () => void;
};

export const BlockedFeatureDialog = ({ feature, onCTAClick }: BlockedFeatureDialogProps) => {
  const content = contentIfBlocked[feature];

  return (
    content && (
      <BaseBlockedFeatureDialog
        title={content.title}
        description={content.description}
        listItems={content.listItems}
        image={content.image}
        onCTAClick={onCTAClick}
      />
    )
  );
};
