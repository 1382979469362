import React from 'react';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { useTheme, Theme } from '@mui/material/styles';
import { Icon } from '../Icon/Icon';
import { colors, palette } from '../../../theme';

export type AlertProps = { icon?: React.ReactElement } & MuiAlertProps;

export const Alert = ({ children, severity, icon, ...otherProps }: AlertProps) => {
  const theme: Theme = useTheme();
  const buttonFont = theme.typography.boldXS as Record<string, string>;
  return (
    <MuiAlert
      variant="filled"
      severity={severity}
      sx={{
        backgroundColor: colors[palette[severity]][200],
        padding: 0,
        marginBottom: theme.spacing(4),
        '& .MuiAlert-icon': {
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          padding: theme.spacing(3),
          backgroundColor: colors[palette[severity]][300],
          '> svg': {
            width: theme.spacing(5),
          },
        },
        '& .MuiAlert-message': {
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          margin: 0,
          padding: theme.spacing(3),
        },
        '& .MuiAlert-action': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          padding: 0,
          '& .MuiButtonBase-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(3),
            ...buttonFont,
            color: colors[palette[severity]][500],
          },
        },
      }}
      iconMapping={{
        info: icon || <Icon color={colors[palette[severity]][500]} iconName="info-circle" />,
        success: icon || <Icon color={colors[palette[severity]][500]} iconName="check" />,
        warning: icon || (
          <Icon color={colors[palette[severity]][500]} iconName="exclamation-triangle" />
        ),
        error: icon || <Icon color={colors[palette[severity]][500]} iconName="times" />,
      }}
      {...otherProps}
    >
      <Typography color={colors.onyx} variant="regularXS">
        {children}
      </Typography>
    </MuiAlert>
  );
};
