import { useContext, useEffect } from 'react';
import { BackboneViewContext } from '../contexts/BackboneViewContext';
import { ComponentNameContext } from '../contexts/ComponentNameContext';
import { getBackboneListenerId, getStateListenerId } from '../helpers';

export function useBackbone<T>(state: T, onStateChange: (state: T) => void): void {
  const backboneView = useContext(BackboneViewContext);
  const componentName = useContext<string>(ComponentNameContext);

  if (!backboneView) {
    const error = 'backboneViewContext should contain an instance of Marionette.View';
    console.error(error);
    throw new Error(error);
  }

  // every time the component state changes we tell backbone about it
  useEffect(() => {
    const triggerName = getStateListenerId(componentName);
    backboneView.trigger(triggerName, state);
  }, [backboneView, componentName, state]);

  // every time backbone changes the same piece of state we tell react about it
  useEffect(() => {
    const triggerName = getBackboneListenerId(componentName);
    backboneView.on(triggerName, onStateChange);

    return function cleanup() {
      backboneView.off(backboneView, triggerName, onStateChange);
    };
  }, [backboneView, componentName, onStateChange]);
}
