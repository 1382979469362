import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { journeyMap as journeyMapMock } from '../../mocks/journeyMap';
import { journeyMapsApi } from './journeyMapsApi';
import { JourneyMap } from '../../types/journeyMap';

export const updateJourneyMap = async (
  organizationId: string,
  projectId: string,
  journeyMapId: string,
  journeyMap: Partial<JourneyMap>
) => {
  if (!organizationId) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }
  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }
  if (!journeyMapId) {
    const error = 'smaply-ui: missing journey map id';
    console.error(error);
    throw new Error(error);
  }

  if (shouldMockSmaplyAPI) {
    return journeyMapMock;
  }

  try {
    const result: JourneyMap = await journeyMapsApi
      .put(organizationId, projectId, journeyMapId, {
        json: journeyMap,
      })
      .json();
    return result;
  } catch (error) {
    console.error(error);
    if (typeof window.showErrorFlashMessage === 'function') window.showErrorFlashMessage(error);
    throw error;
  }
};
