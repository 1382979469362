import { sortAlphabetically } from './sortingHelper';

describe('sortingHelper', () => {
  describe('sortAlphabetically', () => {
    it('sortAlphabetically sorts array of objects in alphabetical order', () => {
      const tags = [{ name: 'John' }, { name: 'Caron' }, { name: 'Amanda' }];
      const sorted = sortAlphabetically(tags, 'name');
      expect(sorted).toEqual([{ name: 'Amanda' }, { name: 'Caron' }, { name: 'John' }]);
    });
  });
});
