import { GetState, SetState } from 'zustand/vanilla';
import { default as produce } from 'immer';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { entityNames } from '../../constants/entityNames';
import { statusesMethods } from '../statuses/statusesSelector';
import { AppStateInterface } from '../../config/storeTypes';
import { errorsMethods } from '../errors/errorsSelectors';
import { AdditionalFeatureType } from '../../../types/subscription';
import { getAdditionalFeatures } from '../../../api/organization/getAdditionalFeatures';

export const additionalFeaturesSlice = (
  set: SetState<AppStateInterface>,
  get: GetState<AppStateInterface>
) => ({
  getAdditionalFeatures: async (abortSignal?: AbortSignal) => {
    const entityName = entityNames.ADDITIONAL_FEATURES;

    const {
      entities: { organization },
    } = get();
    const { setEntityStatus } = statusesMethods(get());

    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const response: AdditionalFeatureType[] = await getAdditionalFeatures(
        organization.slug,
        abortSignal
      );

      set((state) =>
        produce(state, (draft) => {
          /* eslint-disable no-param-reassign */
          draft.entities[entityName] = response;
        })
      );
      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
});
