const getNumberLength = (n: number) => {
  return String(Math.abs(n)).length;
};

export const pxToRem = (fontSizePx: number) => {
  const baseFontSize = 10;
  const coef = 1;
  const rem = (fontSizePx / baseFontSize) * coef;
  return `${getNumberLength(rem) > 3 ? rem.toFixed(3) : rem}rem`;
};

export const fontFamily = ['Inter', 'sans-serif'].join(', ');

type FontVariant = {
  fontSize: string;
  lineHeight: string;
  letterSpacing: string;
  fontWeight: number;
  fontFamily: string;
};

export const fontVariant = (
  fontWeight: number,
  fontSize: number,
  lineHeight: number,
  letterSpacing = 0
) =>
  ({
    fontSize: pxToRem(fontSize),
    lineHeight: pxToRem(lineHeight),
    letterSpacing: pxToRem(letterSpacing),
    fontWeight,
    fontFamily,
  } as FontVariant);

// https://www.figma.com/file/EP1yzHNFce9xRpRGhonobt/Smaply-App-Design-System
export const fontVariants = {
  regularXXL: fontVariant(400, 24, 36),
  regularXL: fontVariant(400, 20, 32),
  regularL: fontVariant(400, 18, 24),
  regularM: fontVariant(400, 16, 24),
  regularS: fontVariant(400, 14, 20),
  regularXS: fontVariant(400, 13, 18),
  regularXXS: fontVariant(400, 11, 16),
  boldXXL: fontVariant(700, 24, 36),
  boldXL: fontVariant(700, 20, 32),
  boldL: fontVariant(700, 18, 24),
  boldM: fontVariant(700, 16, 24),
  boldS: fontVariant(700, 14, 22, 0.6),
  boldXS: fontVariant(700, 13, 20, 0.65),
  boldXXS: fontVariant(700, 11, 18, 0.55),
};
