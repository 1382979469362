import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { MenuWithArrow as Menu, MenuProps } from '../../atoms/Menu/Menu';
import { Icon } from '../../atoms/Icon/Icon';
import { colors } from '../../../theme';
import { ButtonBase, ButtonBaseProps } from '../../atoms/ButtonBase/ButtonBase';
import { MenuItem } from '../../atoms/MenuItem/MenuItem';

const UTM_PARAMS = '?utm_source=app&utm_medium=menu&utm_campaign=learn';

const LegacyButtonBare = (props: ButtonBaseProps) => {
  return (
    <ButtonBase
      sx={{
        color: colors.aluminium,
        backgroundColor: 'transparent',
        "&:hover, &[aria-expanded='true']": {
          color: colors.blue[500],
        },
        '&:active': {
          color: colors.blue[600],
        },
      }}
      {...props}
    >
      <Icon iconName="graduation-cap" sx={{ fontSize: 15, mr: 2 }} viewBox="0 0 15 15" />
      <Typography variant="regularXS" sx={{ color: 'inherit', lineHeight: 1 }}>
        Learn
      </Typography>
    </ButtonBase>
  );
};

const LegacyHeaderButton = (props: ButtonBaseProps) => {
  return (
    <LegacyButtonBare
      sx={{
        display: 'inline-flex',
        height: '30px',
        backgroundColor: colors.silver,
        color: colors.carbon,
        padding: '6px 10px 5px',
        borderRadius: '2px',
        "&:hover, &[aria-expanded='true']": {
          backgroundColor: colors.aluminium,
          color: colors.white,
          boxShadow: '0 2px 8px -2px rgb(0 0 0 / 60%)',
        },
        '&:active': {
          color: colors.white,
          backgroundColor: colors.iron,
          boxShadow: '0 2px 8px -2px rgb(0 0 0 / 60%)',
        },
      }}
      {...props}
    />
  );
};

export const LearnMenuBase = ({
  transformOrigin = { horizontal: 'left', vertical: 'top' },
  anchorOrigin = { horizontal: 'left', vertical: 'bottom' },
  ...rest
}: MenuProps) => {
  const theme = useTheme();

  return (
    <Menu
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
      PaperProps={{
        elevation: 0,
        sx: {
          textTransform: 'uppercase',
        },
      }}
      sx={{
        zIndex: 99999,
        '& .MuiListItemIcon-root': {
          alignSelf: 'flex-start',
          justifyContent: 'flex-start',
          minWidth: '20px !important',
          marginRight: theme.spacing(3),
        },
        '& .MuiMenuItem-root': {
          paddingY: theme.spacing(2),
          paddingX: theme.spacing(4),

          '& .MuiMenuItemText': {
            display: 'flex',
            textTransform: 'initial',
            maxWidth: 275,
            whiteSpace: 'normal',
          },
        },
        '& .MuiListItemText-root': {
          lineHeight: 0,
        },
        '& .MuiMenuTitle': {
          paddingX: theme.spacing(4),
          paddingBottom: theme.spacing(2),
          letterSpacing: theme.typography.pxToRem(0.65),
          margin: 0,
          '&.Mui-disabled': {
            opacity: 1,
          },
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: colors.blue[200],
          '& .MuiTypography-root': {
            color: colors.blue[500],
          },
          '& .MuiSvgIcon-root': {
            color: colors.blue[500],
          },
        },
        '& .MuiTypography-root, & .MuiSvgIcon-root': {
          color: colors.iron,
        },
      }}
      {...rest}
    >
      <MenuItem className="MuiMenuTitle" disabled>
        <Typography variant="boldXS" color="text.secondary">
          About smaply
        </Typography>
      </MenuItem>
      <MenuItem
        component="a"
        target="_blank"
        href={`https://helpdesk.smaply.com/support/home${UTM_PARAMS}`}
      >
        <ListItemIcon>
          <Icon sx={{ fontSize: 16, mt: 1.5 }} iconName="question-circle" viewBox="0 0 16 16" />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography variant="regularXS" color="text.secondary">
            Helpdesk
          </Typography>
          <Typography className="MuiMenuItemText" variant="regularXS" color="text.secondary">
            Tips and tricks for using Smaply
          </Typography>
        </ListItemText>
      </MenuItem>
      <MenuItem
        component="a"
        target="_blank"
        href={`https://helpdesk.smaply.com/support/solutions/articles/22000258098-about-the-tool-how-to-use-smaply#Video-demo%3A-how-to-work-with-Smaply${UTM_PARAMS}`}
      >
        <ListItemIcon>
          <Icon sx={{ fontSize: 16, mt: 1.5 }} iconName="play-circle" viewBox="0 0 16 16" />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography variant="regularXS" color="text.secondary">
            Full video demo
          </Typography>
          <Typography className="MuiMenuItemText" variant="regularXS" color="text.secondary">
            Our co-founder talks you through Smaply
          </Typography>
        </ListItemText>
      </MenuItem>
      <MenuItem
        component="a"
        target="_blank"
        href={`https://www.smaply.com/blog/example-journey-maps${UTM_PARAMS}`}
      >
        <ListItemIcon>
          <SvgIcon sx={{ fontSize: 18, mt: 1.5 }} viewBox="0 0 18 18">
            {/* TODO: fix bundling SVG */}
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="M0,2.18181818 C0,2.58327273 0.325818182,2.90909091 0.727272727,2.90909091 L3.63636364,2.90909091 C4.03781818,2.90909091 4.36363636,2.58327273 4.36363636,2.18181818 L4.36363636,0.727272727 C4.36363636,0.325818182 4.03781818,0 3.63636364,0 L0.727272727,0 C0.325818182,0 0,0.325818182 0,0.727272727 L0,2.18181818 Z"
                  id="Shape"
                ></path>
                <path
                  d="M5.81818182,2.18181818 C5.81818182,2.58327273 6.144,2.90909091 6.54545455,2.90909091 L9.45454545,2.90909091 C9.85672727,2.90909091 10.1818182,2.58327273 10.1818182,2.18181818 L10.1818182,0.727272727 C10.1818182,0.325818182 9.85672727,0 9.45454545,0 L6.54545455,0 C6.144,0 5.81818182,0.325818182 5.81818182,0.727272727 L5.81818182,2.18181818 Z"
                  id="Shape"
                ></path>
                <path
                  d="M11.6363636,2.18181818 C11.6363636,2.58327273 11.9614545,2.90909091 12.3636364,2.90909091 L15.2727273,2.90909091 C15.6749091,2.90909091 16,2.58327273 16,2.18181818 L16,0.727272727 C16,0.325818182 15.6749091,0 15.2727273,0 L12.3636364,0 C11.9614545,0 11.6363636,0.325818182 11.6363636,0.727272727 L11.6363636,2.18181818 Z"
                  id="Shape"
                ></path>
                <path
                  d="M0,5.81818182 C0,6.21963636 0.325818182,6.54545455 0.727272727,6.54545455 L3.63636364,6.54545455 C4.03781818,6.54545455 4.36363636,6.21963636 4.36363636,5.81818182 L4.36363636,4.36363636 C4.36363636,3.96218182 4.03781818,3.63636364 3.63636364,3.63636364 L0.727272727,3.63636364 C0.325818182,3.63636364 0,3.96218182 0,4.36363636 L0,5.81818182 Z"
                  id="Shape"
                ></path>
                <path
                  d="M5.81818182,5.81818182 C5.81818182,6.21963636 6.144,6.54545455 6.54545455,6.54545455 L9.45454545,6.54545455 C9.85672727,6.54545455 10.1818182,6.21963636 10.1818182,5.81818182 L10.1818182,4.36363636 C10.1818182,3.96218182 9.85672727,3.63636364 9.45454545,3.63636364 L6.54545455,3.63636364 C6.144,3.63636364 5.81818182,3.96218182 5.81818182,4.36363636 L5.81818182,5.81818182 Z"
                  id="Shape"
                ></path>
                <path
                  d="M11.6363636,5.81818182 C11.6363636,6.21963636 11.9614545,6.54545455 12.3636364,6.54545455 L15.2727273,6.54545455 C15.6749091,6.54545455 16,6.21963636 16,5.81818182 L16,4.36363636 C16,3.96218182 15.6749091,3.63636364 15.2727273,3.63636364 L12.3636364,3.63636364 C11.9614545,3.63636364 11.6363636,3.96218182 11.6363636,4.36363636 L11.6363636,5.81818182 Z"
                  id="Shape"
                ></path>
                <circle id="Oval" cx="2.18181818" cy="10.1818182" r="1.45454545"></circle>
                <circle id="Oval" cx="8" cy="13.0909091" r="1.45454545"></circle>
                <circle id="Oval" cx="13.8181818" cy="9.45454545" r="1.45454545"></circle>
                <polygon
                  id="Shape"
                  points="13.432728 8.83781942 14.2036356 10.0712715 8.03957246 13.923811 1.85657193 10.8323107 2.50706443 9.53132568 7.96042754 12.2580072"
                ></polygon>
              </g>
            </svg>
          </SvgIcon>
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography variant="regularXS" color="text.secondary">
            Example Journey Maps
          </Typography>
          <Typography className="MuiMenuItemText" variant="regularXS" color="text.secondary">
            Industry-specific examples as inspiration
          </Typography>
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem className="MuiMenuTitle" disabled>
        <Typography variant="boldXS" color="text.secondary">
          About Service Design
        </Typography>
      </MenuItem>
      <MenuItem
        component="a"
        target="_blank"
        href={`https://www.smaply.com/resources/cx-course-online${UTM_PARAMS}`}
      >
        <ListItemIcon>
          <Icon sx={{ fontSize: 18, mt: 1 }} iconName="graduation-cap" viewBox="0 0 18 18" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="regularXS" color="text.secondary">
            Online Course: Experience Innovation
          </Typography>
          <Typography className="MuiMenuItemText" variant="regularXS" color="text.secondary">
            Learn journey mapping basics in 3 hours
          </Typography>
        </ListItemText>
      </MenuItem>
      <MenuItem component="a" target="_blank" href={`https://smaply.com/blog-toolbox${UTM_PARAMS}`}>
        <ListItemIcon>
          <Icon sx={{ fontSize: 15, mt: 1.5 }} iconName="wrench" viewBox="0 0 15 15" />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography variant="regularXS" color="text.secondary">
            Customer experience toolbox
          </Typography>
          <Typography className="MuiMenuItemText" variant="regularXS" color="text.secondary">
            Cheat sheets, templates and more
          </Typography>
        </ListItemText>
      </MenuItem>
      <MenuItem component="a" target="_blank" href={`https://www.smaply.com/blog${UTM_PARAMS}`}>
        <ListItemIcon>
          <Icon sx={{ fontSize: 16, mt: 1.5 }} iconName="book" viewBox="0 0 16 16" />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography variant="regularXS" color="text.secondary">
            Smaply Blog
          </Typography>
          <Typography className="MuiMenuItemText" variant="regularXS" color="text.secondary">
            State of the art in customer experience
          </Typography>
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};

export type LearnMenuProps = {
  MenuProps?: Omit<MenuProps, 'open'>;
  ButtonProps?: ButtonBaseProps;
  ButtonComponent?: typeof ButtonBase;
};

export const LearnMenu = ({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  MenuProps,
  ButtonProps,
  ButtonComponent = ButtonBase,
}: LearnMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonComponent
        aria-label="more"
        onClick={handleClick}
        aria-controls={open ? 'help-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        {...ButtonProps}
      />
      <LearnMenuBase
        id="help-menu"
        mode="dark"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
        {...MenuProps}
      />
    </>
  );
};

export const LearnMenuHeaderBare = (props: LearnMenuProps) => {
  return <LearnMenu ButtonComponent={LegacyButtonBare} {...props} />;
};

export const LearnMenuHeader = (props: LearnMenuProps) => {
  return <LearnMenu ButtonComponent={LegacyHeaderButton} {...props} />;
};
