import { useEffect, useState } from 'react';

/**
 * A React Hook that returns an AbortSignal that is marked as aborted when the calling component is
 * unmounted. This is useful for canceling promises, such as those for network requests, when a
 * component is unmounted.
 *
 * To be used in conjunction with fetchWithTimeout
 *
 * @returns {AbortSignal} Returns the abort signal that can be used within a fetch call.
 * @example
 *
 * const abortSignal = useAbortSignal();
 * // => AbortSignal
 *
 * fetchWithTimeout('www.example.com', { signal: abortSignal })
 * // => Promise<pending>
 *
 * fetchWithTimeout('www.example.com', { signal: abortSignal, method: 'POST' })
 * // => Promise<pending>
 */

export const useAbortSignal = (): AbortSignal => {
  const [abortController] = useState(() => new AbortController());

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);

  return abortController.signal;
};
