import React from 'react';
import MuiChip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { Avatar, AvatarProps } from '../Avatar/Avatar';
import { colors } from '../../../theme';

export type { ChipProps };

const StyledChip = styled(MuiChip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '& .MuiChip-avatar': {
    color: colors.white,
    margin: 0,
    height: '100%',
  },
}));

export const Chip = (props: ChipProps): JSX.Element => {
  return <StyledChip {...props} />;
};

export const ChipUser = ({
  avatarProps,
  ...otherProps
}: ChipProps & { avatarProps?: AvatarProps }) => {
  return <StyledChip avatar={<Avatar variant="square" {...avatarProps} />} {...otherProps} />;
};

export const ChipPDF = (props: ChipProps): JSX.Element => {
  return <MuiChip label="PDF" sx={{ backgroundColor: '#f46160', color: '#ffffff' }} {...props} />;
};

export const ChipXLS = (props: ChipProps): JSX.Element => {
  return <MuiChip label="XLS" sx={{ backgroundColor: '#19b67f', color: '#ffffff' }} {...props} />;
};

export const ChipPPT = (props: ChipProps): JSX.Element => {
  return <MuiChip label="PPT" sx={{ backgroundColor: '#cb4527', color: '#ffffff' }} {...props} />;
};
