import React from 'react';
import { render } from '@testing-library/react';
import { Slider } from './Slider';

describe('Slider', () => {
  it('should render slider successfully', () => {
    const { baseElement } = render(<Slider />);
    expect(baseElement).toBeTruthy();
  });
});
