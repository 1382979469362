import React from 'react';
import * as CSS from 'csstype';

const zxcvbn = require('zxcvbn');

type Score =
  -1| // too short
  0 | // too guessable. (guesses < 10^3)
  1 | // very guessable. (guesses < 10^6)
  2 | // somewhat guessable. (guesses < 10^8)
  3 | // safely unguessable. (guesses < 10^10)
  4; // very unguessable. (guesses >= 10^10)

interface ScoreAttr {
  class: string;
  label: string;
  width: CSS.Property.Width;
}

interface Props {
  password: string;
  label?: string;
  minLength?: number;
}
const PasswordIndicator: React.FunctionComponent<Props> = ({
  label = 'Password strength:',
  password = '',
  minLength = 8,
}) => {
  const scoreToAttr = (score: Score) : ScoreAttr => {
    switch (score) {
      case -1:
        return { class: 'bg-red', label: `too short (min ${minLength} characters)`, width: '20%' };
      case 0:
        return { class: 'bg-red', label: 'very weak', width: '20%' };
      case 1:
        return { class: 'bg-orange', label: 'weak', width: '40%' };
      case 2:
        return { class: 'bg-yellow', label: 'fair', width: '60%' };
      case 3:
        return { class: 'bg-green', label: 'strong', width: '80%' };
      default:
        return { class: 'bg-green-200', label: 'very strong', width: '100%' };
    }
  };

  const getScore = (pwd: string) : Score => (
    pwd.length >= minLength
      ? zxcvbn(password).score
      : -1
  );

  const score = getScore(password);
  const attributes = scoreToAttr(score);

  return (
    <div className="mt-16 w-full">
      <div className={`bar rounded bg-opacity-25 mb-8 ${attributes.class}`}>
        <div
          style={{ width: `${attributes.width}` }}
          className={`meter h-full ${attributes.class}`}
        />
      </div>
      <div className="flex flex-col text-11 text-gray-carbon">
        <span className="font-bold">
          {label}
        </span>
        <span>
          {attributes.label}
        </span>
      </div>
    </div>
  );
};

export default PasswordIndicator;
