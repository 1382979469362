import { HTTPError } from 'ky';
import { entityNames } from '../../constants/entityNames';
import { store } from '../../config/store';

const initialState = store.getState();
const resetState = () => store.setState(initialState, true);

describe('errorsSlice', () => {
  afterEach(() => {
    resetState();
  });

  describe('addError', () => {
    it('should add an error to the entity', () => {
      const { addError } = store.getState();
      const error = {} as HTTPError;
      addError(error, entityNames.PLANS);

      const state = store.getState();

      expect(state.entities.errors[entityNames.PLANS][0].entityName).toBe(entityNames.PLANS);
    });

    it('should add an error to the entity with id', () => {
      const planId = '1';
      const { addError } = store.getState();
      const error = {} as HTTPError;
      addError(error, entityNames.PLANS, planId);

      const state = store.getState();

      expect(state.entities.errors[entityNames.PLANS][0].entityId).toBe(planId);
    });
  });

  describe('removeError', () => {
    it('should remove an error from state', () => {
      const { addError, removeError } = store.getState();
      const error = {} as HTTPError;
      addError(error, entityNames.PLANS);

      let state = store.getState();
      const errorId = state.entities.errors[entityNames.PLANS][0].id;

      removeError(entityNames.PLANS, errorId);

      state = store.getState();
      expect(state.entities.errors[entityNames.PLANS].length).toBe(0);
    });

    it('should remove the correct error from state', () => {
      const { addError, removeError } = store.getState();
      const error = {} as HTTPError;

      addError(error, entityNames.PLANS);
      addError(error, entityNames.PLANS);

      let state = store.getState();
      const errorId = state.entities.errors[entityNames.PLANS][0].id;

      removeError(entityNames.PLANS, errorId);

      state = store.getState();
      expect(state.entities.errors[entityNames.PLANS].length).toBe(1);
      expect(state.entities.errors[entityNames.PLANS][0].entityId).not.toBe(errorId);
    });
  });

  describe('removeAllErrors', () => {
    it('should remove all errors from the entity', () => {
      const { addError, removeAllErrors } = store.getState();
      const error = {} as HTTPError;

      addError(error, entityNames.PLANS);
      addError(error, entityNames.PLANS);

      removeAllErrors(entityNames.PLANS);

      const state = store.getState();
      expect(state.entities.errors[entityNames.PLANS].length).toBe(0);
    });
  });
});
