import { billingFrequencies } from '../constants/billingFrequencies';
import { BillingInformation } from '../types/pricing';

export const isBillingScheduledForCancelation = ({
  scheduled_for_cancellation,
}: BillingInformation) => Boolean(scheduled_for_cancellation);

export const billingHasPlanPrice = ({ plan_price }: BillingInformation) => Boolean(plan_price);

export const isBillingFrequencyYear = ({ billing_frequency }: BillingInformation) =>
  billing_frequency === billingFrequencies.year;
