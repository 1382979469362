import React from 'react';
import {
  CardContent as MuiCardContent,
  CardContentProps as MuiCardContentProps,
  styled,
} from '@mui/material';

export type CardContentProps = MuiCardContentProps;

const StyledCardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: theme.spacing(5),
}));

export const CardContent = (props: CardContentProps) => {
  return <StyledCardContent {...props} />;
};
