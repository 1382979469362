import isString from 'lodash/isString';
import { TagsApi } from './tagsApi';

export const deleteTag = async (
  organizationId: string,
  tagId: string | number
): Promise<Response> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (!tagId) {
    const error = 'smaply-ui: missing tag id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result = await TagsApi.remove(organizationId, tagId);

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
