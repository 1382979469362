import { Options } from 'ky';
import { JourneyMap } from '../../types/journeyMap';
import { JourneyMapApi } from './journeyMapApi';

export const createJourneyMap = async (
  organizationId: string,
  projectId: string,
  options?: Options
): Promise<JourneyMap> => {
  if (!organizationId) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }
  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: JourneyMap = await JourneyMapApi.postJourneyMap(
      organizationId,
      projectId,
      options
    ).json();

    return result;
  } catch (error) {
    console.error(error);
    if (typeof window.showErrorFlashMessage === 'function') window.showErrorFlashMessage(error);
    throw error;
  }
};
