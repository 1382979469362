import { JiraApi } from './jiraApi';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { JiraIntegration } from '../../types/jira';
import { jiraIntegrationNotIntegrated } from '../../mocks/jira';
import { delayPromise } from '../../utils/storybook';

export const deleteJiraIntegration = async (
  organizationSlug: string,
  abortSignal: AbortSignal
): Promise<JiraIntegration> => {
  if (!organizationSlug) {
    const error = 'smaply-ui: missing organization slug';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result = shouldMockSmaplyAPI
      ? await delayPromise(() => {
          return jiraIntegrationNotIntegrated;
        })
      : await JiraApi.deleteJiraIntegrationRequest(organizationSlug, {
          signal: abortSignal,
        }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
