import { enableFetchMocks } from 'jest-fetch-mock';
import { getTags } from './getTags';

enableFetchMocks();

describe('getTags', () => {
  beforeEach(() => {
    fetchMock.resetMocks();
  });

  describe('throws errors', () => {
    // eslint-disable-next-line consistent-return
    it('when organization id is not present', async () => {
      let err: Error;
      try {
        // @ts-expect-error has to ignore the error since we are calling the method without params
        const result = await getTags();
        return () => result;
      } catch (e) {
        err = e;
      }

      expect(err.message).toMatch(new RegExp('^smaply-ui: missing organization id'));
      expect(fetchMock.mock.calls).toHaveLength(0);
    });

    it('when GET fails', async () => {
      fetchMock.mockReject(new Error('failed response'));
      try {
        await getTags('1234567890');
      } catch (e) {
        expect(e.message).toBe('failed response');
      }
    });
  });

  describe('with valid property id', () => {
    it('returns valid results when empty array is sent back', async () => {
      fetchMock.mockResponse(JSON.stringify([]));
      try {
        const result = await getTags('1234567890');
        expect(result).toMatchObject([]);
      } catch (e) {
        return e;
      }
    });
  });
});
