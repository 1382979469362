import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, Theme } from '@mui/material/styles';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import { getStaticState } from '../../../../state/helpers/getStaticState';
import { additionalFeatures } from '../../../../constants/additionalFeatures';
import { allowedStatuses } from '../../../../state/constants/allowedStatuses';
import { List } from '../../../atoms/List/List';
import { ErrorMessage } from '../../../molecules/Alerts/GenericAlert';
import { useAbortSignal } from '../../../../utils/hooks/useAbortSignal';
import { Alert } from '../../../atoms/Alert/Alert';
import { Button } from '../../../atoms/Button/Button';
import { Circle } from '../../../atoms/Circle/Circle';
import { colors } from '../../../../theme';
import { Template } from '../../Template';
import { JiraSettingsSkeleton } from './JiraSettingsSkeleton';
import { userRoles } from '../../../../constants/userRoles';
import { IconText } from '../../../atoms/Icon/IconText';
import { Input, InputWithCopyButton } from '../../../atoms/Inputs/Input/Input';
import { MediaCard } from '../../../molecules/Card/MediaCard';
import { Dialog } from '../../../atoms/Dialog/Dialog';
import { DialogTitle } from '../../../atoms/Dialog/DialogTitle';
import { DialogContent } from '../../../atoms/Dialog/DialogContent';

export type JiraSettingsProps = {
  trackEvent?: (eventName: string, properties?: Record<string, unknown>) => void;
};

export const JiraSettings = ({ trackEvent }: JiraSettingsProps) => {
  const { getJiraIntegration, putJiraIntegration, deleteJiraIntegration, removeAllErrors } =
    getStaticState();

  const {
    entities: { jiraIntegration, organization },
    isLoading,
  } = useEntities(entityNames.JIRA_INTEGRATION, entityNames.ORGANIZATION);

  const abortSignal = useAbortSignal();

  useEffect(() => {
    getJiraIntegration(abortSignal);
  }, [getJiraIntegration, putJiraIntegration, deleteJiraIntegration, abortSignal]);

  const put = (field: string, value: string) => {
    removeAllErrors(entityNames.JIRA_INTEGRATION);
    putJiraIntegration(value, abortSignal);
  };

  const goToJiraSettings = () => {
    trackEvent('Integration Activity Triggered', {
      integration: 'Jira',
      activity: 'open setup page',
    });

    window
      .open(`${jiraIntegration.jira_url}/plugins/servlet/applinks/listApplicationLinks`, '_blank')
      .focus();
  };

  const goToJiraWebhooksPage = () => {
    trackEvent('Integration Activity Triggered', {
      integration: 'Jira',
      activity: 'open jira webhook page',
    });

    window.open(`${jiraIntegration.jira_url}/plugins/servlet/webhooks`, '_blank').focus();
  };

  const goToJiraToAuthorizeConnection = () => {
    trackEvent('Integration Activity Triggered', {
      integration: 'Jira',
      activity: 'request authorization',
    });

    window
      .open(
        `/web/v1/organizations/${organization.slug}/enterprise_configuration/integrations/jira/new`,
        '_blank'
      )
      .focus();
  };

  const disconnect = () => {
    trackEvent('Integration Activity Triggered', {
      integration: 'Jira',
      activity: 'request disconnection',
    });

    deleteJiraIntegration(abortSignal);
  };

  const theme: Theme = useTheme();

  const userIsAdmin = Boolean(organization.role === userRoles.ADMIN);

  const featureAvailable = organization.additional_features.includes(
    additionalFeatures.journey_map_jira.id
  );

  const [screenshotDialogOpen, setScreenshotDialogOpen] = useState(false);
  const [screenshot, setScreenshot] = useState({ src: '', title: '' });

  const openScreenshotDialog = (src: any, title: string) => {
    setScreenshot({ src, title });
    setScreenshotDialogOpen(true);
  };

  const closeScreenshotDialog = () => {
    setScreenshotDialogOpen(false);
  };

  return (
    <Box padding={theme.spacing(10)} position="relative">
      <Box mb={9}>
        <Typography color={colors.iron} variant="boldM" mr={2}>
          JIRA INTEGRATION IN ORGANIZATION
        </Typography>
        <Typography color={colors.onyx} variant="regularM">
          {organization.name}
        </Typography>
      </Box>

      <Template
        skeleton={<JiraSettingsSkeleton />}
        isLoading={isLoading}
        errorComponent={<ErrorMessage />}
      >
        <>
          {!userIsAdmin && (
            <Alert severity="info">
              Only administrators of your organization can change the Jira integration.
            </Alert>
          )}

          {!featureAvailable && (
            <Alert severity="info">This feature is not available in your plan.</Alert>
          )}

          <Box sx={{ opacity: featureAvailable && userIsAdmin && !isLoading ? 1 : 0.25 }}>
            {featureAvailable && !jiraIntegration.integrated && (
              <Alert severity="info">
                Please note that you have to be logged in as Administrator in Jira to set up the
                connection.
              </Alert>
            )}

            {jiraIntegration.integrated && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <IconText
                    color={colors.green[500]}
                    fontVariant="boldXXS"
                    iconName="check-circle"
                    text="Your organization is connected to Jira"
                  />
                </Box>
                <Box pt={theme.spacing(4)}>
                  <Input
                    label="Your Jira url"
                    onBlur={(event) => put('jira_url', event.target.value)}
                    defaultValue={jiraIntegration.jira_url}
                    disabled
                  />
                </Box>
                <Box pt={theme.spacing(4)}>
                  <Button onClick={disconnect} disabled={!featureAvailable} color="danger">
                    Disconnect Jira
                  </Button>
                </Box>
                <Box pt={theme.spacing(4)}>
                  <Alert severity="warning">
                    If you decide to disconnect with Jira, linked issues will stay in your Jira
                    lanes, but the data in the issues will not be able to get updated anymore.
                  </Alert>
                </Box>
              </>
            )}

            {!jiraIntegration.integrated && (
              <>
                <Box sx={{ display: 'flex', alignContent: 'flex-start' }}>
                  <Box
                    maxWidth="50%"
                    pt={theme.spacing(8)}
                    mb={theme.spacing(8)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{ marginRight: theme.spacing(2) }}>
                        <Circle label="1" />
                      </Box>
                      <Typography variant="boldS">
                        Enter your Jira URL and go to settings in Jira
                      </Typography>
                    </Box>
                    <Box pt={theme.spacing(4)}>
                      <Input
                        label="Your Jira url"
                        onBlur={(event) => put('jira_url', event.target.value)}
                        defaultValue={jiraIntegration.jira_url}
                        disabled={Boolean(!featureAvailable || jiraIntegration.integrated)}
                        status={
                          jiraIntegration.errors &&
                          jiraIntegration.errors.messages &&
                          jiraIntegration.errors.messages.jira_url
                            ? allowedStatuses.ERROR
                            : allowedStatuses.IDLE
                        }
                        errorMessage={
                          jiraIntegration.errors &&
                          jiraIntegration.errors.messages &&
                          jiraIntegration.errors.messages.jira_url
                            ? 'Url is not valid'
                            : ''
                        }
                      />
                    </Box>
                    <Box pt={theme.spacing(4)}>
                      <Box>
                        <Button
                          onClick={goToJiraSettings}
                          disabled={Boolean(!featureAvailable || !jiraIntegration.jira_url)}
                        >
                          Go to Jira settings
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    maxWidth="50%"
                    sx={{
                      display: 'flex',
                      flex: '1 1 auto',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      padding: theme.spacing(6),
                    }}
                  />
                </Box>

                <Box sx={{ borderBottom: `1px solid ${colors.smoke}` }} />

                <Box sx={{ display: 'flex' }}>
                  <Box
                    maxWidth="50%"
                    pt={theme.spacing(8)}
                    mb={theme.spacing(8)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{ marginRight: theme.spacing(2) }}>
                        <Circle
                          label="2"
                          color={jiraIntegration.smaply_url ? colors.blue[500] : colors.smoke}
                          colorText={jiraIntegration.smaply_url ? colors.white : colors.carbon}
                        />
                      </Box>
                      <Typography variant="boldS">Create Application Link in Jira</Typography>
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      Copy the following application URL into the form in Jira and click
                      &apos;Create new link&apos;.
                    </Typography>
                    <Box pt={theme.spacing(4)}>
                      <InputWithCopyButton
                        label="Application url"
                        disabled
                        value={jiraIntegration.smaply_url}
                      />
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      A modal opens, click on &apos;Continue&apos; to proceed.
                    </Typography>
                  </Box>
                  <Box
                    maxWidth="50%"
                    sx={{
                      display: 'flex',
                      flex: '1 1 auto',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      padding: theme.spacing(6),
                    }}
                  >
                    <MediaCard
                      image={'./jira_settings/step2preview.png'}
                      onClick={() => {
                        openScreenshotDialog(
                          './jira_settings/step2.png',
                          'Enter your Jira URL and go to settings in Jira'
                        );
                      }}
                    />
                  </Box>
                </Box>

                <Box sx={{ borderBottom: `1px solid ${colors.smoke}` }} />

                <Box sx={{ display: 'flex' }}>
                  <Box
                    maxWidth="50%"
                    pt={theme.spacing(8)}
                    mb={theme.spacing(8)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{ marginRight: theme.spacing(2) }}>
                        <Circle
                          label="3"
                          color={jiraIntegration.consumer_name ? colors.blue[500] : colors.smoke}
                          colorText={jiraIntegration.consumer_name ? colors.white : colors.carbon}
                        />
                      </Box>
                      <Typography variant="boldS">Set application name</Typography>
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      Copy the following Application Name into the settings. After clicking
                      &apos;Continue&apos;, the new Application Link will get created and appear in
                      the list.
                    </Typography>
                    <Box pt={theme.spacing(4)}>
                      <InputWithCopyButton
                        label="Application name"
                        disabled
                        value={jiraIntegration.consumer_name}
                      />
                    </Box>
                  </Box>
                  <Box
                    maxWidth="50%"
                    sx={{
                      display: 'flex',
                      flex: '1 1 auto',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      padding: theme.spacing(6),
                    }}
                  >
                    <MediaCard
                      image={'./jira_settings/step3preview.png'}
                      onClick={() => {
                        openScreenshotDialog(
                          './jira_settings/step3.png',
                          'Create Application Link in Jira'
                        );
                      }}
                    />
                  </Box>
                </Box>

                <Box sx={{ borderBottom: `1px solid ${colors.smoke}` }} />

                <Box sx={{ display: 'flex' }}>
                  <Box
                    maxWidth="50%"
                    pt={theme.spacing(8)}
                    mb={theme.spacing(8)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{ marginRight: theme.spacing(2) }}>
                        <Circle
                          label="4"
                          color={jiraIntegration.consumer_key ? colors.blue[500] : colors.smoke}
                          colorText={jiraIntegration.consumer_key ? colors.white : colors.carbon}
                        />
                      </Box>
                      <Typography variant="boldS">Configure application settings</Typography>
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      Edit the newly created Application Link. Go to the Incoming Authentication tab
                      and fill in the corresponding fields. Close the modal to finish setup.
                    </Typography>
                    <Box pt={theme.spacing(4)}>
                      <InputWithCopyButton
                        label="Consumer key"
                        disabled
                        value={jiraIntegration.consumer_key}
                      />
                    </Box>
                    <Box pt={theme.spacing(4)}>
                      <InputWithCopyButton
                        label="Consumer name"
                        disabled
                        value={jiraIntegration.consumer_name}
                      />
                    </Box>
                    <Box pt={theme.spacing(4)}>
                      <InputWithCopyButton
                        label="Public key"
                        disabled
                        value={jiraIntegration.public_key}
                      />
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      After clicking &apos;Save&apos; you can close the modal.
                    </Typography>
                  </Box>
                  <Box
                    maxWidth="50%"
                    sx={{
                      display: 'flex',
                      flex: '1 1 auto',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      padding: theme.spacing(6),
                    }}
                  >
                    <MediaCard
                      image={'./jira_settings/step4preview.png'}
                      onClick={() => {
                        openScreenshotDialog('./jira_settings/step4.png', 'Set application name');
                      }}
                    />
                  </Box>
                </Box>

                <Box sx={{ borderBottom: `1px solid ${colors.smoke}` }} />

                <Box sx={{ display: 'flex' }}>
                  <Box
                    maxWidth="50%"
                    pt={theme.spacing(8)}
                    mb={theme.spacing(8)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{ marginRight: theme.spacing(2) }}>
                        <Circle
                          label="5"
                          color={jiraIntegration.consumer_key ? colors.blue[500] : colors.smoke}
                          colorText={jiraIntegration.consumer_key ? colors.white : colors.carbon}
                        />
                      </Box>
                      <Typography variant="boldS">Configure Webhook</Typography>
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      A webhook is required to receive Jira issue updates in Smaply.
                    </Typography>
                    <Box pt={theme.spacing(4)}>
                      <Box>
                        <Button
                          onClick={goToJiraWebhooksPage}
                          disabled={Boolean(!featureAvailable || !jiraIntegration.webhook_url)}
                        >
                          Go to Jira WebHooks
                        </Button>
                      </Box>
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      &bull; Click on &apos;Create a Webhook&apos; in the top right corner of the
                      page.
                    </Typography>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      &bull; Fill in the corresponding fields.
                    </Typography>
                    <Box pt={theme.spacing(4)}>
                      <InputWithCopyButton label="Name" disabled defaultValue="Smaply Webhook" />
                    </Box>
                    <Box pt={theme.spacing(4)}>
                      <InputWithCopyButton
                        label="URL"
                        disabled
                        value={jiraIntegration.webhook_url}
                      />
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      &bull; Check 3 checkboxes under &apos;Issue&apos;:
                    </Typography>
                    <Box mt={theme.spacing(4)}>
                      <List items={['Updated', 'Deleted']} />
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      &bull; Click on &apos;Create&apos;.
                    </Typography>
                  </Box>
                  <Box
                    maxWidth="50%"
                    sx={{
                      display: 'flex',
                      flex: '1 1 auto',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      padding: theme.spacing(6),
                    }}
                  >
                    <MediaCard
                      image={'./jira_settings/step5preview.png'}
                      onClick={() => {
                        openScreenshotDialog(
                          './jira_settings/step5preview.png',
                          'Configure Webhook'
                        );
                      }}
                    />
                  </Box>
                </Box>

                <Box sx={{ borderBottom: `1px solid ${colors.smoke}` }} />

                <Box sx={{ display: 'flex' }}>
                  <Box
                    maxWidth="50%"
                    pt={theme.spacing(8)}
                    mb={theme.spacing(8)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{ marginRight: theme.spacing(2) }}>
                        <Circle
                          label="6"
                          color={jiraIntegration.consumer_key ? colors.blue[500] : colors.smoke}
                          colorText={jiraIntegration.consumer_key ? colors.white : colors.carbon}
                        />
                      </Box>
                      <Typography variant="boldS">Connect application</Typography>
                    </Box>
                    <Typography pt={theme.spacing(4)} display="flex" variant="regularS">
                      Click on &apos;Connect Jira&apos; and click &apos;Allow&apos; to accept Smaply
                      connecting with your Jira.
                    </Typography>
                    <Box pt={theme.spacing(8)}>
                      <Button
                        onClick={goToJiraToAuthorizeConnection}
                        disabled={Boolean(
                          !featureAvailable ||
                            jiraIntegration.integrated ||
                            !jiraIntegration.jira_url
                        )}
                      >
                        Connect Jira
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    maxWidth="50%"
                    sx={{
                      display: 'flex',
                      flex: '1 1 auto',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      padding: theme.spacing(6),
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      </Template>
      <Dialog fullWidth maxWidth="lg" onClose={closeScreenshotDialog} open={screenshotDialogOpen}>
        <DialogTitle onClose={closeScreenshotDialog}>{screenshot.title}</DialogTitle>
        <DialogContent>
          <img src={screenshot.src} alt={screenshot.title} width="100%" />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
