import { plans } from '../mocks/plans';
import { Currency } from '../types/subscription';
import { plansFactory, orderPlans } from './plansFactory';

describe('plansFactory', () => {
  ['EUR', 'USD'].map((currency: Currency) => {
    const shuffledPlans = plans.sort(() => Math.random() - 0.5); // Make sure to have random order of plans first.

    const plansAfterFactory = plansFactory(shuffledPlans, currency);
    it(`returns right amount of ${currency} plans`, () => {
      expect(plansAfterFactory.length).toEqual(7);
    });

    const orderedPlans = orderPlans(plansAfterFactory as any);

    it(`returns right order of ${currency} plans`, () => {
      // remove the enterprise plans (they are added by the factory)
      orderedPlans.pop();
      orderedPlans.pop();

      expect(orderedPlans.map((plan) => plan.chargebee_id)).toEqual([
        `smaply-free-${currency.toLowerCase()}-m`,
        `smaply-basic-${currency.toLowerCase()}-m`,
        `smaply-basic-${currency.toLowerCase()}-a`,
        `smaply-pro-${currency.toLowerCase()}-m`,
        `smaply-pro-${currency.toLowerCase()}-a`,
      ]);
    });
  });
});
