import React, { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  tag?: 'button';
  type?: 'button' | 'submit' | 'reset';
  text?: string;
  color?: 'blue' | 'red';
  labelClass?: string;
  iconClass?: string;
  icon?: string; // integrate fa types
}

const Button: React.FunctionComponent<ButtonProps> = ({
  tag = 'button',
  type = tag === 'button' ? 'button' : undefined,
  children,
  color = 'blue',
  icon = 'fa fa-arrow-right',
  iconClass = '',
  labelClass = '',
  text = '',
  className = '',
  ...other
}: ButtonProps) => (
  <button
    type={type}
    className={`btn btn--${color} ${className}`}
    {...other}
  >
    <span className={`btn-label ${labelClass}`}>
      {children}
      {text}
    </span>
    <span className={`btn-icon ${iconClass}`}>
      <i className={icon} />
    </span>
  </button>
);

export default Button;
