import React from 'react';
import { render } from '@testing-library/react';
import { Accordion } from './Accordion';
import { AccordionHeader, AccordionContent } from './AccordionDemoData';

describe('Accordion', () => {
  it('should render successfully', () => {
    const { baseElement } = render(
      <Accordion header={<AccordionHeader />}>
        <AccordionContent />
      </Accordion>
    );
    expect(baseElement).toBeTruthy();
  });
});
