import { JourneyMap } from '../types/journeyMap';

export const journeyMap: JourneyMap = {
  id: 21,
  title: 'my journey map',
  slug: '2e08be89-2cd6-4bb4-a3e8-ef88f7420d17',
  description:
    '<p><strong>My Journey Map Description</strong></p><p><br></p><p><strong>List</strong></p><ul><li>Do this</li><li>Do this</li><li>Do that</li></ul><p><br></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis tortor felis. Aenean feugiat tellus at lorem aliquam efficitur. Nam vel feugiat odio. Phasellus ultricies ullamcorper rhoncus. Ut aliquam sed eros sit amet hendrerit. Duis tincidunt erat at consequat tempor. <em>Praesent sagittis vulputate lorem nec laoreet.</em>\t</p>',
  updated_at: '2021-11-02T12:49:28.000+01:00',
  steps_count: 0,
  journeys_count: 0,
  share_url: null,
  enable_sharing_password: false,
  sharing_password: null,
  internal_notes:
    '<p><strong>My Journey Map Internal Notes</strong></p><p><br></p><p><strong>List</strong></p><ul><li>Do this</li><li>Do this</li><li>Do that</li></ul><p><br></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis tortor felis. Aenean feugiat tellus at lorem aliquam efficitur. Nam vel feugiat odio. Phasellus ultricies ullamcorper rhoncus. Ut aliquam sed eros sit amet hendrerit. Duis tincidunt erat at consequat tempor. <em>Praesent sagittis vulputate lorem nec laoreet.</em>\t</p>',
  commentable: false,
  comments_count: 0,
  organization_slug: 'jose',
  project_slug: '3a34052f-15ff-4d0d-b661-9abff030769f',
  exports: [
    {
      id: 1,
      type: 'pdf',
      status: 'done',
      url: 'https://smaplydev.s3.eu-central-1.amazonaws.com/uploads/rewrite/journey_map_export/file/1/my-journey-map.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJLKSKFUUQYDTYIWQ%2F20211102%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20211102T130556Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=c684b04101add5ced85d82ef8555fd0f721968dfa8f753c3a44be470bd973d3a',
      direct_url:
        'https://smaplydev.s3.eu-central-1.amazonaws.com/uploads/rewrite/journey_map_export/file/1/my-journey-map.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJLKSKFUUQYDTYIWQ%2F20211102%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20211102T130556Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=c684b04101add5ced85d82ef8555fd0f721968dfa8f753c3a44be470bd973d3a',
      created_at: '2021-11-02T14:05:52.000+01:00',
    },
  ],
};

export const journeyMaps = [journeyMap];
