import React from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../../../theme';
import { Chip } from '../../../atoms/Chip/Chip';

const PlanChip = styled(Chip)(({ theme }) => ({
  color: colors.white,
  fontWeight: 'bold',
  fontSize: theme.typography.pxToRem(8),
  width: 36,
  height: 16,
  letterSpacing: '0.55px',
  textTransform: 'uppercase',
  '& .MuiChip-label': {
    padding: 0,
  },
}));

export const PlanChipBasic = (): JSX.Element => {
  return <PlanChip label="Basic" sx={{ backgroundColor: colors.blue[500] }} />;
};

export const PlanChipPro = (): JSX.Element => {
  return <PlanChip label="Pro" sx={{ backgroundColor: colors.green[500] }} />;
};
