import { colors } from '../theme/colors/colors';

export const userColors = {
  undefined: colors.blue[500],
  default: colors.blue[500],
  red: '#ff6136',
  orange: '#ff9933',
  yellow: '#e5c642',
  green: '#61ce66',
  dark_blue_legacy: '#1c5fe5',
  pink: '#ff3399',
  purple: colors.purple[500],
};
