import { GetState, SetState } from 'zustand/vanilla';
import { default as produce } from 'immer';
import { errorsMethods } from '../errors/errorsSelectors';
import { statusesMethods } from '../statuses/statusesSelector';
import { AppStateInterface } from '../../config/storeTypes';
import { entityNames } from '../../constants/entityNames';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { WallsyncMetaData } from '../../../types/wallsync';
import { getWallsyncData } from '../../../api/wallsync/getWallsync';
import { wallsyncIntegrations } from '../../../constants/wallsync';

export const wallsyncSlice = (
  set: SetState<AppStateInterface>,
  get: GetState<AppStateInterface>
) => ({
  getWallsync: async () => {
    const entityName = entityNames.WALLSYNC;
    const {
      entities: { organization, selectedProject },
    } = get();
    const { setEntityStatus } = statusesMethods(get());

    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);
      const response: WallsyncMetaData = await getWallsyncData(
        organization.slug,
        selectedProject.slug
      );

      set((state) =>
        produce(state, (draft) => {
          /* eslint-disable no-param-reassign */
          draft.entities.wallsync = response;
        })
      );

      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  getWallsyncIntegrations: () => {
    return wallsyncIntegrations;
  },
});
