import { Options } from 'ky';
import { OrganizationId } from '../../types/organization';
import { ProjectId } from '../../types/project';
import { get } from '../config';

export const WallsyncApi = {
  get: (organizationId: OrganizationId, projectId: ProjectId, options?: Options) =>
    get(
      `web/v1/organizations/${organizationId}/projects/${projectId}/wallsync_qrcode.svg`,
      options
    ),
};
