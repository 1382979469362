import React from 'react';
import { useEntities } from '../../../../state/hooks/useEntities';
import { entityNames } from '../../../../state/constants/entityNames';
import { MetadataField } from './metadataFields';
import { AllowedStatuses } from '../../../../types/state';
import { RichTextInputValue } from '../../../atoms/Inputs/RichTextInput/RichTextInputProps';
import { JourneyMap } from '../../../../types/journeyMap';
import { CharCountRichTextInput } from '../../../atoms/Inputs/RichTextInput/CharCount/CharCountRichTextInput';

export type MetadataInternalNotesFieldProps = {
  getFieldStatus: (field: MetadataField, status: AllowedStatuses) => AllowedStatuses;
  onBlurCharCountField: (
    field: MetadataField,
    value: RichTextInputValue,
    isLengthExceeded: boolean,
    journeyMap: JourneyMap
  ) => void;
};

export const MetadataInternalNotesField = ({
  getFieldStatus,
  onBlurCharCountField,
}: MetadataInternalNotesFieldProps) => {
  const {
    entities: { journeyMap },
    statuses,
  } = useEntities(entityNames.JOURNEY_MAP);
  // eslint-disable-next-line
  const { internal_notes } = journeyMap;
  return (
    <CharCountRichTextInput
      id="metadata_internal_notes"
      initialValue={{ html: internal_notes }}
      onBlur={(value, isLengthExceeded) =>
        onBlurCharCountField('internal_notes', value, isLengthExceeded, journeyMap)
      }
      status={getFieldStatus('internal_notes', statuses.journeyMap)}
      label="internal notes"
      maxLength={500}
      fullHeight
    />
  );
};
