import React from 'react';
import Box from '@mui/material/Box';
import { Skeleton } from '../../atoms/Skeleton/Skeleton';

export const ImportDialogSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" sx={{ p: 5, width: '100%', height: '100%' }} />
    </Box>
  );
};
