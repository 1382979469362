import { SetState } from 'zustand/vanilla';
import { default as produce } from 'immer';
import { Project } from '../../../types/project';
import { AppStateInterface } from '../../config/storeTypes';

export const projectSlice = (set: SetState<AppStateInterface>) => ({
  setSelectedProject: (project: Project) => {
    set((state) =>
      produce(state, (draft) => {
        /* eslint-disable no-param-reassign */
        draft.entities.selectedProject = project;
      })
    );
  },
});
