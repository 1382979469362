import { User } from '../../types/user';
import { UsersApi } from './usersApi';

export const getUser = async (abortSignal?: AbortSignal): Promise<User> => {
  try {
    const result: User = await UsersApi.getUser({
      signal: abortSignal,
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
