import { normalize } from 'normalizr';
import { entitySchema } from '../slices/schema';
import { normalizeList, normalizeObject } from './normalizeMiddleware';
import { entityNames } from '../constants/entityNames';
import { store } from './store';
import { currentPlan, plans } from '../../mocks/plans';

const initialState = store.getState();
const resetState = () => store.setState(initialState, true);

describe('normalizeMiddleware tests', () => {
  afterEach(() => {
    resetState();
  });

  describe('normalizeList', () => {
    it('should normalize a list correctly', () => {
      const entityName = entityNames.PLANS;
      const set = store.setState;
      normalizeList(set, { data: plans, entityName });

      const state = store.getState();

      const normalized = normalize({ [entityName]: plans }, entitySchema);
      const ids = normalized.result[entityName].map((id: any) => String(id));

      expect(state.entities[entityName]).toEqual(normalized.entities[entityName]);
      expect(state.entities.result[entityName]).toEqual(ids);
    });
    it('should normalize an empty list', () => {
      const entityName = entityNames.PLANS;
      const set = store.setState;
      normalizeList(set, { data: [], entityName });

      const state = store.getState();

      expect(state.entities[entityName]).toEqual({});
      expect(state.entities.result[entityName]).toEqual([]);
    });
  });

  describe('normalizeObject', () => {
    it('should add a new object to the normalized state', () => {
      const entityName = entityNames.PLANS;
      const set = store.setState;

      normalizeObject(set, { data: currentPlan, entityName });

      const state = store.getState();

      expect(state.entities[entityName][currentPlan.id]).toEqual(currentPlan);
      expect(state.entities.result[entityName].length).toBe(1);
      expect(state.entities.result[entityName][0]).toBe(String(currentPlan.id));
    });
    it('should modify an existing object in the normalized state', () => {
      const entityName = entityNames.PLANS;
      const set = store.setState;

      normalizeObject(set, { data: currentPlan, entityName });
      const newPlan = { ...currentPlan, name: 'NEW PLAN' };
      normalizeObject(set, { data: newPlan, entityName });

      const state = store.getState();

      expect(state.entities[entityName][currentPlan.id]).toEqual(newPlan);
      expect(state.entities.result[entityName].length).toBe(1);
      expect(state.entities.result[entityName][0]).toBe(String(newPlan.id));
    });
  });
});
