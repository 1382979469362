import { Tag } from '../types/tags';
import { tagFactory } from './tagsFactory';

describe('tagsFactory', () => {
  describe('instantiates a tag orrectly', () => {
    it('adds an empty string when tag name is null', () => {
      const nullableNameTag: Tag = {
        id: 653,
        name: null,
        created_at: '2021-11-16T11:44:13.000+01:00',
        updated_at: '2021-11-16T11:44:13.000+01:00',
      };

      const tag = tagFactory(nullableNameTag);

      expect(tag.id).toBe(nullableNameTag.id);
      expect(tag.name).toBe('');
      expect(tag.created_at).toBe(nullableNameTag.created_at);
      expect(tag.updated_at).toBe(nullableNameTag.updated_at);
    });
  });

  it('keeps the tag name when a name is provided', () => {
    const nonNullableNameTag: Tag = {
      id: 653,
      name: 'Some tag name',
      created_at: '2021-11-16T11:44:13.000+01:00',
      updated_at: '2021-11-16T11:44:13.000+01:00',
    };

    const tag = tagFactory(nonNullableNameTag);

    expect(tag.id).toBe(nonNullableNameTag.id);
    expect(tag.name).toBe(nonNullableNameTag.name);
    expect(tag.created_at).toBe(nonNullableNameTag.created_at);
    expect(tag.updated_at).toBe(nonNullableNameTag.updated_at);
  });
});
