import { SetState } from 'zustand';
import { normalize } from 'normalizr';
import { default as produce } from 'immer';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { entitySchema } from '../slices/schema';
import { DENORMALIZED_DATA_KEY } from './helpers';
import { AppStateInterface } from './storeTypes';

type DataAndEntityName = {
  data: any;
  entityName: string;
};

export const normalizeList = (
  set: SetState<AppStateInterface>,
  { data, entityName }: DataAndEntityName
) => {
  // normalzr can't normalize empty array.
  // therefore if api returns empty array we just set to state an empty object
  if (data.length === 0) {
    set((state) =>
      produce(state, (draft) => {
        /* eslint-disable no-param-reassign */
        // @ts-ignore
        draft.entities[entityName] = {};
        // @ts-ignore
        draft.entities.result[entityName] = [];
      })
    );
    return;
  }

  const normalized = normalize({ [entityName]: data }, entitySchema);
  set((state) =>
    produce(state, (draft) => {
      /* eslint-disable no-param-reassign */
      // @ts-ignore
      draft.entities[entityName] = normalized.entities[entityName];
      // @ts-ignore
      draft.entities.result[entityName] = normalized.result[entityName].map((id: any) =>
        String(id)
      );
    })
  );
};

export const normalizeObject = (
  set: SetState<AppStateInterface>,
  { data, entityName }: DataAndEntityName
) => {
  set((state) =>
    produce(state, (draft) => {
      // @ts-ignore
      draft.entities[entityName][data.id] = data;

      // @ts-ignore
      const alreadyExists = draft.entities.result[entityName].find(
        (id: number | string) => String(id) === String(data.id)
      );
      if (!alreadyExists) {
        // @ts-ignore
        draft.entities.result[entityName].push(String(data.id));
      }
    })
  );
};

export const normalizeMiddleware = (config: any) => (set: any, get: any, api: any) =>
  config(
    (args: any) => {
      if (typeof args === 'object' && args[DENORMALIZED_DATA_KEY]) {
        const { data } = args[DENORMALIZED_DATA_KEY];

        if (isArray(data)) {
          normalizeList(set, args[DENORMALIZED_DATA_KEY]);
        } else if (isObject(data)) {
          normalizeObject(set, args[DENORMALIZED_DATA_KEY]);
        }
      } else {
        set(args);
      }
    },
    get,
    api
  );
