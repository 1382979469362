import { EntityNames } from '../../../types/state';
import { AppStateInterface } from '../../config/storeTypes';

export const errorsByEntity = (entityName: EntityNames, state: AppStateInterface) =>
  state.entities.errors[entityName];

export const errorsMethods = (state: any) => ({
  addError: state.addError,
  removeError: state.removeError,
});
