import React from 'react';
import {
  CardActions as MuiCardActions,
  CardActionsProps as MuiCardActionsProps,
  styled,
} from '@mui/material';

export type CardActionsProps = MuiCardActionsProps;

const StyledCardActions = styled(MuiCardActions)(({ theme }) => ({
  padding: theme.spacing(6),
}));

export const CardActions = (props: CardActionsProps) => {
  return <StyledCardActions {...props} />;
};
