import React from 'react';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { colors } from '../../../theme';

export type LinkProps = MuiLinkProps;

const StyledLink = styled(MuiLink)(() => ({
  color: colors.blue[500],
  '&:hover': {
    color: colors.blue[600],
  },
}));

export const Link = (props: LinkProps) => {
  const { variant = 'regularXS', ...rest } = props;

  return <StyledLink variant={variant} {...rest} />;
};

export const BreadcrumbsLink = styled((props: LinkProps) => {
  return <Link variant="boldXS" {...props} />;
})(() => ({
  color: colors.silver,
  textTransform: 'uppercase',
  '&:hover': {
    color: colors.blue[400],
  },
}));
