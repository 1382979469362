import React from 'react';
import SvgIcon, { SvgIconProps, svgIconClasses } from '@mui/material/SvgIcon';
import { fontVariants } from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  findIconDefinition,
  IconDefinition,
  IconName,
  IconPrefix,
  library,
} from '@fortawesome/fontawesome-svg-core';
import {
  faA,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowCircleRight,
  faArrowDown,
  faArrowDownToDottedLine,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpToDottedLine,
  faAsterisk,
  faBold,
  faBook,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronUp,
  faCirclePlus,
  faCloudArrowUp,
  faCoffee,
  faCog,
  faCopy,
  faDatabase,
  faDeleteRight,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEyeDropper,
  faFaceLaugh,
  faGraduationCap,
  faGripDots,
  faGripDotsVertical,
  faHandPaper,
  faHighlighter,
  faImage,
  faInfoCircle,
  faInputText,
  faItalic,
  faLink,
  faLinkSlash,
  faList,
  faListOl,
  faListUl,
  faMessages,
  faMousePointer,
  faNote,
  faObjectGroup,
  faPage,
  faPercentage,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPlusLarge,
  faQuestionCircle,
  faRedo,
  faSignsPost,
  faSpinner,
  faStarOfLife,
  faSync,
  faText,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faTrash,
  faUnderline,
  faUndo,
  faUser,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faA as falA,
  faAlignCenter as falAlignCenter,
  faAlignLeft as falAlignLeft,
  faAlignRight as falAlignRight,
  faAngleDown as falAngleDown,
  faBold as falBold,
  faChartLine as falChartLine,
  faCheck as falCheck,
  faCog as falCog,
  faCopy as falCopy,
  faDatabase as falDatabase,
  faEyeDropper as falEyeDropper,
  faFaceLaugh as falFaceLaugh,
  faHandPaper as falHandPaper,
  faHighlighter as falHighlighter,
  faImage as falImage,
  faItalic as falItalic,
  faLink as falLink,
  faLinkSlash as falLinkSlash,
  faListOl as falListOl,
  faListUl as falListUl,
  faMessages as falMessages,
  faMousePointer as falMousePointer,
  faNote as falNote,
  faObjectGroup as falObjectGroup,
  faPage as falPage,
  faRedo as falRedo,
  faSearch as falSearch,
  faSignsPost as falSignsPost,
  faSpinner as falSpinner,
  faText as falText,
  faTimes as falTimes,
  faUnderline as falUnderline,
  faUndo as falUndo,
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowCircleDown as farArrowCircleDown,
  faArrowCircleLeft as farArrowCircleLeft,
  faArrowCircleRight as farArrowCircleRight,
  faArrowCircleUp as farArrowCircleUp,
  faArrowDownArrowUp as farArrowDownArrowUp,
  faArrowLeftFromLine as farArrowLeftFromLine,
  faArrowRightFromLine as farArrowRightFromLine,
  faChartLine as farChartLine,
  faCirclePlus as farCirclePlus,
  faCircleStar as farCircleStar,
  faCopy as farCopy,
  faDatabase as farDatabase,
  faExpand as farExpand,
  faFaceLaugh as farFaceLaugh,
  faFileImport as farFileImport,
  faGear as farGear,
  faIdCardClip as farIdCardClip,
  faImage as farImage,
  faLayerGroup as farLayerGroup,
  faLinkSlash as farLinkSlash,
  faMessages as farMessages,
  faMessageText as farMessageText,
  faPage as farPage,
  faTag as farTag,
  faText as farText,
  faTrashCan as farTrashCan,
  faEmptySet as farEmptySet,
} from '@fortawesome/pro-regular-svg-icons';

export const ICONS: IconDefinition[] = [
  faA,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowCircleRight,
  faArrowDown,
  faArrowDownToDottedLine,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpToDottedLine,
  faAsterisk,
  faBold,
  faBook,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronUp,
  faCirclePlus,
  faCloudArrowUp,
  faCoffee,
  faCog,
  faCopy,
  faDatabase,
  faDeleteRight,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEyeDropper,
  faFaceLaugh,
  faGraduationCap,
  faGripDots,
  faGripDotsVertical,
  faHandPaper,
  faHighlighter,
  faImage,
  faInfoCircle,
  faInputText,
  faItalic,
  faItalic,
  falA,
  falAlignCenter,
  falAlignLeft,
  falAlignRight,
  falAngleDown,
  falBold,
  falChartLine,
  falCheck,
  falCog,
  falCopy,
  falDatabase,
  falEyeDropper,
  falFaceLaugh,
  falHandPaper,
  falHighlighter,
  falImage,
  faLink,
  faLinkSlash,
  faList,
  faListOl,
  faListUl,
  falItalic,
  falLink,
  falLinkSlash,
  falListOl,
  falListUl,
  falMessages,
  falMousePointer,
  falNote,
  falObjectGroup,
  falPage,
  falRedo,
  falSearch,
  falSignsPost,
  falSpinner,
  falText,
  falTimes,
  falUnderline,
  falUndo,
  faMessages,
  faMousePointer,
  faNote,
  faObjectGroup,
  faPage,
  faPercentage,
  faPlayCircle,
  faPlus,
  faPlus,
  faPlusCircle,
  faPlusLarge,
  faQuestionCircle,
  farArrowCircleDown,
  farArrowCircleLeft,
  farArrowCircleRight,
  farArrowCircleUp,
  farArrowDownArrowUp,
  farArrowLeftFromLine,
  farArrowRightFromLine,
  farChartLine,
  farCirclePlus,
  farCircleStar,
  farCopy,
  farDatabase,
  faRedo,
  farExpand,
  farEmptySet,
  farFaceLaugh,
  farFileImport,
  farGear,
  farIdCardClip,
  farImage,
  farLayerGroup,
  farLinkSlash,
  farMessages,
  farMessageText,
  farPage,
  farTag,
  farText,
  farTrashCan,
  faSignsPost,
  faSpinner,
  faStarOfLife,
  faSync,
  faText,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faTrash,
  faUnderline,
  faUndo,
  faUser,
  faWrench,
];

library.add(...ICONS);

function getIcon(iconName: IconName, prefix: IconPrefix = 'fas'): IconDefinition {
  return findIconDefinition({ iconName, prefix });
}

export interface IconProps extends Omit<SvgIconProps, 'color'> {
  iconName: IconName;
  iconPrefix?: IconPrefix;
  color?: string;
  fontVariant?: keyof typeof fontVariants | 'inherit';
}

export const Icon = ({
  iconName,
  iconPrefix,
  color = 'inherit',
  fontVariant = 'inherit',
  className,
  sx,
  ...other
}: IconProps): JSX.Element => {
  return (
    <SvgIcon
      className={[
        `${svgIconClasses.root}-${iconPrefix}`,
        `${svgIconClasses.root}-${iconName}`,
        className,
      ].join(' ')}
      // @ts-ignore
      sx={{ color, ...fontVariants[fontVariant], ...sx }}
      {...other}
    >
      <FontAwesomeIcon icon={getIcon(iconName, iconPrefix)} />
    </SvgIcon>
  );
};
