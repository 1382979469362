import { renderHook } from '@testing-library/react-hooks';
import { enableFetchMocks } from 'jest-fetch-mock';
import { getTags } from '../../../../../api/tags/getTags';
import { useTags } from './useTags';

enableFetchMocks();
jest.mock('../../../../../api/tags/getTags');

beforeEach(() => {
  jest.clearAllMocks();
});

describe('useTags', () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mockedGetTags: jest.Mocked<any> = getTags as any;

  it('results properly', async () => {
    const mockResponse = [{}, {}, {}];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mockedGetTags.mockImplementation((): Promise<any> => Promise.resolve(mockResponse));
    const { result, waitForNextUpdate } = renderHook(() => useTags('some org ID'));

    let [resultList, isLoading] = result.current;

    expect(isLoading).toBeTruthy();
    expect(resultList).toEqual([]);

    await waitForNextUpdate();

    [resultList, isLoading] = result.current;

    expect(isLoading).toBeFalsy();
    expect(resultList).toEqual(mockResponse);
  });

  it('logs error when an error is thrown', () => {
    mockedGetTags.mockImplementation(() => {
      throw new Error('some error from upstream');
    });

    try {
      renderHook(() => useTags('some org ID'));
    } catch (e) {
      expect(e.message).toBe('smaply-ui: get tags hook error');
    }
  });
});
