import MuiCard from '@mui/material/Card';
import MuiCardActions from '@mui/material/CardActions';
import MuiCardContent from '@mui/material/CardContent';
import MuiCardMedia from '@mui/material/CardMedia';

import { styled } from '@mui/material/styles';
import { colors } from '../../../theme';

export const Card = styled(MuiCard)(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 0,
}));
export const CardActions = styled(MuiCardActions)(() => ({
  padding: '0',
  justifyContent: 'flex-end',
}));
export const CardContent = styled(MuiCardContent)(() => ({
  flexGrow: 1,
  '& .MuiTypography-subtitle1': {
    color: colors.iron,
  },
  '& .MuiTypography-body2': {
    color: colors.iron,
  },
}));
export const CardMedia = styled(MuiCardMedia)(() => ({}));
