import isString from 'lodash/isString';
import { Tag } from '../../types/tags';
import { TagsApi } from './tagsApi';

export const getTags = async (
  organizationId: string,
  abortSignal?: AbortSignal
): Promise<Array<Tag>> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: Tag[] = await TagsApi.get(organizationId, {
      signal: abortSignal,
    }).json();

    return result || [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};
