import { useEffect, useState } from 'react';
import { getProjectTagAssociations } from '../../../../../api/project-tags-association/getProjectTagAssociations';
import { getTags } from '../../../../../api/tags/getTags';
import { Tag, TagAssociation } from '../../../../../types/tags';
import { useAbortSignal } from '../../../../../utils/hooks/useAbortSignal';

export const useProjectTags = (
  organizationId: string,
  projectId: string
): [Tag[], TagAssociation[], typeof findTagAssociation, boolean, Error] => {
  const [projectTags, setProjectTags]: [Tag[], React.Dispatch<React.SetStateAction<Tag[]>>] =
    useState([]);

  const [tagAssociationsList, setTagAssociations]: [
    TagAssociation[],
    React.Dispatch<React.SetStateAction<TagAssociation[]>>
  ] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const abortSignal: AbortSignal = useAbortSignal();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags: Tag[] = await getTags(organizationId, abortSignal);
        const associations: TagAssociation[] = await getProjectTagAssociations(
          organizationId,
          projectId,
          abortSignal
        );

        setTagAssociations(associations);
        setProjectTags(
          associations
            .map((association) => tags.find((tag) => tag.id === association.tag_id))
            .filter((tag) => tag)
        );
      } catch (e) {
        setProjectTags([]);
        setError(e);

        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [organizationId, projectId, abortSignal]);

  const findTagAssociation = (tag: Tag, associationsList: TagAssociation[]) => {
    return associationsList.find((association) => tag.id === association.tag_id);
  };

  return [projectTags, tagAssociationsList, findTagAssociation, isLoading, error];
};
