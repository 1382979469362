import { produce } from 'immer';
import { GetState, SetState } from 'zustand/vanilla';
import { entityNames } from '../../constants/entityNames';
import { allowedStatuses } from '../../constants/allowedStatuses';
import { getJiraIntegration } from '../../../api/jira/getJiraIntegration';
import { putJiraIntegration } from '../../../api/jira/putJiraIntegration';
import { deleteJiraIntegration } from '../../../api/jira/deleteJiraIntegration';
import { searchJiraIssues } from '../../../api/jira/searchJiraIssues';
import { JiraIntegration, JiraIssue } from '../../../types/jira';
import { errorsMethods } from '../errors/errorsSelectors';
import { statusesMethods } from '../statuses/statusesSelector';
import { AppStateInterface } from '../../config/storeTypes';
import { HTTPError } from 'ky';

export const jiraSlice = (set: SetState<AppStateInterface>, get: GetState<AppStateInterface>) => ({
  getJiraIntegration: async (abortSignal: AbortSignal) => {
    const entityName = entityNames.JIRA_INTEGRATION;

    const {
      entities: { organization },
    } = get();

    const { setEntityStatus } = statusesMethods(get());

    try {
      setEntityStatus(entityName, allowedStatuses.LOADING);

      const result: JiraIntegration = await getJiraIntegration(organization.slug, abortSignal);

      set((state) =>
        produce(state, (draft) => {
          /* eslint-disable no-param-reassign */
          draft.entities[entityName] = result;
        })
      );

      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  putJiraIntegration: async (jiraUrl: string, abortSignal: AbortSignal) => {
    const entityName = entityNames.JIRA_INTEGRATION;

    const {
      setEntityStatus,
      setJiraIntegration,
      addError,
      entities: { organization },
    } = get();

    try {
      setEntityStatus(entityName, allowedStatuses.PERSISTING);

      const result: JiraIntegration = await putJiraIntegration(
        organization.slug,
        jiraUrl,
        abortSignal
      );

      setJiraIntegration(result);
      setEntityStatus(entityName, allowedStatuses.PERSISTED);
    } catch (error) {
      const serverError: HTTPError = await error.response.json();
      addError(serverError, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  deleteJiraIntegration: async (abortSignal: AbortSignal) => {
    const {
      setEntityStatus,
      addError,
      entities: { organization },
    } = get();

    const entityName = entityNames.JIRA_INTEGRATION;

    try {
      setEntityStatus(entityName, allowedStatuses.PERSISTING);

      const result: JiraIntegration = await deleteJiraIntegration(organization.slug, abortSignal);

      set((state) =>
        produce(state, (draft) => {
          /* eslint-disable no-param-reassign */
          draft.entities[entityName] = result;
        })
      );

      setEntityStatus(entityName, allowedStatuses.IDLE);
    } catch (error) {
      const serverError: HTTPError = await error.response.json();
      addError(serverError, entityName);
      setEntityStatus(entityName, allowedStatuses.ERROR);
    }
  },
  setJiraIntegration: (jiraIntegration: JiraIntegration) => {
    set((state: AppStateInterface) =>
      produce(state, (draft) => {
        /* eslint-disable no-param-reassign */
        draft.entities.jiraIntegration = jiraIntegration;
      })
    );
  },
  searchJiraIssues: async (query: string, abortSignal: AbortSignal) => {
    const {
      setDenormalizedData,
      entities: { organization },
    } = get();

    const { setEntityStatus } = statusesMethods(get());

    try {
      setEntityStatus(entityNames.JIRA_ISSUES, allowedStatuses.LOADING);

      const jiraIssues: JiraIssue[] = await searchJiraIssues(organization.slug, query, abortSignal);

      setDenormalizedData(jiraIssues, entityNames.JIRA_ISSUES);

      setEntityStatus(entityNames.JIRA_ISSUES, allowedStatuses.IDLE);
    } catch (error) {
      const { addError } = errorsMethods(get());
      addError(error, entityNames.JIRA_ISSUES);
      setEntityStatus(entityNames.JIRA_ISSUES, allowedStatuses.ERROR);
    }
  },
  toggleSelectedJiraIssue: (jira_issue_key: string) => {
    const {
      entities: { jiraSelectedIssueKeys },
    } = get();

    const newJiraSelectedIssueKeys = [...jiraSelectedIssueKeys];

    const index = jiraSelectedIssueKeys.findIndex((j) => j === jira_issue_key);
    if (index < 0) {
      newJiraSelectedIssueKeys.push(jira_issue_key);
    } else {
      newJiraSelectedIssueKeys.splice(index, 1);
    }

    set((state: AppStateInterface) =>
      produce(state, (draft) => {
        draft.entities[entityNames.JIRA_SELECTED_ISSUE_KEYS] = newJiraSelectedIssueKeys;
      })
    );
  },
  clearSelectedJiraIssues: () => {
    set((state: AppStateInterface) =>
      produce(state, (draft) => {
        draft.entities[entityNames.JIRA_SELECTED_ISSUE_KEYS] = [];
      })
    );
  },
});
