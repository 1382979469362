import isString from 'lodash/isString';
import { ChargebeePlanId, PlanValidation } from '../../types/pricing';
import { PlansApi } from './plansApi';

export const getPlanValidation = async (
  organizationId: string,
  planId: ChargebeePlanId,
  abortSignal?: AbortSignal
): Promise<PlanValidation> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: PlanValidation = await PlansApi.getPlanValidation(organizationId, {
      signal: abortSignal,
      searchParams: { new_plan_id: planId },
    }).json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
