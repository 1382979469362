// the quill editor is triggering a "blur" event when you paste text on it.
// this code prevents that to happen.
// Github issue: https://github.com/zenoamaro/react-quill/issues/276

import { UnprivilegedEditor } from 'react-quill';
import { RichTextInputProps, RichTextInputValue } from '../RichTextInputProps';

export const fixBlurOnPasteIssue = (
  editor: UnprivilegedEditor,
  onBlur: Pick<RichTextInputProps, 'onBlur'>['onBlur']
) => {
  // this is arbitrary, just time enough for quill to update the "selection"
  const timeout = 50;

  setTimeout(() => {
    // gets currently selected content
    const selection = editor.getSelection();

    // if there is content selected means that was a "fake blur"
    // if there is none selected means that this is an "actual blur"
    if (!selection && onBlur) {
      const currentValue: RichTextInputValue = {
        html: editor.getHTML(),
        text: editor.getText(),
      };

      onBlur(currentValue);
    }
  }, timeout);
};
