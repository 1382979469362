import React from "react";
import {
  JiraIssueCard as SUIJiraIssueCard,
  JiraIssueCardProps
} from './src/components/molecules/Jira/JiraIssueCard';
import {
  JiraIssuesDialog as SUIJiraIssuesDialog,
  JiraIssuesDialogProps,
} from './src/components/molecules/Jira/JiraIssuesDialog';
import {
  JiraSettings as SUIJiraSettings,
  JiraSettingsProps,
} from "./src/components/templates/Settings/Jira/JiraSettings";
import { K2Theme } from './src/theme/material/k2';
import { ThemeProvider } from '@mui/material';

export function JiraIssueCard(props: JiraIssueCardProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUIJiraIssueCard {...props} />
    </ThemeProvider>
  );
}

export function JiraIssuesDialog(props: JiraIssuesDialogProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUIJiraIssuesDialog {...props} />
    </ThemeProvider>
  );
}

export function JiraSettings(props: JiraSettingsProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SUIJiraSettings {...props} />
    </ThemeProvider>
  );
}
