import React from 'react';
import {
  MetadataBar as SmaplyUIMedataBar,
} from './src/components/molecules/Metadata/MetadataBar';
import { MetadataBarContentProps } from './src/components/molecules/Metadata/MetadataBarContent';
import { K2Theme } from './src/theme/material/k2';
import { ThemeProvider } from '@mui/material';

export function MetadataBar(props: MetadataBarContentProps) {
  return (
    <ThemeProvider theme={K2Theme}>
      <SmaplyUIMedataBar {...props} />
    </ThemeProvider>
  );
}
