import { combineStatuses } from './combineStatuses';
import { StatusesType } from '../config/storeTypes';
import { allowedStatuses } from '../constants/allowedStatuses';

describe('combineStatuses', () => {
  it('should return LOADING if any of the entities is in LOADING state', () => {
    const statuses: Partial<StatusesType> = {
      currentPlan: allowedStatuses.LOADING,
      billingInformation: allowedStatuses.PERSISTING,
    };
    expect(combineStatuses(statuses)).toBe(allowedStatuses.LOADING);
  });
  it('should return PERSISTING if any of the entities is in PERSISTING state and none of the entities are LOADING', () => {
    const statuses: Partial<StatusesType> = {
      currentPlan: allowedStatuses.ERROR,
      billingInformation: allowedStatuses.PERSISTING,
    };
    expect(combineStatuses(statuses)).toBe(allowedStatuses.PERSISTING);
  });
  it('should return ERROR if any of the entities is in ERROR state and none of the entities are LOADING or PERSISTING', () => {
    const statuses: Partial<StatusesType> = {
      currentPlan: allowedStatuses.ERROR,
      billingInformation: allowedStatuses.PERSISTED,
    };
    expect(combineStatuses(statuses)).toBe(allowedStatuses.ERROR);
  });
  it('should return PERSISTED if any of the entities is in PERSISTED state and none of the entities are LOADING or PERSISTING or ERROR', () => {
    const statuses: Partial<StatusesType> = {
      currentPlan: allowedStatuses.IDLE,
      billingInformation: allowedStatuses.PERSISTED,
    };
    expect(combineStatuses(statuses)).toBe(allowedStatuses.PERSISTED);
  });
  it('should return IDLE if all entities are in IDLE state', () => {
    const statuses: Partial<StatusesType> = {
      currentPlan: allowedStatuses.IDLE,
      billingInformation: allowedStatuses.IDLE,
    };
    expect(combineStatuses(statuses)).toBe(allowedStatuses.IDLE);
  });
});
