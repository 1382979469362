import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import { colors } from '../../../theme';

export const StyledAutoComplete = styled(MuiAutocomplete)(({ theme }) => ({
  '&.border': {
    border: `1px solid ${colors.smoke}`,
  },
  '&.hiddenLabel .MuiInputBase-root': {
    padding: '8px 8px 6px',
  },
  '&.visibleLabel .MuiInputBase-root': {
    padding: '28px 10px 6px',
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
    padding: '0px 4px 0px 4px',
  },
  '& .MuiAutocomplete-tag': {
    margin: '2px',
    color: colors.onyx,
    backgroundColor: colors.smoke,
  },
  '& span.MuiAutocomplete-tag:last-of-type': {
    ...theme.typography.boldXS,
    background: 'initial',
    color: colors.aluminium,
  },
  '& .MuiInputBase-root .MuiAutocomplete-input': {
    ...theme.typography.regularXXS,
    color: colors.carbon,
    '&::placeholder': {
      textTransform: 'uppercase',
    },
  },
}));

export const AutoComplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element => {
  return <StyledAutoComplete {...props} />;
};
