import React from 'react';
import Link from '@mui/material/Link';
import { Alert } from '../../atoms/Alert/Alert';
import { SUPPORT_EMAIL } from '../../../constants/emailAddresses';

export const ErrorMessage = () => {
  const severity = 'error';

  return (
    <Alert severity={severity}>
      Oops, an error has occurred. Our technical staff has been informed. Please refresh the page
      and try again.
      <br /> If the error still occurs, just contact us at
      <Link href={`mailto:${SUPPORT_EMAIL}`} ml={1}>
        support@smaply.com
      </Link>
    </Alert>
  );
};
