import { JiraIntegration } from '../../types/jira';
import { jiraIntegrationNotIntegrated } from '../../mocks/jira';
import { shouldMockSmaplyAPI } from '../../mocks/shouldMock';
import { JiraApi } from './jiraApi';
import { delayPromise } from '../../utils/storybook';

export const getJiraIntegration = async (
  organizationSlug: string,
  abortSignal: AbortSignal
): Promise<JiraIntegration> => {
  try {
    const result: JiraIntegration = shouldMockSmaplyAPI
      ? await delayPromise(() => {
          // return jiraIntegrationWithErrors;
          // return jiraIntegration;
          return jiraIntegrationNotIntegrated;
        })
      : await JiraApi.getJiraIntegrationRequest(organizationSlug, {
          signal: abortSignal,
        }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
