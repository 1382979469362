import ky from 'ky';
import process from 'process';
import { environment, environments } from '../utils/environment';

export const SMAPLY_API_URLS = {
  [environments.DEVELOPMENT]: 'https://app.smaply.localhost',
  [environments.STORYBOOK]: null,
  [environments.STORYBOOK_SMAPLY]: 'http://storybook.smaply.localhost',
  [environments.SMAPLY_DEVELOPMENT]: '',
  [environments.SMAPLY_SYSTEM_TESTS]: null,
  [environments.SMAPLY_STAGING]: 'https://staging.smaply.com',
  [environments.SMAPLY_PRODUCTION]: null,
} as any;

export const {
  get,
  post,
  put,
  delete: remove,
} = ky.create({
  prefixUrl: SMAPLY_API_URLS[environment],
  credentials: [environments.DEVELOPMENT, environments.STORYBOOK_SMAPLY].includes(environment)
    ? 'include'
    : 'same-origin',
  retry: {
    limit: 2,
    statusCodes: [502, 401],
  },
  headers: { Accept: 'application/json' },
  hooks: {
    beforeRequest: [
      (request) => {
        const csrf: HTMLMetaElement = document.head.querySelector('[name=csrf-token]');

        if (csrf) {
          request.headers.set('X-CSRF-TOKEN', csrf.content);
        }
        if (process.env.JIRA_ACCESS_TOKEN) {
          request.headers.set('JIRA_ACCESS_TOKEN', process.env.JIRA_ACCESS_TOKEN);
        }
        if (process.env.JIRA_ACCESS_SECRET) {
          request.headers.set('JIRA_ACCESS_SECRET', process.env.JIRA_ACCESS_SECRET);
        }
      },
    ],
  },
});
