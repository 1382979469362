import React from 'react';
import MuiLinearProgress, {
  LinearProgressProps as MuiLinearProgressProps,
} from '@mui/material/LinearProgress';

export type LinearProgressProps = MuiLinearProgressProps;

export const LinearProgress = (props: LinearProgressProps) => {
  return <MuiLinearProgress {...props} />;
};
