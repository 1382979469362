import { JiraIntegration, JiraSearchResults, JiraIssue } from '../types/jira';
import mockJiraSearch from './api-responses/jiraSearch.json';

export const jiraIntegrationNotIntegrated = {
  id: 1,
  jira_url: '',
  smaply_url: '',
  consumer_name: '',
  consumer_key: '',
  public_key: '',
  integrated: false,
  webhook_url: '',
} as JiraIntegration;

export const jiraIntegrationNotIntegratedWithJiraUrl = {
  id: 1,
  jira_url: 'https://morethanmetrics.atlassian.net',
  smaply_url: 'https://app.smaply.localhost',
  consumer_name: 'consumer-name-from-mocks',
  consumer_key: 'consumer-key-from-mocks',
  public_key: 'public-key-from-mocks',
  integrated: false,
  webhook_url: 'https://app.smaply.com/integrations/jira_issue/notify/example',
} as JiraIntegration;

export const jiraIntegration = {
  id: 1,
  jira_url: 'https://morethanmetrics.atlassian.net',
  smaply_url: 'https://app.smaply.localhost',
  consumer_name: 'consumer-name-from-mocks',
  consumer_key: 'consumer-key-from-mocks',
  public_key: 'public-key-from-mocks',
  integrated: true,
  webhook_url: 'https://app.smaply.com/integrations/jira_issue/notify/example',
} as JiraIntegration;

export const jiraIntegrationWithJiraUrl = {
  id: 1,
  jira_url: 'https://morethanmetrics.atlassian.net/',
  smaply_url: 'https://app.smaply.localhost',
  consumer_name: 'consumer-name-from-mocks',
  consumer_key: 'consumer-key-from-mocks',
  public_key: 'public-key-from-mocks',
  integrated: false,
  webhook_url: 'webhook-url-from-mocks',
} as JiraIntegration;

export const jiraIntegrationWithErrors = {
  errors: { messages: { jira_url: ['is invalid', 'has invalid host', 'does not use https'] } },
};

export const mockedJiraSearch = mockJiraSearch as unknown as JiraSearchResults;

export const jiraIssue: JiraIssue = {
  key: 'S3-1234',
  self_link: 'https://morethanmetrics.atlassian.net/browse/S3-1234',
  summary:
    'This Jira issue is a mocked inside of Storybook. You can update this issue directly in the mock if you need to.',
  issue_type_name: 'Story',
  issue_type_icon_url:
    'https://morethanmetrics.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10715?size=medium',
  status_name: 'In Progress',
  status_background_color: 'blue-gray',
  priority_name: 'Major',
  priority_icon: 'https://morethanmetrics.atlassian.net/images/icons/priorities/major.svg',
  assignee_name: 'Brendan Bailey',
  assignee_avatar: '',
  created_at: '',
};
