import process from 'process';

export const environments = {
  DEVELOPMENT: 'DEVELOPMENT', // 'Standalone' application.
  STORYBOOK: 'STORYBOOK',
  STORYBOOK_SMAPLY: 'STORYBOOK_SMAPLY', // Storybook with connection to your local Smaply env.
  GITHUB_PAGES: 'GITHUB_PAGES',
  SMAPLY_DEVELOPMENT: 'SMAPLY_DEVELOPMENT', // Canvas embedded inside of Smaply.
  SMAPLY_SYSTEM_TESTS: 'SMAPLY_SYSTEM_TESTS',
  SMAPLY_STAGING: 'SMAPLY_STAGING',
  SMAPLY_PRODUCTION: 'SMAPLY_PRODUCTION',
};

export type Environment = typeof environments[keyof typeof environments];

export let environment: Environment = environments.SMAPLY_PRODUCTION;

switch (window.location.hostname) {
  case '127.0.0.1':
  case 'localhost':
    if (window.location.port === '4500') {
      environment = environments.DEVELOPMENT;
    } else if (window.location.port === '4400') {
      environment = environments.STORYBOOK;
    } else {
      environment = environments.SMAPLY_SYSTEM_TESTS;
    }
    break;
  case 'storybook.smaply.localhost':
    environment = environments.STORYBOOK_SMAPLY;
    break;
  case 'smaply-ui.com':
    environment = environments.GITHUB_PAGES;
    break;
  case 'app.smaply.localhost':
    environment = environments.SMAPLY_DEVELOPMENT;
    break;
  case 'staging.smaply.com':
    environment = environments.SMAPLY_STAGING;
    break;
  default:
    if (process.env.STORYBOOK) {
      environment = environments.STORYBOOK;
    }
}
