import { renderHook } from '@testing-library/react-hooks';
import { useAbortSignal } from './useAbortSignal';

describe('useAbortSignal', () => {
  it('returns an abort signal', () => {
    const { result } = renderHook(() => useAbortSignal());
    expect(result.current).toBeInstanceOf(AbortSignal);
  });

  it('marks the AbortSignal as aborted when unmounted', () => {
    const { result, unmount } = renderHook(() => useAbortSignal());
    expect(result.current.aborted).toBe(false);
    unmount();
    expect(result.current.aborted).toBe(true);
  });

  it('does not change the AbortSignal when re-rendered', () => {
    const { result, rerender } = renderHook(() => useAbortSignal());
    const initialAbortSignal = result.current;
    rerender();
    expect(result.current).toBe(initialAbortSignal);
  });
});
