import { environment, environments } from '../utils/environment';
import { Project } from '../types/project';
import { WallsyncMetaData } from '../types/wallsync';

export const buildWallsyncImportUrl = (
  tool: string,
  project: Project,
  wallsync: WallsyncMetaData
) => {
  const params = new URLSearchParams({
    tool,
    project_title: project?.slug,
    token: wallsync?.token,
    projectId: project?.id?.toString(),
    host: wallsync?.host,
    // temp
    userId: window.gon?.current_user?.id,
    instance_key: window.gon?.instance_key,
  } as any);

  const baseUrl =
    environment === environments.SMAPLY_PRODUCTION
      ? 'https://wallsync.smaply.com/'
      : 'https://wallsync-staging.smaply.com/';

  return `${baseUrl}#/journey?${params.toString()}`;
};
