import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { colors, fontFamily } from '../../../../theme';

export const MenuInput = styled(InputBase)(({ theme }) => ({
  fontFamily: fontFamily,
  padding: 10,
  width: '100%',
  fontSize: theme.typography.pxToRem(11),
  '& input': {
    fontSize: theme.typography.pxToRem(11),
    backgroundColor: colors.onyx,
    padding: 6,
    color: colors.white,
    borderBottom: `2px solid ${colors.blue[500]}`,
  },
}));
