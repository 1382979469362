import process from 'process';
import mockResponsePlans from './api-responses/plans.json';
import mockResponsePlan from './api-responses/plan.json';
import { ChargebeePlanId, Plan } from '../types/pricing';
import { shouldMockSmaplyAPI } from './shouldMock';

export const plans = mockResponsePlans as Plan[];
export const currentPlan = mockResponsePlan as Plan;

if (shouldMockSmaplyAPI && process.env.CURRENT_PLAN_CHARGEBEE_ID) {
  currentPlan.chargebee_id = process.env.CURRENT_PLAN_CHARGEBEE_ID as ChargebeePlanId;
}
