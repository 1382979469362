export const subscriptionStatus = {
  ACTIVE: 'active',
  TRIAL: 'trial',
  TRIAL_ENDED: 'trial_ended',
  LOCKED: 'locked',
  LOCKED_LIMIT_EXCEEDED: 'locked_limits_exceeded',
} as const;

export const chargebeeSubscriptionStatus = {
  FUTURE: 'future',
  PAUSED: 'paused',
  IN_TRIAL: 'in_trial',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
} as const;
