import { WallsyncMetaData } from '../../types/wallsync';
import { WallsyncApi } from './wallsyncApi';

export const getWallsyncData = async (
  organizationId: string,
  projectId: string
): Promise<WallsyncMetaData> => {
  if (!organizationId) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  if (!projectId) {
    const error = 'smaply-ui: missing project id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: WallsyncMetaData = await WallsyncApi.get(organizationId, projectId).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
