import React from 'react';
import { capitalize } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { K2Button } from './K2Button';

export type ButtonProps = MuiButtonProps;

export const Button = (props: ButtonProps) => {
  const { className = '', children, color = 'primary', variant = 'contained', ...rest } = props;

  return (
    <K2Button
      // eslint-disable-next-line
      className={`${variant}${capitalize(color)} ${className}`}
      color={color}
      variant={variant}
      {...rest}
    >
      <span className="MuiButtonText">{children}</span>
    </K2Button>
  );
};
