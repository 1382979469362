import React from 'react';
import Box from '@mui/material/Box';
import { Skeleton } from '../../../atoms/Skeleton/Skeleton';

export const JiraSettingsSkeleton = () => {
  return (
    <Box width={440}>
      <Box mb={9}>
        <Skeleton variant="rectangular" height={50} sx={{ marginTop: 6 }} />
      </Box>

      <Skeleton variant="rectangular" height={24} sx={{ marginTop: 4 }} />
      <Skeleton variant="rectangular" height={50} sx={{ marginTop: 4 }} />
      <Skeleton variant="rectangular" height={42} sx={{ marginTop: 4 }} width={136} />

      <Skeleton variant="rectangular" height={24} sx={{ marginTop: 12 }} />
      <Skeleton variant="rectangular" height={16} sx={{ marginTop: 4 }} />
      <Skeleton variant="rectangular" height={16} sx={{ marginTop: 4 }} />
      <Skeleton variant="rectangular" height={42} sx={{ marginTop: 4 }} width={136} />
    </Box>
  );
};
