import isString from 'lodash/isString';
import { Tag } from '../../types/tags';
import { TagsApi } from './tagsApi';

export const postTag = async (organizationId: string, name: string): Promise<Tag> => {
  if (!isString(organizationId)) {
    const error = 'smaply-ui: missing organization id';
    console.error(error);
    throw new Error(error);
  }

  try {
    const result: Tag = await TagsApi.post(organizationId, {
      json: { name },
    }).json();

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
