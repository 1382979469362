import React from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import { colors } from '../../../theme';

export type ListProps = {
  items: Array<string>;
};

export function List({ items = [] }: ListProps) {
  const theme: Theme = useTheme();
  return (
    <ul style={{ margin: '0px', paddingLeft: '0px', listStyleType: 'none' }}>
      {items.map((item) => (
        <li key={nanoid()}>
          <Typography variant="regularXS" color={colors.carbon} px={theme.spacing(2)}>
            &bull;
          </Typography>
          <Typography
            variant="regularXS"
            color={colors.carbon}
            lineHeight={theme.typography.regularM.lineHeight}
          >
            {item}
          </Typography>
        </li>
      ))}
    </ul>
  );
}
