import process from 'process';
import { Organization } from '../types/organization';
import mockResponseOrganization from './api-responses/organization.json';

export const organization = mockResponseOrganization as Organization;

if (process.env.SMAPLY_ORGANIZATION_NAME) {
  organization.name = process.env.SMAPLY_ORGANIZATION_NAME;
}
if (process.env.SMAPLY_ORGANIZATION_ROLE) {
  organization.role = process.env.SMAPLY_ORGANIZATION_ROLE;
}
if (process.env.SMAPLY_ORGANIZATION_SLUG) {
  organization.slug = process.env.SMAPLY_ORGANIZATION_SLUG;
}
