import { OrganizationId } from '../../types/organization';
import { ProjectId } from '../../types/project';
import { TagAssociationId } from '../../types/tags';
import { get, post, remove } from '../config';

export const ProjectTagAssociationsApi = {
  get: (organizationId: OrganizationId, projectId: ProjectId, params: any) =>
    get(`web/v1/organizations/${organizationId}/projects/${projectId}/tag_associations`, params),
  post: (organizationId: OrganizationId, projectId: ProjectId, params: any) =>
    post(`web/v1/organizations/${organizationId}/projects/${projectId}/tag_associations`, params),
  remove: (organizationId: OrganizationId, projectId: ProjectId, id: TagAssociationId) =>
    remove(`web/v1/organizations/${organizationId}/projects/${projectId}/tag_associations/${id}`),
};
