import React from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Icon, IconProps } from './Icon';

const iconSides = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
};

export type IconTextProps = IconProps & {
  text: string;
  fontStyles?: React.CSSProperties;
  alignItems?: React.CSSProperties['alignItems'];
  hoverColor?: string;
  iconSide?: keyof typeof iconSides;
};

const marginProp = {
  [iconSides.RIGHT]: 'marginRight',
  [iconSides.LEFT]: 'marginLeft',
};

export const IconText = ({
  text,
  fontVariant,
  color,
  alignItems,
  fontStyles,
  hoverColor,
  iconSide = 'LEFT',
  ...otherProps
}: IconTextProps) => {
  const theme: Theme = useTheme();
  const margin = marginProp[iconSide];
  const IconComponent = <Icon fontVariant={fontVariant} color={color} {...otherProps} />;
  return (
    <Typography
      display="flex"
      alignItems={alignItems || 'center'}
      component="div"
      color={color}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': { color: hoverColor, svg: { color: hoverColor } },
      }}
    >
      {iconSide === iconSides.LEFT && IconComponent}
      <Typography
        variant={fontVariant}
        color={color}
        style={{ [margin]: theme.spacing(1), ...fontStyles }}
      >
        {text}
      </Typography>
      {iconSide === iconSides.RIGHT && IconComponent}
    </Typography>
  );
};
