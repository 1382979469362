import React from 'react';
import Box from '@mui/material/Box';
import { Icon } from '../Icon/Icon';
import { colors } from '../../../theme';
import { useMutationObserver } from '../../../utils/hooks/useMutationObserver';
import { IconButton, IconButtonProps } from '../Icon/IconButton';

type TextEditorToolbarButtonProps = { className?: string } & IconButtonProps;

const Bold = ({ className }: TextEditorToolbarButtonProps) => (
  <IconButton className={className}>
    <Icon fontVariant="boldXS" iconPrefix="fas" iconName="bold" />
  </IconButton>
);

const Italic = ({ className }: TextEditorToolbarButtonProps) => (
  <IconButton className={className}>
    <Icon fontVariant="boldXS" iconPrefix="fas" iconName="italic" />
  </IconButton>
);

const List = ({ className }: TextEditorToolbarButtonProps) => (
  <IconButton className={className}>
    <Icon fontVariant="boldXS" iconPrefix="fas" iconName="list" />
  </IconButton>
);

const toolbarButtons = {
  bold: ({ className }: TextEditorToolbarButtonProps) => <Bold className={className} />,
  italic: ({ className }: TextEditorToolbarButtonProps) => <Italic className={className} />,
  list: ({ className }: TextEditorToolbarButtonProps) => <List className={className} />,
};

export type TextEditorToolbarProps = {
  id: string;
  onDOMElementRendered?: (el: HTMLElement) => void;
  buttonClassNames?: {
    [key in keyof typeof toolbarButtons]: string;
  };
  buttonActiveClassName?: string;
};

export const TextEditorToolbar = ({
  buttonActiveClassName,
  buttonClassNames,
  id,
  onDOMElementRendered,
}: TextEditorToolbarProps) => {
  const sx = buttonActiveClassName && {
    [`.${buttonActiveClassName}`]: { color: colors.blue[600] },
  };
  const ref = useMutationObserver(onDOMElementRendered);
  return (
    <Box ref={ref} id={id} sx={sx}>
      {Object.keys(toolbarButtons).map((buttonName) => {
        // @ts-ignore
        const Button = toolbarButtons[
          buttonName
        ] as React.FunctionComponent<TextEditorToolbarButtonProps>;
        return (
          <React.Fragment key={buttonName}>
            <Button
              disableRipple={false}
              // @ts-ignore
              className={buttonClassNames && buttonClassNames[buttonName]}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};
