import React from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { contentIfBlocked } from '../../../constants/additionalFeatures';
import isEmpty from 'lodash/isEmpty';
import { colors } from '../../../theme';
import { Button } from '../../atoms/Button/Button';
import { List } from '../../atoms/List/List';
import { CardMedia } from '../../atoms/Card/CardMedia';
import { ChipPDF, ChipPPT, ChipXLS } from '../../atoms/Chip/Chip';

export type BaseBlockedFeatureBoxProps = {
  title: string;
  description?: string;
  listItems?: Array<string>;
  image?: string;
  chipName?: string;
  onCTAClick?: () => void;
};

const chipsByName = {
  pdf: <ChipPDF />,
  xls: <ChipXLS />,
  ppt: <ChipPPT />,
};

const BaseBlockedFeatureBox = ({
  title,
  description,
  listItems,
  image,
  chipName,
  onCTAClick,
}: BaseBlockedFeatureBoxProps) => {
  const theme: Theme = useTheme();

  return (
    <Box bgcolor={colors.smoke} p={theme.spacing(5)}>
      <Typography display="flex" variant="regularM" color={colors.black}>
        {title}
      </Typography>
      {description && (
        <Typography display="flex" variant="regularXS" color={colors.carbon} mt={theme.spacing(4)}>
          {description}
        </Typography>
      )}
      <Box mt={theme.spacing(4)}>
        <List items={listItems} />
      </Box>
      <Box mt={theme.spacing(4)}>
        {!isEmpty(image) && <CardMedia component="img" image={image} alt={title} />}
        {/* @ts-ignore */}
        {chipName && chipsByName[chipName] && (
          <Box sx={{ position: 'relative', top: theme.spacing(-3), left: theme.spacing(3) }}>
            {/* @ts-ignore */}
            {chipsByName[chipName]}
          </Box>
        )}
      </Box>
      <Box mt={theme.spacing(3)}>
        <Button fullWidth color="primary" onClick={onCTAClick}>
          Upgrade plan
        </Button>
      </Box>
    </Box>
  );
};

export type BlockedFeatureBoxProps = {
  feature: string;
  onCTAClick?: () => void;
};

export const BlockedFeatureBox = ({ feature, onCTAClick }: BlockedFeatureBoxProps) => {
  // @ts-ignore
  const content = contentIfBlocked[feature];

  return (
    content && (
      <BaseBlockedFeatureBox
        title={content.title}
        listItems={content.listItems}
        image={content.image}
        chipName={content.chipName}
        onCTAClick={onCTAClick}
      />
    )
  );
};
