import { Options } from 'ky';
import { get, put, remove } from '../config';

const getUrl = (organizationSlug: string) =>
  `web/v1/organizations/${organizationSlug}/enterprise_configuration/integrations/jira`;

export const JiraApi = {
  getJiraIntegrationRequest: (organizationSlug: string, options: Options) =>
    get(getUrl(organizationSlug), options),
  deleteJiraIntegrationRequest: (organizationSlug: string, options: Options) =>
    remove(getUrl(organizationSlug), options),
  putJiraIntegration: (organizationSlug: string, options: Options) =>
    put(getUrl(organizationSlug), options),
  searchJiraIssues: (organizationSlug: string, query: string, options: Options) =>
    get(`${getUrl(organizationSlug)}/search/${query}`, options),
};
