import { Editor } from '@tiptap/react';
import { colors } from '../theme/colors/colors';

// @ts-ignore
export const editorMock = {
  chain: () => ({
    focus: () => ({
      setTextAlign: (option: any) => ({
        run: () => console.info(option),
      }),
      setFontFamily: (option: any) => ({
        run: () => console.info(option),
      }),
      setFontSize: (option: any) => ({
        run: () => console.info(option),
      }),
      setColor: (option: any) => ({
        run: () => console.info(option),
      }),
      setHighlight: (option: any) => ({
        run: () => console.info(option),
      }),
    }),
  }),
  isActive: (option: any) => {
    console.info(option);
    return false;
  },
  getAttributes: (option: any) => {
    console.info(option);
    return { color: colors.black } as Record<string, any>;
  },
} as Editor;
