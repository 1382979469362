// All available formats from quill
// https://quilljs.com/docs/formats/

export const quillFormats = [
  // Inline
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  // Block
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  // Embeds
  'formula',
  'image',
  'video',
] as const;

export type QuillFormats = Array<typeof quillFormats[number]>;
