import React from 'react';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const StyledIconButton = styled(MuiIconButton)(() => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'initial',
  },
}));

export type IconButtonProps = MuiIconButtonProps;

export const IconButton = ({ children, ...rest }: IconButtonProps) => {
  return <StyledIconButton {...rest}>{children}</StyledIconButton>;
};
