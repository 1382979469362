import { useEffect, useState } from 'react';
import { searchJiraIssues } from '../../../../api/jira/searchJiraIssues';
import { JiraIssue } from '../../../../types/jira';
import { useAbortSignal } from '../../../../utils/hooks/useAbortSignal';

export const useJiraIssues = (
  organizationSlug: string,
  query: string
): [JiraIssue[], boolean, Error] => {
  const [resultList, setResultList]: [
    JiraIssue[],
    React.Dispatch<React.SetStateAction<JiraIssue[]>>
  ] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);

  const abortSignal: AbortSignal = useAbortSignal();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        if (query === '') {
          setResultList([]);
        } else {
          const data: Array<JiraIssue> = await searchJiraIssues(
            organizationSlug,
            query,
            abortSignal
          );

          setResultList(data);
        }
      } catch (e) {
        setResultList([]);
        setError(e);

        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [organizationSlug, query, abortSignal]);

  return [resultList, isLoading, error];
};
